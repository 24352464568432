const constants = {
	CHANGE_THEME: "CHANGE_THEME",
	UPDATE_DATA: "UPDATE_DATA",
	UPDATE_SELECTIONS: "UPDATE_SELECTIONS",
	UPDATE_MULTI_SELECTIONS: "UPDATE_MULTI_SELECTIONS",
	UPDATE_ALERT_INFO: "UPDATE_ALERT_INFO",
	UPDATE_USER_INFO: "UPDATE_USER_INFO",
	RESET_FILTERS: "RESET_FILTERS",
	UPDATE_CONFIG_USER_INPUTS: "UPDATE_CONFIG_USER_INPUTS",
	UPDATE_WHOLE_CONFIG_USER_INPUTS: "UPDATE_WHOLE_CONFIG_USER_INPUTS",
	UPDATE_COUNTRY_REGIONS: "UPDATE_COUNTRY_REGIONS",
	EMPTY_TEST_CONTROL: "EMPTY_TEST_CONTROL",
	UPDATE_CONTROL_REGIONS: "UPDATE_CONTROL_REGIONS",
	UPDATE_TEST_REGIONS: "UPDATE_TEST_REGIONS",
	UPDATE_REGIONS_DATA: "UPDATE_REGIONS_DATA",
	TOGGLE_MODEL: "TOGGLE_MODEL",
	ADD_DATASET: "ADD_DATASET",
	REMOVE_DATASET: "REMOVE_DATASET",
	ADD_KPI: "ADD_KPI",
	REMOVE_KPI: "REMOVE_KPI",
	UPDATE_DATASET_LOCAL_PARAMETERS: "UPDATE_DATASET_LOCAL_PARAMETERS",
	UPDATE_MODEL_LOCAL_PARAMETERS: "UPDATE_MODEL_LOCAL_PARAMETERS",
	UPDATE_CATEGORY: "UPDATE_CATEGORY",
	UPDATE_RUN_DATA: "UPDATE_RUN_DATA",
	RELOAD_RUN_DATA: "RELOAD_RUN_DATA",
	REFRESH_DATA: "REFRESH_DATA",
	UPDATE_DIALOG_INFO: "UPDATE_DIALOG_INFO",
	RELOAD_CONFIGS: "RELOAD_CONFIGS",
	RELOAD_ADMIN_USER_LIST: "RELOAD_ADMIN_USER_LIST",
	TOGGLE_DATASET: "TOGGLE_DATASET",
	TOGGLE_KPI: "TOGGLE_KPI",
	ADD_TOGGLE_KPI: "ADD_TOGGLE_KPI",
	UPDATE_DATASET_ON_COUNTRY_CHANGE: "UPDATE_DATASET_ON_COUNTRY_CHANGE",
	UPDATE_CATEGORY_DATASET: "UPDATE_CATEGORY_DATASET",
	SELECT_ALL: "SELECT_ALL",
	CLEAR_ALL: "CLEAR_ALL",
	TOGGLE_SIDENAV: "TOGGLE_SIDENAV",
	REFRESH_COMMENTS: "REFRESH_COMMENTS",
	UPDATE_ADMIN_PANEL_USER_DETAILS: "UPDATE_ADMIN_PANEL_USER_DETAILS",
	REDIRECT_TO_ERROR: "REDIRECT_TO_ERROR",
	RESET_REDIRECT_TO_ERROR: "RESET_REDIRECT_TO_ERROR",

	GET_STANDARD_DATASET: "GET_STANDARD_DATASET",
	GET_MY_DATASET: "GET_MY_DATASET",
	STANDARD_DATASET_RECEIVED: "STANDARD_DATASET_RECEIVED",
	MY_DATASET_RECEIVED: "MY_DATASET_RECEIVED",

	GET_DATASET_DETAILS: "GET_DATASET_DETAILS",
	DATASET_DETAILS_RECEIVED: "DATASET_DETAILS_RECEIVED",

	GET_RUN_HISTORY_DETAILS: "GET_RUN_HISTORY_DETAILS",
	RUN_HISTORY_DETAILS_RECEIVED: "RUN_HISTORY_DETAILS_RECEIVED",

	GET_RUN_HISTORY_LIST: "GET_RUN_HISTORY_LIST",
	RUN_HISTORY_LIST_RECEIVED: "RUN_HISTORY_LIST_RECEIVED",

	GET_DATASET_SCHEMA: "GET_DATASET_SCHEMA",
	GET_RUNHISTORY_SCHEMA: "GET_RUNHISTORY_SCHEMA",
	DATASET_SCHEMA_RECEIVED: "DATASET_SCHEMA_RECEIVED",
	RUNHISTORY_SCHEMA_RECEIVED: "RUNHISTORY_SCHEMA_RECEIVED",

	GET_DATASET_REGISTER_DATA: "GET_DATASET_REGISTER_DATA",
	DATASET_REGISTER_DATA_RECIEVED: "DATASET_REGISTER_DATA_RECIEVED",

	GET_VALIDATION_STATUS: "GET_VALIDATION_STATUS",
	VALIDATION_STATUS_RECIEVED: "VALIDATION_STATUS_RECIEVED",

	GET_REGISTER_STATUS: "GET_REGISTER_STATUS",
	REGISTER_STATUS_RECIEVED: "REGISTER_STATUS_RECIEVED",

	GET_UPLOAD_STATUS: "GET_UPLOAD_STATUS",
	UPLOAD_STATUS_RECIEVED: "UPLOAD_STATUS_RECIEVED",

	GET_ADD_VALIDATION_STATUS: "GET_ADD_VALIDATION_STATUS",
	ADD_VALIDATION_STATUS_RECIEVED: "ADD_VALIDATION_STATUS_RECIEVED",

	GET_ADD_DATASET_STATUS: "GET_ADD_DATASET_STATUS",
	ADD_DATASET_STATUS_RECIEVED: "ADD_DATASET_STATUS_RECIEVED",

	GET_DOWNLOAD_URL_STATUS: "GET_DOWNLOAD_URL_STATUS",
	DOWNLOAD_URL_STATUS_RECIEVED: "DOWNLOAD_URL_STATUS_RECIEVED",
	UPDATE_DATA_SET_OF_UPLOAD: "UPDATE_DATA_SET_OF_UPLOAD",
	RESET_VALIDATION_STATUS: "RESET_VALIDATION_STATUS",
	UPDATE_LOADER_STATUS: "UPDATE_LOADER_STATUS",
};

export default constants;
