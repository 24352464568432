/** @format */

import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { getStandardDataset } from "../../actions";
import Loader from "../../js/components/Loader/Loader";
import SubHeading from "./SubHeading";

import "./index.scss";

const CssTextField = withStyles({
	root: {
		width: "100%",
		backgroundColor: "#f3f3f3",
		padding: "6px 10px",
		height: "24px",
		borderRadius: "4px",
		"& label.Mui-focused": {
			color: "green",
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "#dedede",
		},
		"& .MuiOutlinedInput-root": {
			backgroundColor: "white",
			"& fieldset": {
				borderColor: "#dedede",
			},
			"&:hover fieldset": {
				borderColor: "#dedede",
			},
			"&.Mui-focused fieldset": {
				border: "0.5px solid #dedede",
			},
		},
	},
})(TextField);
const StyledAutoComplete = withStyles(() => ({
	tag: {
		background: "#465a69",
		color: "#46596a !important",
		fontFamily: "Hind Siliguri !important",
	},
	paper: {
		fontFamily: "Hind Siliguri",
		fontSize: 13,
		color: "#46596a",
	},
}))(Autocomplete);

const useStyles = makeStyles(() => ({
	typographyStyle: {
		"& .MuiChip-root": {
			fontFamily: "Hind Siliguri",
			fontSize: 12,
		},
		"& .MuiInput-underline:after": {
			borderBottom: 0,
		},
		"& .MuiInput-underline:before": {
			borderBottom: 0,
			transition: "none",
		},
		"& .MuiInput-underline:hover:not(.Mui-disabled):before": {
			borderBottom: "0px solid #dedede !important",
		},
		"& .Mui-focused .MuiInput-underline": {
			borderBottom: 0,
		},
		"& .MuiAutocomplete-option": {
			fontFamily: "Hind Siliguri",
			fontSize: 12,
		},
		"& .MuiAutocomplete-paper": {
			fontFamily: "Hind Siliguri",
			fontSize: 6,
		},
		"& .MuiInputBase-input": {
			fontFamily: "Hind Siliguri",
			fontSize: 12,
			color: "#46596a",
		},
		"& .MuiOutlinedInput-root": {
			paddingTop: "0px !important",
			paddingBottom: "0px !important",
		},
		"& .MuiAutocomplete-popupIndicator": {
			"& span": {
				"& svg": {
					"& path": {
						d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')", // your svg icon path here
					},
				},
			},
		},
	},
	root: {
		"& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
			transform: "translate(34px, 20px) scale(1);",
			fontFamily: "Hind Siliguri",
			fontStyle: "normal",
			fontWeight: "400",
			fontSize: "5px",
			color: "#465a69",
			opacity: 0.5,
		},
	},
	select: {
		fontFamily: "Hind Siliguri !important",
		fontSize: "16px!important",
		fontStyle: "normal!important",
		fontWeight: "400!important",
	},
	comboOptions: {
		fontFamily: "Hind Siliguri",
		fontStyle: "normal",
		fontWeight: "400",
		fontSize: "16px",
		color: "#465A69",
	},
	popupIndicator: {
		"& span": {
			"& svg": {
				"& path": {
					d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')", // your svg icon path here
				},
			},
		},
	},
	inputRoot: {
		color: "#465a69",
		background: "#ededed",
		'&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
			paddingLeft: 26,
			fontFamily: "Hind Siliguri",
			fontSize: "14px",
			fontStyle: "normal",
			fontWeight: "500",
			lineHeight: "23px",
			letterSpacing: "0em",
			textAlign: "left",
			color: "#465A69",
		},
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#fafafa",
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "#fafafa",
		},
		"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
			borderColor: "#fafafa",
		},
	},
}));

const getDatasetNameList = (datasetDetails) => {
	const dataSetList = datasetDetails.data.map(dataset => ({
		datasetName: dataset.datasetName,
		datasetId: dataset.datasetId,
	}));
	return dataSetList;
};
function DatesetName({
	datasetsObj,
	handleDataSetChange,
	dataset,
	isEditable,
}) {
	const dispatch = useDispatch();
	useEffect(() => {
		if (datasetsObj.status === "loading") dispatch(getStandardDataset({}));
	}, []);

	const classes = useStyles();
	return (
		<Box mr={6}>
			{datasetsObj.status === "loading" && (
				<Loader message="Loading Dataset..." />
			)}
			{datasetsObj.status === "success" && (
				<>
					<SubHeading className="sub-heading-sub">Dataset Name</SubHeading>
					<StyledAutoComplete
						classes={classes.typographyStyle}
						value={dataset}
						options={getDatasetNameList(datasetsObj)}
						getOptionLabel={option => option?.datasetName}
						onChange={(e, newValue) => {
							handleDataSetChange(newValue);
						}}
						disableClearable
						disabled={isEditable}
						renderInput={params => (
							<CssTextField
								{...params}
								className={classes.typographyStyle}
								fullWidth
								placeholder="Choose dataset"
							/>
						)}
						style={{
							width: "338px",
							height: "50px",
						}}
					/>
				</>
			)}
		</Box>
	);
}

DatesetName.propTypes = {
	datasetsObj: PropTypes.any.isRequired,
	handleDataSetChange: PropTypes.any.isRequired,
	dataset: PropTypes.any.isRequired,
	isEditable: PropTypes.any.isRequired,
};

const mapStateToProps = state => ({
	datasetsObj: state.dataset.standardList || {},
});

export default connect(mapStateToProps)(DatesetName);
