import React from "react";
import PropTypes from "prop-types";

import { Dialog } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const dialogUseStyles = makeStyles(() => ({
	dialog: {
		fontFamily: "Hind Siliguri",
		fontSize: 14,
		padding: "15px",
		"& .MuiDialog-paperWidthSm": {
			maxWidth: "850px",
			height: "auto",
			maxHeight: "700px",
			padding: "15px",
		},
	},
}));

function Modal({ modalComponent, handleDialogClose }) {
	const dialogClass = dialogUseStyles();
	return (
		<Dialog
			className={dialogClass.dialog}
			onClose={handleDialogClose}
			aria-labelledby="simple-dialog-title"
			open={modalComponent.openModal}
		>
			{modalComponent.component}
		</Dialog>
	);
}

Modal.propTypes = {
	modalComponent: PropTypes.any.isRequired,
	handleDialogClose: PropTypes.any.isRequired,
};

export default Modal;
