/** @format */

import * as React from "react";
import {
	Box, Dialog, DialogActions, DialogContent,
} from "@mui/material";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
// import CloseIcon from "@material-ui/icons/Close";
// import { withStyles } from "@material-ui/core/styles";
import moment from "moment";

// Import utils and data
import "./index.scss";
import { useStyles } from "./styles";
import config from "../../js/config/config";
import ErrorAlert from "../RatifyForm/ErrorAlert";
import Loader from "../../js/components/Loader/Loader";

import runError from "../../assets/images/ratify/run-error.svg";
import runSuccess from "../../assets/images/ratify/run-success.svg";

// const SubHeading = withStyles({
// 	root: {
// 		fontFamily: "Hind Siliguri",
// 		fontStyle: "normal",
// 		fontWeight: "600",
// 		fontSize: "13px",
// 		lineHeight: "26px",
// 		color: "#465A69",
// 	},
// })(Typography);

export const triggerRatifyRequest = (payload) => {
	const url = config.api.triggerRatifyUrl;
	return fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(payload),
	});
};

function RatifyModel({
	open,
	handleCloseModal,
	dataSetDetails,
	myDataset,
	deleteRunConfig,
	functionType = "",
	hold = false,
	handleRefreshEvent,
	deleteStatus = "pending",
	setDeleteStatus = {},
	// setOpenModal = {},
	alertMsg = "",
}) {
	const classes = useStyles();
	const [waiting, setWaiting] = React.useState(false);
	const [messageAlert, setAlertMessage] = React.useState(false);
	const [alert, setAlert] = React.useState(false);
	const handleAlertClose = () => {
		setAlert(false);
		handleRefreshEvent();
	};

	const triggerRatify = () => {
		const data = {};
		let datasetTimeRange;
		if (myDataset) {
			datasetTimeRange = {
				endDate: moment().valueOf(),
				startDate: 0,
			};
		} else {
			datasetTimeRange = {
				endDate: dataSetDetails?.datasetTimeRange.endTime,
				startDate: dataSetDetails?.datasetTimeRange.startTime,
			};
		}
		Object.defineProperties(data, {
			datasetId: {
				value: dataSetDetails?.datasetId,
				enumerable: true,
			},
			runConfigName: {
				value: myDataset
					? dataSetDetails?.datasetId
					: dataSetDetails?.runConfigName,
				enumerable: true,
			},
			datasetFilters: {
				value: myDataset ? [] : dataSetDetails?.datasetFilters,
				enumerable: true,
			},
			datasetTimeRange: {
				value: datasetTimeRange,
				enumerable: true,
			},
			timeRange: {
				value: myDataset ? "fullRange" : dataSetDetails?.timeRange,
				enumerable: true,
			},
			forceRunRequired: {
				value: true,
				enumerable: true,
			},
			isScheduled: {
				value: "ondemand",
				enumerable: true,
			},
		});

		setWaiting(true);
		triggerRatifyRequest(data).then((response) => {
			setWaiting(false);
			response
				.json()
				.then((result) => {
					setWaiting(false);
					if (result.status.statusCode === "ratify-200") {
						handleCloseModal();
						setAlert(true);
						setAlertMessage(result.status.statusMessage);
					} else {
						handleCloseModal();
						setAlert(true);
						setAlertMessage(result.status.statusMessage);
					}
				})
				.catch(() => {
					handleCloseModal();
					setAlert(true);
					setAlertMessage("Server error occured");
				});
		});
	};

	const handleConfiremd = (type) => {
		if (type === "delete") {
			deleteRunConfig();
		} else if (type === "deleteMyDataset") {
			setDeleteStatus("loading");
			deleteRunConfig();
		} else if (type === "deleteStdDataset") {
			setDeleteStatus("loading");
			deleteRunConfig();
		} else {
			triggerRatify();
		}
	};
	const confirmationCloseModal = () => {
		handleCloseModal();
		handleRefreshEvent();
	};

	return (
		<>
			<ErrorAlert
				message={messageAlert}
				open={alert}
				redirect={false}
				handleClose={handleAlertClose}
			/>
			<Dialog
				open={open}
				onClose={handleCloseModal}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				sx={{
					"& .MuiDialog-paper": {
						borderRadius: "8px",
					},
				}}
			>
				{deleteStatus === "loading" && (
					<div className={classes.container}>
						<div
							style={{
								alignItems: "center",
								justifyContent: "center",
								display: "flex",
								flexDirection: "column",
							}}
						>
							<Loader />
							Deleting Dataset ...
						</div>
					</div>
				)}
				{deleteStatus === "pending" && (
					<div className={classes.container}>
						<div style={{ display: "flex", alignItems: "flex-start" }}>
							<DialogContent sx={{ py: "10px" }}>
								{functionType === "delete" && (
									<span>{config.messages.deleteRuns}</span>
								)}
								{(functionType === "deleteMyDataset"
									|| functionType === "deleteStdDataset") && (
									<span>{config.messages.deleteMyDataset}</span>
								)}
								{functionType !== "deleteMyDataset"
									&& functionType !== "delete"
									&& functionType !== "deleteStdDataset" && (
									<span>{config.messages.ratifyRuns}</span>
								)}
							</DialogContent>
							<Box style={{ padding: "5px" }}>
								<i
									className="material-icons-outlined drawer-cancel-icon iconSize"
									onClick={handleCloseModal}
								>
									cancel
								</i>
							</Box>
						</div>
						<DialogActions>
							<Box
								display="flex"
								alignItems="center"
								justifyContent="flex-end"
								mt={1}
							>
								<Button
									className={classes.cancelBtn}
									onClick={handleCloseModal}
								>
									Cancel
								</Button>
								<Button
									disabled={waiting || hold}
									onClick={() => handleConfiremd(functionType)}
									className={classes.reportBtn}
								>
									Continue
								</Button>
							</Box>
						</DialogActions>
					</div>
				)}
				{deleteStatus === "success" && (
					<div className={classes.container}>
						<div style={{ display: "flex", alignItems: "flex-start" }}>
							<DialogContent sx={{ py: "10px" }}>
								{(functionType === "deleteMyDataset"
									|| functionType === "deleteStdDataset") && (
									<div
										style={{
											display: "flex",
											alignItems: "center",
											color: "green",
										}}
									>
										<img src={runSuccess} title="Deleted" alt="Success" />
										<span>{alertMsg}</span>
									</div>
								)}
							</DialogContent>
							<Box style={{ padding: "5px" }}>
								<i
									className="material-icons-outlined drawer-cancel-icon"
									onClick={confirmationCloseModal}
								>
									cancel
								</i>
							</Box>
						</div>
					</div>
				)}
				{deleteStatus === "error" && (
					<div className={classes.container}>
						<div style={{ display: "flex", alignItems: "flex-start" }}>
							<DialogContent sx={{ py: "10px" }}>
								{(functionType === "deleteMyDataset"
									|| functionType === "deleteStdDataset") && (
									<div style={{
										display: "flex",
										alignItems: "center",
										color: "red",
									}}
									>
										<img src={runError} title="Deletion failed" alt="Failed" />
										<span>
Deletion Failed :
											{alertMsg}
										</span>
									</div>
								)}
							</DialogContent>
							<Box style={{ padding: "5px" }}>
								<i
									className="material-icons-outlined drawer-cancel-icon"
									onClick={confirmationCloseModal}
								>
									cancel
								</i>
							</Box>
						</div>
					</div>
				)}
			</Dialog>
		</>
	);
}

RatifyModel.propTypes = {
	open: PropTypes.any.isRequired,
	handleCloseModal: PropTypes.any.isRequired,
	dataSetDetails: PropTypes.any.isRequired,
	myDataset: PropTypes.any,
	deleteRunConfig: PropTypes.any.isRequired,
	functionType: PropTypes.any.isRequired,
	hold: PropTypes.any.isRequired,
	handleRefreshEvent: PropTypes.any.isRequired,
	deleteStatus: PropTypes.any.isRequired,
	setDeleteStatus: PropTypes.any.isRequired,
	alertMsg: PropTypes.any.isRequired,
};
RatifyModel.defaultProps = {
	myDataset: false,
};
export default RatifyModel;
