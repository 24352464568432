import React from "react";
import PropTypes from "prop-types";

import "./index.scss";

function Filter({
	criteria, changeCriteria, width, placeholderText = "",
}) {
	return (
		<div className="aide-filter-new">
			<div className="input-search" style={{ width }}>
				<i className={`material-icons-outlined search-icon `}>search</i>
				<input
					type="text"
					id="search"
					name="search"
					value={criteria.searchText}
					className="input-label"
					onChange={e => changeCriteria("searchText", e.target.value)}
					placeholder={placeholderText || "Search"}
					autoComplete="off"
				/>
				<i
					className={`material-icons-outlined cancel-icon ${criteria.searchText ? "visible" : ""}`}
					onClick={() => changeCriteria("searchText", "")}
				>
					clear
				</i>
			</div>
		</div>
	);
}

Filter.propTypes = {
	criteria: PropTypes.any.isRequired,
	changeCriteria: PropTypes.any.isRequired,
	width: PropTypes.any,
	placeholderText: PropTypes.any.isRequired,
};

Filter.defaultProps = {
	width: '52%',
};

export default React.memo(Filter);
