import axios from "axios";
import { BASE_URL, V1_URL } from "./constants";

export const invokeAPI = async (
	url,
	method = "GET",
	params = {},
	payload = {},
) => {
	method = method.toUpperCase();
	const options = {
		baseURL: `${BASE_URL}${V1_URL}`,
		url,
		method,
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		timeout: 30000,
		params,
	};
	if (["POST", "PUT", "PATCH"].includes(method)) {
		options.data = JSON.stringify(payload);
	}

	let isSuccess = false;
	let errorMSG = null;
	let respData = null;

	try {
		const { data: response } = await axios.request(options);
		const { status: { statusCode, statusMessage } = {} } = response;
		if (statusCode === "ratify-401") {
			window.location.href = "/login";
			return null;
		}
		if (statusCode === "ratify-403") {
			window.location.href = "/home";
			return null;
		}
		if (statusCode === "ratify-200") {
			isSuccess = true;
			respData = response.data;
		} else {
			isSuccess = false;
			errorMSG = statusMessage;
		}
	} catch (errorObj) {
		if (errorObj.response) {
			if (errorObj.response.status === 401) {
				window.location.href = "/login";
				return null;
			}
			if (errorObj.response.status === 403) {
				window.location.href = "/home";
				return null;
			}
			errorMSG = errorObj.response?.errorMessage
				|| errorObj.response.data?.status?.statusMessage
				|| "Server error";
		} else {
			errorMSG = "Server error";
		}
		isSuccess = false;
	}
	return { isSuccess, data: respData, error: errorMSG };
};

export default invokeAPI;
