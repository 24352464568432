// Import required libraies
import { React, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// Import Libraries
import {
	makeStyles, withStyles, Grid, Button,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

// Import Utils
import { getLocationTypeDetailsData } from "../../utils/utils";
import DatasetLocationMappingData from "../../../assets/data/DatasetLocationMapping.json";
import mocky from "../../../assets/data/mocky.json";

import LayoutTopSideBottom from "../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";

// Import Styles
import {
	StyledAutoComplete,
	useStyles02,
	StyledInputBase,
	SubHeading,
} from "../../../styles/js/Styles";

// Import actions
import { updateData } from "../../../actions";

// Import custom Components

// Import Styles
import "../MyDataset/addDataSet.scss";
import DummyDatasetRules from "./DummyDatasetRules";

// Defining styles
const CssTextField = withStyles({
	root: {
		width: "100%",
		backgroundColor: "#f3f3f3",
		padding: "6px 10px",
		height: "24px",
		borderRadius: "4px",
		"& label.Mui-focused": {
			color: "green",
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "#dedede",
		},
		"& .MuiOutlinedInput-root": {
			backgroundColor: "white",
			"& fieldset": {
				borderColor: "#dedede",
			},
			"&:hover fieldset": {
				borderColor: "#dedede",
			},
			"&.Mui-focused fieldset": {
				border: "0.5px solid #dedede",
			},
		},
	},
})(TextField);

const makeRegisterDatasetFooterStyles = () => {
	const useStyles1 = makeStyles(() => ({
		mainContainer: {
			display: "flex",
			height: "calc(100% - 28px)",
			width: "100%",
			flexDirection: "column",
			justifyContent: "space-between",
			overflow: "auto",
		},
		registerDatasetContainer: {
			display: "flex",
			height: "100%",
			width: "100%",
			flexDirection: "column",
			overflow: "auto",
		},
		inputContainerClass: {
			display: "flex",
			"&:disabled": {
				backgroundColor: "#A4D4D7",
				cursor: "default",
			},
		},
		inputDatasetClass: {
			display: "flex",
			flexDirection: "column",
			paddingRight: "50px",
		},
		validateButtonClass: {
			"&:disabled": {
				backgroundColor: "#9F9F9F",
				cursor: "default",
			},
			backgroundColor: "#465A69",
			fontWeight: "700",
			fontFamily: "Hind Siliguri !important",
		},
		validateButtonContainer: {
			display: "flex",
			alignItems: "center",
		},
		footerButtonClass: {
			"&:disabled": {
				backgroundColor: "#A4D4D7",
				cursor: "default",
			},
			backgroundColor: "#4EAFB3",
			fontWeight: "700",
			fontFamily: "Hind Siliguri !important",
		},
		cancelBtn: {
			color: "#4eafb3",
			border: "1px solid #4eafb3",
			fontSize: "13px",
			padding: "0px 10px",
			fontFamily: "Hind Siliguri",
			textTransform: "none",
			marginRight: "10px",
			fontWeight: "700",
		},
		registerDatasetFooterContainer: {
			padding: "25px 0px",
		},
		leftFooterButtons: {
			marginRight: "20px",
		},
		footerButtonsContainer: {
			display: "flex",
			justifyContent: "end",
			alignItems: "flex-end",
		},
		leftButtonsContainer: {
			display: "flex",
			justifyContent: "space-between",
			// width: "225px !important",
			alignItems: "flex-end",
		},
		validatedText: {
			fontFamily: "Hind Siliguri !important",
			fontSize: "14px !important",
			fontWeight: "700",
		},
		validateContainer: {
			"& .validateContentContainer": {
				border: "1px solid rgba(0, 0, 0, 0.3);",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				borderRadius: 5,
				padding: "20px 0",
				textAlign: "center",
				"& p": {
					margin: "0 !important",
					padding: 0,
					color: "#4EAFB3",
					fontSize: 13,
				},
				"& span": {
					color: "#415A6C",
					fontWeight: 600,
					fontSize: 15,
				},
			},
		},
		validationSuccess: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			"& p": {
				color: "#4EAFB3 !important",
			},
		},
		schemaValidationContainer: {
			"& .schemaVerifyContainer": {
				"& p": {
					color: "#415A6C",
				},
				"& span": {
					fontWeight: 500,
					fontSize: 14,
					color: "#4EAFB3",
					textDecoration: "underline",
					cursor: "pointer",
				},
			},
		},
		mainContent: {
			display: "flex",
			// justifyContent: "space-between",
			flexDirection: "column",
			height: "100%",
		},
		textUnderLine: {
			textDecoration: "underline",
			cursor: "pointer",
		},
		rulesContainer: {
			display: "flex",
			alignItems: "end",
			position: "relative",

			"& span": {
				fontSize: 12,
				color: "#415A6C",
				fontWeight: 600,
				cursor: "pointer",
			},
		},
	}));
	return useStyles1;
};

// Create Dataset Registration form
const RegisterDataset = (props) => {
	const { match } = props;
	const classes = useStyles02();

	const dataRegister = DatasetLocationMappingData;

	// For updating values of temporary fields
	const [validationDetailsMetaData, setvalidationDetailsMetaData] = useState(
		{},
	);

	// for updating the validation massage
	const initialValue = {
		datasetDetails: {
			LocationMetaData: ["server", "location", "schema", "table"],
			dataFormat: "bqtable",
			location: "Azure",
			locationID: "azure",
			locationName: "Azure",
			sourceType: "remote",
		},
	};
	const [validState, setValidState] = useState({});

	const useRegisterFooterStyles = makeRegisterDatasetFooterStyles();
	const registerFooterClasses = useRegisterFooterStyles();
	const {
		inputDatasetClass,
		inputContainerClass,
		registerDatasetContainer,
		registerDatasetFooterContainer,
		mainContainer,
		mainContent,
		footerButtonClass,
	} = registerFooterClasses;

	return (
		<>
			<LayoutTopSideBottom match={match}>
				<Breadcrumbs match={match} />
				<div className={mainContainer}>
					<div className={registerDatasetContainer}>
						<div className={mainContent}>
							<div>
								<div className={inputContainerClass}>
									<div className={inputDatasetClass}>
										<SubHeading className="sub-heading">
											Select Environment
										</SubHeading>
										<StyledAutoComplete
											id="share-select-user"
											size="small"
											className={classes.typographyStyle}
											options={getLocationTypeDetailsData(dataRegister.data)}
											value={mocky.env}
											style={{
												fontFamily: "Hind Siliguri !important",
												width: "305px",
												height: "35px",
											}}
											disableClearable
											getOptionLabel={option => option.name || ""}
											getOptionSelected={(option, value) => option.name === value.name
											}
											onChange={() => {
												console.log("clicked");
											}}
											noOptionsText="No option"
											renderInput={params => (
												<CssTextField
													{...params}
													placeholder="Select Environment Name"
													variant="standard"
													className={classes.typographyStyle}
												/>
											)}
										/>
									</div>
								</div>
								<div className={inputContainerClass}>
									<>
										<LocationDetailsFields
											initialValue={initialValue}
											validationDetailsMetaData={validationDetailsMetaData}
											setvalidationDetailsMetaData={
												setvalidationDetailsMetaData
											}
											setValidState={setValidState}
											validState={validState}
										/>
									</>
								</div>
							</div>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									marginTop: "20px",
								}}
							>
								<button type="button" className={footerButtonClass}>
									Validate
								</button>
							</div>
							<div>
								<DummyDatasetRules tableName="edap_stg.igk_ekko" disable newMockyData={mocky.ruleData} />
							</div>
							<div className={registerDatasetFooterContainer}>
								<RegisterDatasetFooter />
							</div>
						</div>
					</div>
				</div>
			</LayoutTopSideBottom>
		</>
	);
};

const LocationDetailsFields = () => {
	const classes = useStyles02();
	const useRegisterFooterStyles = makeRegisterDatasetFooterStyles();
	const registerFooterClasses = useRegisterFooterStyles();
	const { inputDatasetClass } = registerFooterClasses;

	return (
		<>
			<Grid
				container
				spacing={{ xs: 2, md: 3 }}
				columns={{ xs: 4, sm: 8, md: 12 }}
			>
				{[
					{ id: "server", name: "Server" },
					{ id: "location", name: "Database Name" },
					{ id: "schema", name: "Schema" },
					{ id: "table", name: "Table Name" },
				].map((row) => {
					const { id = "", name = "" } = row;
					const el = (
						<Grid item xs={6}>
							<SubHeading>{name}</SubHeading>
							<StyledInputBase
								className={classes.inputTextVal}
								placeholder={`Enter ${name.toLowerCase()}`}
								value={mocky[id] || ""}
								style={{
									fontFamily: "Hind Siliguri !important",
									width: "320px",
								}}
								size="small"
							/>
						</Grid>
					);
					return (
						<div className={inputDatasetClass} key={id}>
							{el}
						</div>
					);
				})}
			</Grid>
		</>
	);
};

const RegisterDatasetFooter = () => {
	const useRegisterFooterStyles = makeRegisterDatasetFooterStyles();
	const registerFooterClasses = useRegisterFooterStyles();
	const {
		footerButtonClass,
		footerButtonsContainer,
		leftButtonsContainer,
		cancelBtn,
	} = registerFooterClasses;
	const [displayMessage, setDisplayMessage] = useState({
		message: "",
		open: false,
	});

	const registerDatasetSend = () => {
		setDisplayMessage({ message: "Changes saved successfully.", open: true });
	};

	return (
		<div
			className={footerButtonsContainer}
			style={{ display: "flex", alignItems: "center" }}
		>
			<p
				style={{
					color: "#4eafb3",
					fontSize: "13px",
					fontFamily: "Hind Siliguri !important",
					paddingRight: "20px",
				}}
			>
				{displayMessage.message}
			</p>
			<div className={leftButtonsContainer}>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "end",
					}}
				>
					<div>
						<Link to="/home/datasets" style={{ textDecoration: "none" }}>
							<Button className={cancelBtn}>Cancel</Button>
						</Link>
						<button
							type="button"
							className={footerButtonClass}
							onClick={registerDatasetSend}
						>
							Save Dataset
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

RegisterDatasetFooter.propTypes = {
};

LocationDetailsFields.propTypes = {
};

RegisterDataset.propTypes = {
	match: PropTypes.object.isRequired,
	validationStatus: PropTypes.any.isRequired,
	dataRegister: PropTypes.any.isRequired,
	setregisterDatasetState: PropTypes.any.isRequired,
	registerStatus: PropTypes.any.isRequired,
	updateData: PropTypes.any.isRequired,
	defaultData: PropTypes.any.isRequired,
	isUpdating: PropTypes.any.isRequired,
};

const mapStateToProps = state => ({
	dataRegister: { ...state.dataset.datasetRegister },
	validationStatus: { ...state.dataset.validationStatus },
	registerStatus: { ...state.dataset.registerStatus },
});
const mapDispatchToProps = { updateData };

export default connect(mapStateToProps, mapDispatchToProps)(RegisterDataset);
