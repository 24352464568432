const backendApi = "/api/v1/";
const fetchDoc = `${backendApi}/datasetupload`;
const signInUrl = `/auth/sign_in`;
const signOutUrl = `/auth/sign_out`;
const configRunUrl = `${backendApi}/config/run`;
const authUrl = `${backendApi}/userinfo`;
const runUrl = `${backendApi}/runs`;
const notificationUrl = `${backendApi}/notifications`;
const triggerRatifyUrl = `${backendApi}/trigger`;
const deleteRunConfigUrl = `${backendApi}/configDelete`;
const scheduleUrl = `${backendApi}/schedule`;
const deleteMyDataset = `${backendApi}/mydatasetDelete`;
const deleteStdDataset = `${backendApi}/stddatasetDelete`;
const updateName = `${backendApi}/datasetnameupdate`;
const updateDataset = `${backendApi}/register`;

const messages = {
	authenticatingUserMessage: "Authenticating user",
	noApps: "No applications available",
	noConfigs: "No configurations available for your selections.",
	noDocuments: "No Document found",
	confirmDelete: "Are you sure you want to delete?",
	runFailed: "Sorry, your job was not successful.",
	runQueued: "Your job is in the queue.",
	runRunning: "Your job is running.",
	runSuccess: "Your job was successful!",
	runSuccessInfo:
		"While we are working on a feature to show you a dashboard with your run outputs, you could download your output files by clicking on the Download button at top right of this pane.",
	noData: "No data available",
	loginPrimaryText: "Dataset Quality Portal",
	loginSecondaryText_1:
		"A Portal to manage all your Data Quality Rules.",
	loginSecondaryText_2: "Log in to find out more!",
	noRunsFound: "No runs found!",
	noRecordsText: "No record found!",
	deleteRuns: "Deleting this subset will remove all the historical runs",
	ratifyRuns: "Ratify this subset with the same parameters",
	deleteMyDataset: "Deleting this dataset will remove all the subset runs permanently !",
	deletedSuccessfully: "Dataset deleted Successfully !",
	deletionFailed: "Dataset deletion failed !",
};

const hardCoded = {
	runSuccess: "Success",
	runFailed: "Failed",
	runQueued: "Queued",
	runRunning: "Running",
	homeLocation: "/home",
	pageSizes: [10, 15, 20, 30, 50],
};

const api = {
	configRunUrl,
	authUrl,
	signInUrl,
	signOutUrl,
	runUrl,
	notificationUrl,
	fetchDoc,
	triggerRatifyUrl,
	deleteRunConfigUrl,
	scheduleUrl,
	deleteMyDataset,
	deleteStdDataset,
	updateName,
	updateDataset,
};

const config = {
	footerText: "Powered by Pulse and RBOne.",
	api,
	messages,
	hardCoded,
	autoHideDuration: 10000,
	fetchNotificationDuration: 300000,
};

export default config;
