import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

function Loader({ fullPage, message }) {
	const useStyles = makeStyles(theme => ({
		root: {
			display: "flex",
			flexDirection: "column",
			margin: theme.spacing(1),
			"& > * + *": {
				margin: theme.spacing(5),
			},
			justifyContent: "center",
			alignItems: "center",
			...(fullPage && {
				height: "100vh",
			}),
		},
		text: {
			color: "#ea3592",
		},
	}));

	const classes = useStyles();

	return (
		<div className={classes.root}>
			{message && <div className={classes.text}>{message}</div>}
			<CircularProgress color="secondary" size="1rem" />
		</div>
	);
}

Loader.propTypes = {
	fullPage: PropTypes.any,
	message: PropTypes.any,
};
Loader.defaultProps = {
	fullPage: false,
	message: "",
};

export default Loader;
