/* eslint-disable indent */
import C from "../actions/constants";

const dataset = (state = {}, payload) => {
	const {
		type, data, datasetId, key, requestId,
	} = payload;
	switch (type) {
		case C.UPDATE_DATA:
			return {
				...state,
				[key]: data,
			};
		case C.GET_STANDARD_DATASET:
			return {
				...state,
				standardList: { status: "loading", message: "", data: [] },
			};
		case C.GET_REGISTER_STATUS:
			return {
				...state,
				registerStatus: { status: "loading", message: "", data: [] },
			};
		case C.GET_MY_DATASET:
			return {
				...state,
				myList: { status: "loading", message: "", data: [] },
			};

		case C.GET_DOWNLOAD_URL_STATUS:
			return {
				...state,
				downloadStatus: { status: "loading", message: "", data: [] },
			};

		case C.STANDARD_DATASET_RECEIVED:
			return {
				...state,
				standardList: { status: "success", message: "", data },
			};
		case C.MY_DATASET_RECEIVED:
			return {
				...state,
				myList: { status: "success", message: "", data },
			};
		case C.GET_DATASET_DETAILS:
			return {
				...state,
				datasetDetails: {
					...state.datasetDetails,
					status: "loading",
					message: "",
				},
			};
		case C.DATASET_DETAILS_RECEIVED:
			return {
				...state,
				datasetDetails: {
					status: "success",
					message: "",
					data: { ...state.datasetDetails.data, [datasetId]: data },
				},
			};
		case C.GET_RUN_HISTORY_DETAILS:
			return {
				...state,
				runHistoryList: { status: "loading", message: "", data: [] },
			};
		case C.RUN_HISTORY_DETAILS_RECEIVED:
			return {
				...state,
				runHistoryList: { status: "success", message: "", data },
			};

		case C.GET_RUN_HISTORY_LIST:
			return {
				...state,
				runHistory: { status: "loading", message: "", data: [] },
			};

		case C.RUN_HISTORY_LIST_RECEIVED:
			return {
				...state,
				runHistory: { status: "success", message: "", data },
			};

		case C.GET_DATASET_SCHEMA:
			return {
				...state,
				datasetConfig: {
					...state.datasetConfig,
					status: "loading",
					message: "",
				},
			};

		case C.DATASET_SCHEMA_RECEIVED:
			return {
				...state,
				datasetConfig: {
					status: "success",
					message: "",
					data: {
						...state.datasetConfig.data,
						[datasetId]: data.datasetSchema || data,
						datasetId: data.datasetId,
						datasetDetails: data.datasetDetails,
						datasetFilterColumns: data.datasetFilterColumns,
					},
				},
			};

		case C.GET_RUNHISTORY_SCHEMA:
			return {
				...state,
				datasetConfig: {
					...state.datasetConfig,
					status: "loading",
					message: "",
				},
			};

		case C.RUNHISTORY_SCHEMA_RECEIVED:
			return {
				...state,
				datasetConfig: {
					status: "success",
					message: "",
					data: {
						...state.datasetConfig.data,
						[requestId]: data.datasetSchema || data,
						datasetId: requestId,
						datasetDetails: data.datasetDetails,
						datasetFilterColumns: data.datasetFilterColumns,
					},
				},
			};

		case C.DATASET_REGISTER_DATA_RECIEVED:
			return {
				...state,
				datasetRegister: data,
			};

		case C.VALIDATION_STATUS_RECIEVED:
			return {
				...state,
				validationStatus: data,
			};

		case C.GET_VALIDATION_STATUS:
			return {
				...state,
				validationStatus: { status: "loading", message: "", data: [] },
			};
		case C.GET_ADD_VALIDATION_STATUS:
			return {
				...state,
				addValidationStatus: { status: "loading", message: "", data: [] },
			};

		case C.ADD_VALIDATION_STATUS_RECIEVED:
			return {
				...state,
				addValidationStatus: data,
			};
		case C.RESET_VALIDATION_STATUS: {
			return {
				...state,
				addValidationStatus: data,
			};
		}
		case C.REGISTER_STATUS_RECIEVED:
			return {
				...state,
				registerStatus: data,
			};

		case C.DOWNLOAD_URL_STATUS_RECIEVED:
			return {
				...state,
				downloadStatus: {
					status: "success",
					message: "",
					data,
				},
			};

		case C.ADD_DATASET_STATUS_RECIEVED:
			return {
				...state,
				addDatasetStatus: data,
			};

		case C.GET_ADD_DATASET_STATUS:
			return {
				...state,
				addDatasetStatus: { status: "loading", message: "", data: [] },
			};
		case C.UPLOAD_STATUS_RECIEVED:
			return {
				...state,
				addUploadStatus: {
					status: "success",
					message: "",
					data,
				},
			};

		case C.UPDATE_DATA_SET_OF_UPLOAD: {
			return {
				...state,
				updateStatusOFUpload: data,
			};
		}
		case C.UPDATE_LOADER_STATUS: {
			return {
				...state,
				status: data,
			};
		}
		default:
			return state;
	}
};

export default dataset;
