// Import required libraries
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";

// Import styles
import "./App.scss";

// Import routes
import Routes from "../routes";

// Import action creators
import { updateUserInfo, resetShowErrorPage } from "../actions";

// Import utils
import config from "./config/config";
import { makeDefaultResponseJson } from "./utils/utils";

// Import custom components
import Loader from "./components/Loader/Loader";

function App({ user, updateUserInfo: updateUserInfoFn }) {
	const dispatch = useDispatch();
	const history = useHistory();
	const [authResolved, setAuthResolved] = useState(false);

	// User authentication
	useEffect(() => {
		const url = config.api.authUrl;
		fetch(url)
			.then((response) => {
				console.groupCollapsed("requesting", url);
				console.log("REPSONSE -> ", response);
				if (response.status === 200) {
					return response.json();
				} if (response.status === 401) {
					const error = response.statusText;
					const errorCode = response.status;
					console.log("ERROR -> ", error);
					return {
						...makeDefaultResponseJson(),
						error,
						errorCode,
						status: {},
					};
				}
				const error = response.statusText;
				const errorCode = response.status;
				console.log("ERROR -> ", error);
				return {
					...makeDefaultResponseJson(),
					error,
					errorCode,
					status: {},
				};
			})
			.then((json) => {
				console.log("JSON -> ", json);
				console.groupEnd();
				if (json.status.statusCode === "ratify-200") {
					updateUserInfoFn({
						name: json.data.name,
						preferred_username: json.data.preferred_username,
						role: json.data.role,
						isAuth: true,
						error: null,
						status: null,
					});
					const location = window.location.pathname;
					setAuthResolved(true);
					history.push(location);
				}
				if (json.errorCode === 401) {
					updateUserInfoFn({
						name: "",
						preferred_username: "",
						isAuth: false,
						error: null,
					});
					setAuthResolved(true);
				} else if (json.errorCode) {
					updateUserInfoFn({ error: json.error, errorCode: json.errorCode });
					setAuthResolved(true);
				}
			});
	}, []);

	// Unauthorized user
	useEffect(() => {
		if (user.error === "UNAUTHORIZED" || user.errorCode === 401) {
			updateUserInfoFn({ isAuth: false });
			window.location.href = config.api.signInUrl;
		}
	}, [user.error]);

	useEffect(() => {
		if (user.showErrorPage) {
			history.push("/error");
		} else {
			dispatch(resetShowErrorPage());
		}
	}, [user.showErrorPage]);

	return (
		<>
			{authResolved ? (
				<Routes />
			) : (
				<Loader
					fullPage
					message={config.messages.authenticatingUserMessage}
				/>
			)}
		</>
	);
}

App.propTypes = {
	updateUserInfo: PropTypes.any.isRequired,
	user: PropTypes.any.isRequired,
};

const mapStateToProps = state => ({
	user: state.user,
});

const mapDispatchToProps = { updateUserInfo };

export default connect(mapStateToProps, mapDispatchToProps)(App);
