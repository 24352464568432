import { makeStyles, withStyles, createStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';

export const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		'& h5': {
			fontWeight: '600',
			color: "#d11450",
		},
		'& .MuiTextField-root': {
			margin: theme.spacing(1),
			width: '150px',
		},
		'& .MuiInputBase-root': {
			fontSize: '0.7em',

		},
		'& .MuiSelect - select.MuiSelect - select': {
			background: '#ededed',
		},
	},
	selectRoot: {
		'&:focus': {
			backgroundColor: 'transparent',
		},
	},

	gridContainerClass: {
		// alignItems:'end'
	},
	gridItemClass: {
		textAlign: 'start',
		marginLeft: '15px',
	},
	gridItemRight: {
		textAlign: 'end',
	},
	selectiontypo: {
		fonFamily: 'Hind Siliguri',
		fontSize: '18px',
		backgroundColor: 'gray',
		margin: '10px',
		padding: '12px',
		borderRadius: '6px',
		color: 'white',
	},
	monthVal: {
		color: '#465A69',
		fontSize: '12px !important',
		fontStyle: 'normal !important',
		fontFamily: 'Hind Siliguri !important',
		fontWeight: '600 !important',
		lineHeight: '19px !important',
		marginLeft: '30px !important',
		textTransform: "capitalize",

	},
	downloadButton: {
		fontFamily: 'Hind Siliguri',
		border: '1px solid #4EAFB3',
		color: '#4EAFB3',
		fontWeight: '500',
		fontSize: '12px',
		lineHeight: '19px',
		textTransform: 'none',
		"&:hover": {
			background: "#4EAFB3",
			color: '#FFFFFF',
		},
	},
	runOnTextCSS: {
		fontFamily: 'Hind Siliguri !important',
		fontStyle: 'normal !important',
		fontSize: '14px !important',
		fontWeight: '500 !important',
		lineHeight: '23px !important',
		color: '#465A69 !important',
		marginLeft: '30px !important',
		marginBottom: '15px !important',
	},
	doneButton: {
		fontFamily: 'Hind Siliguri',
		fontStyle: 'normal',
		fontSize: '12px',
		fontWeight: 600,
		lineHeight: '19px',
		color: '#FFFFFF',
		backgroundColor: '#4EAFB3',
		borderRadius: '5px',
		border: 'none',
		textTransform: 'none',
		// width: '75px',
		paddingLeft: '15px',
		paddingRight: '15px',
		height: '30px',
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: '#4EAFB3',
		"&:hover": {
			background: "none",
			color: '#4EAFB3',
		},
	},
	heading: {
		fontFamily: 'Hind Siliguri',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: '700',
		lineHeight: '32px',
		letterSpacing: '-0.03em',
		marginLeft: '4px',
		paddingLeft: '4px',
		color: '#465A69',
		height: '50vh',
		display: 'flex',
		textAlign: 'center',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		marginBottom: "15px",
	},
	statusIconStyle: {
		width: '22px',
		height: '22px',
		marginRight: '6px',
	},
}));

// export const getCircleBarColor = (percent) => {
// 	const colorObj = {
// 		terrible: { color: '#FF2442', remarks: 'Terrible' },
// 		bad: { color: '#FF6464', remarks: 'Bad' },
// 		moderate: { color: '#FFbd67', remarks: 'Moderate' },
// 		good: { color: '#96cd39', remarks: 'Good' },
// 		excellent: { color: '#50cd93', remarks: 'Excellent' },
// 	};
// 	percent = isString(percent) ? parseInt((percent || "0").split("%")[0], 10) : percent;

// 	if (percent < 20) {
// 		return colorObj.terrible;
// 	}
// 	if (percent >= 21 && percent <= 40) {
// 		return colorObj.bad;
// 	}
// 	if (percent >= 41 && percent <= 70) {
// 		return colorObj.moderate;
// 	}
// 	if (percent >= 71 && percent <= 90) {
// 		return colorObj.good;
// 	}
// 	if (percent >= 91 && percent <= 100) {
// 		return colorObj.excellent;
// 	}
// 	return "";
// };

export const getCircleBarColor = (percent) => {
	const colorObj = {
		terrible: { color: '#FF2442', remarks: 'Terrible' },
		bad: { color: '#FF6464', remarks: 'Bad' },
		moderate: { color: '#FFbd67', remarks: 'Moderate' },
		good: { color: '#96cd39', remarks: 'Good' },
		excellent: { color: '#50cd93', remarks: 'Excellent' },
	};

	if (percent === 100) {
		return colorObj.good;
	}
	return colorObj.terrible;
};

export const CustomMenuItem = withStyles(() => createStyles({
	// root: {
	// 	"&$selected": {
	// 		backgroundColor: "red",
	// 		"&:hover": {
	// 			backgroundColor: "green",
	// 		},
	// 	},
	// 	'&:hover': {
	// 		backgroundColor: 'blue',
	// 	},
	// },
	selected: {

	},
}))(MenuItem);
