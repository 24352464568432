export const BASE_URL = "/api";
export const V1_URL = "/v1";

export const DATASET_URL = "/search";
export const RUN_HISTORY_URL = "/runhistory";
export const DATASET_SCHEMA_URL = "/confView";
export const RUNHISTORY_SCHEMA_URL = "/schemahistory";

export const useMocky = false;
export const DATASET_URL_MOCKY = "https://run.mocky.io/v3/1ef7e7a3-cdb1-4411-bc00-2c4bb230def9";
export const DATASET_DETAILS_URL_MOCKY = "https://run.mocky.io/v3/3c77d0ed-fed2-473e-ad8e-5cce0374418b";
export const DATASET_SCHEMA_URL_MOCKY = "";
export const DATASET_REGISTER_URL = "/datasetlocationMapping";
export const VALIDATION_CONFIG_URL = "/conf";
export const VALIDATION_CONFIG_URL_MOCKY = "https://run.mocky.io/v3/3d40016c-c73f-43b2-9144-b43095b643d9";
export const REGISTER_CONFIG_URL = "/register";
export const MY_DATASET_URL = "/mydatasets";
export const UPLOAD_FILE_URL = "/datasetupload";
export const MOCKY_MYDATASET_URL = "https://run.mocky.io/v3/a9eaa8a0-7e86-4389-aa9d-55b9aa7791f9";
export const ADD_VALID_CONFIG_URL = "/conf";
export const DOWNLOAD_URL = "/filedownload";
