/* eslint-disable react/jsx-indent-props */
/* eslint-disable indent */
// Import required libraries
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import {
	TableRow,
	Grid,
	Popover,
	MenuItem,
	Menu,
	Select,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Pagination } from "@material-ui/lab";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

// Import data & utils
import config from "../../js/config/config";
import { formatDate, getRoundOfScore } from "../../utils";
import DiagnosticReport from "../DiagnosticReport";
import RatifyModel from "./RatifyModal";
import { CustomMenuItem } from "../DiagnosticReport/ReportSideSecondStyles";
import runProgress from "../../assets/images/ratify/run-progress.svg";
import runError from "../../assets/images/ratify/run-error.svg";
import runSuccess from "../../assets/images/ratify/run-success.svg";
import SchedulerModel from "../RatifyForm/SchedulerModel";
import ErrorAlert from "../RatifyForm/ErrorAlert";

// Import action creators
import { getDatasetDetails } from "../../actions";
// Import styles
import {
	StyledTableCell,
	useStyles,
	rowStyle,
	iconStyle,
	popOverHeading,
	popOverBody,
	popOverContent,
	statusIconStyle,
	customMenuItemStyle,
} from "./styles";
import "./index.scss";

const statusIconMapping = {
	"in-progress": runProgress,
	pending: runProgress,
	failed: runError,
	completed: runSuccess,
};

const statusNameMapping = {
	"in-progress": "In Progress",
	pending: "Pending",
	completed: "Completed",
	failed: "Failed",
};


const PopOverDatasetDetails = ({
	item,
	setAlert,
	setAlertMessage,
	showDatasetDetails,
	userRole,
	dataSetDetails,
	// navigateToDetails,
}) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const classes = useStyles();
	const dispatch = useDispatch();
	const [waiting, setWaiting] = React.useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [functionType, setFunctionType] = useState("");

	const handleClose = () => setAnchorEl(null);
	const handleCloseModal = useCallback(() => {
		setOpenModal(false);
	}, []);
	const handleClick = event => setAnchorEl(event.currentTarget);
	const deleteRunConfig = () => {
		setWaiting(true);
		const url = `${config.api.deleteRunConfigUrl}?runConfigId=${item.runConfigId}`;
		fetch(url, {
			method: "DELETE",
		}).then((response) => {
			response
				.json()
				.then((result) => {
					if (result.status.statusCode === "ratify-200") {
						showDatasetDetails(item);
						handleCloseModal();
					} else {
						setAlert(true);
						setAlertMessage(result.status.statusMessage);
					}
					setWaiting(false);
					handleClose();
				})
				.catch(() => {
					setWaiting(false);
					setAlertMessage("Server error occured!");
					setAlert(true);
				});
		});
	};
	const history = useHistory();
	const handleRoute = () => {
		history.push(`/home/ratify/${item.datasetId}/${item.runConfigId}`);
	};

	const handleOpenModal = (type) => {
		setFunctionType(type);
		setOpenModal(true);
		handleClose();
	};
	const handleRefreshEvent = () => {
		dispatch(getDatasetDetails({ datasetId: item.datasetId }));
	};

	return (
		<>
			<i
				className="material-icons more-vert-icon-style"
				aria-label="more"
				aria-controls="long-menu"
				aria-haspopup="true"
				onClick={handleClick}
			>
				more_vert
			</i>
			<Menu
				id="long-menu"
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={handleClose}
			>
				{userRole === "admin" && (
					<MenuItem
						onClick={() => handleOpenModal("delete")}
						className={classes.fontStyle}
						style={{ fontWeight: "400" }}
						disabled={waiting || userRole !== "admin"}
					>
						Delete
					</MenuItem>
				)}
				{userRole === "admin" && (
					<MenuItem
						onClick={handleRoute}
						className={classes.fontStyle}
						style={{ fontWeight: "400" }}
						disabled={userRole !== "admin"}
					>
						Edit
					</MenuItem>
				)}
				<MenuItem
					className={classes.fontStyle}
					style={{ fontWeight: "400" }}
					onClick={() => handleOpenModal("ratify")}
				>
					Ratify
				</MenuItem>
			</Menu>
			<RatifyModel
				functionType={functionType}
				hold={waiting}
				dataSetDetails={dataSetDetails}
				deleteRunConfig={deleteRunConfig}
				handleCloseModal={handleCloseModal}
				open={openModal}
				setOpenModal={setOpenModal}
				handleRefreshEvent={handleRefreshEvent}
				role={userRole}
			/>
		</>
	);
};

PopOverDatasetDetails.propTypes = {
	item: PropTypes.any.isRequired,
	setAlert: PropTypes.any.isRequired,
	setAlertMessage: PropTypes.any.isRequired,
	showDatasetDetails: PropTypes.any.isRequired,
	userRole: PropTypes.any.isRequired,
	dataSetDetails: PropTypes.any.isRequired,
	// navigateToDetails: PropTypes.any.isRequired,
};

React.memo(PopOverDatasetDetails);

const DatasetDetails = ({
	datasetDetails = [],
	setRefresh,
	refresh,
	item,
	showDatasetDetails,
	userRole,
	colSpan,
	myDatasetPage,
	navigateToDetails,
}) => {
	const classes = useStyles();

	const [anchorEl, setAnchorEl] = useState(null);
	const [datasetFiltersToShow, setDatasetFiltersToShow] = useState([]);
	const [messageAlert, setAlertMessage] = React.useState(false);
	const [alert, setAlert] = React.useState(false);
	const open = Boolean(anchorEl);

	const handleClick = (event, datasetFilters) => {
		const tempValues = [...datasetFilters];
		// tempValues.push({ name: "Time range", value: [timeRange] });
		setDatasetFiltersToShow(tempValues);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const pageSizes = [...config.hardCoded.pageSizes];

	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);

	const handleChangePage = (ev, newPage) => {
		setPage(newPage);
	};

	const handlePageSizeChange = (event) => {
		setPageSize(event.target.value);
		setPage(1);
	};

	const displayRecords = [...datasetDetails].splice(
		(page - 1) * pageSize,
		pageSize,
	);
	const noOfPages = Math.ceil(datasetDetails.length / pageSize);
	return (
		<>
			<ErrorAlert
				message={messageAlert}
				open={alert}
				redirect={false}
				handleClose={() => setAlert(false)}
			/>
			<TableRow className="subsection">
				<StyledTableCell colSpan={colSpan}>
					<div className={classes.gridRoot} style={rowStyle}>
						<Grid container className="details-header">
							{!myDatasetPage && (
								<Grid item xs={3} style={{ paddingLeft: "10px" }}>
									Subset Name
								</Grid>
							)}
							{myDatasetPage && (
								<Grid item xs={3} style={{ paddingLeft: "10px" }}>
									Dataset Name
								</Grid>
							)}
							{!myDatasetPage && (
								<Grid item xs={3}>
									Filters
								</Grid>
							)}
							<Grid item xs={myDatasetPage ? 3 : 2}>
								Last scored on
							</Grid>
							<Grid item xs={myDatasetPage ? 3 : 1}>
								Score
							</Grid>
							{!myDatasetPage && (
								<Grid item xs={1}>
									Scheduled
								</Grid>
							)}
							<Grid item xs={myDatasetPage ? 3 : 2} />
						</Grid>
					</div>

					{displayRecords.length ? (
						displayRecords.map((obj, ind) => {
							const conStr = (obj.datasetFilters || [])
								.map(
									filter => `${filter?.name} - ${Array.isArray(filter?.value)
										? filter?.value?.join(", ")
										: ""
										}`,
								)
								.join("; ");

							return (
								<div
									className={classes.gridRoot}
									key={`${obj.runConfigName}-${ind}`}
									style={rowStyle}
								>
									<Grid container>
										<Grid item xs={3}>
											<div className="config-name">
												<img
													src={
														statusIconMapping[
														obj.lastRunStatus?.toLowerCase() || "pending"
														]
													}
													style={statusIconStyle}
													title={
														statusNameMapping[
														obj.lastRunStatus?.toLowerCase()
														]
														|| obj.lastRunStatus
														|| "Pending"
													}
													alt="statusIcon"
												/>
												<span title={obj.runConfigName}>
													{obj.runConfigName}
												</span>
											</div>
										</Grid>
										{!myDatasetPage && (
											<Grid item xs={3}>
												<div
													className="config-name w-75"
													style={{ float: "left", textTransform: "capitalize" }}
												>
													{conStr}
												</div>
												<InfoOutlinedIcon
													style={iconStyle}
													onClick={e => handleClick(e, obj.datasetFilters, obj.timeRange)
													}
												/>
												<Popover
													open={open}
													anchorEl={anchorEl}
													onClose={handleClose}
													anchorOrigin={{
														vertical: "bottom",
														horizontal: "right",
													}}
													transformOrigin={{
														vertical: "top",
														horizontal: "right",
													}}
													className="dataset-params"
												>
													<div style={popOverContent}>
														<Grid container>
															{datasetFiltersToShow.map((filter, ind1) => (
																<Grid
																	item
																	xs={12}
																	style={{ marginTop: ind1 ? "5px" : "" }}
																	key={ind1}
																>
																	<div style={popOverHeading}>
																		{filter.name}
																	</div>
																	<div style={popOverBody}>
																		{filter.value.join(", ")}
																	</div>
																</Grid>
															))}
														</Grid>
													</div>
												</Popover>
											</Grid>
										)}
										<Grid item xs={myDatasetPage ? 3 : 2}>
											{obj.lastRunOn === 0
												? " - "
												: formatDate(obj.lastRunOn, "MMMM DD, yyyy")}
										</Grid>
										{" "}
										<Grid item xs={myDatasetPage ? 3 : 1}>
											{obj.response?.aggregateScore >= 0
												? `${getRoundOfScore(obj.response?.aggregateScore)}%`
												: " - "}
										</Grid>
										{!myDatasetPage && (
											<Grid item xs={1} className={classes.textWraping}>
												<SchedulerModel
													dataSetDetails={obj}
													setRefresh={setRefresh}
													refresh={refresh}
													item={item}
													showDatasetDetails={showDatasetDetails}
												/>
											</Grid>
										)}
										<Grid
											item
											container
											xs={myDatasetPage ? 3 : 2}
											className={classes.textAlignment}
										>
											<Grid item xs={myDatasetPage ? 12 : 10}>
												<DiagnosticReport dataobj={obj} />
											</Grid>
											{!myDatasetPage && (
												<Grid item xs={2}>
													<PopOverDatasetDetails
														item={obj}
														setAlertMessage={setAlertMessage}
														setAlert={setAlert}
														showDatasetDetails={showDatasetDetails}
														userRole={userRole}
														dataSetDetails={obj}
														navigateToDetails={navigateToDetails}
													/>
												</Grid>
											)}
										</Grid>
									</Grid>
								</div>
							);
						})
					) : (
						<p className="text-center-align">{config.messages.noRunsFound}</p>
					)}
					{datasetDetails.length > 10 && (
						<Grid container style={{ marginTop: "15px" }}>
							<Grid item>
								<Pagination
									count={noOfPages}
									page={page}
									shape="rounded"
									onChange={handleChangePage}
									siblingCount={0}
									boundaryCount={2}
									className={classes.pagination}
								/>
							</Grid>
							<Grid item xs={1}>
								<Select
									onChange={handlePageSizeChange}
									value={pageSize}
									disableUnderline
									className={classes.paginationSelect}
									IconComponent={ExpandMoreIcon}
								>
									{pageSizes.map(size => (
										<CustomMenuItem
											key={size}
											value={size}
											style={customMenuItemStyle}
										>
											View
											{' '}
											{size}
										</CustomMenuItem>
									))}
								</Select>
							</Grid>
						</Grid>
					)}
				</StyledTableCell>
			</TableRow>
		</>
	);
};

DatasetDetails.propTypes = {
	datasetDetails: PropTypes.any.isRequired,
	setRefresh: PropTypes.any.isRequired,
	refresh: PropTypes.any.isRequired,
	item: PropTypes.any.isRequired,
	showDatasetDetails: PropTypes.any.isRequired,
	userRole: PropTypes.any.isRequired,
	colSpan: PropTypes.any.isRequired,
	myDatasetPage: PropTypes.any,
	navigateToDetails: PropTypes.any.isRequired,
};
DatasetDetails.defaultProps = {
	myDatasetPage: false,
};

export default DatasetDetails;
