import C from "./constants";

export const updateData = (key, data) => ({
	type: C.UPDATE_DATA,
	key,
	data,
});

export const updateSelections = (key, data) => ({
	type: C.UPDATE_SELECTIONS,
	key,
	data,
});

export const updateMultiSelections = data => ({
	type: C.UPDATE_MULTI_SELECTIONS,
	data,
});

export const updateAlertInfo = data => ({
	type: C.UPDATE_ALERT_INFO,
	data,
});

export const updateUserInfo = data => ({
	type: C.UPDATE_USER_INFO,
	data,
});

export const resetFilters = () => ({
	type: C.RESET_FILTERS,
});

export const updateConfigUserInputs = (key, data) => ({
	type: C.UPDATE_CONFIG_USER_INPUTS,
	key,
	data,
});

export const updateCountryRegions = data => ({
	type: C.UPDATE_COUNTRY_REGIONS,
	data,
});

export const emptyTestControl = () => ({
	type: C.EMPTY_TEST_CONTROL,
});

export const updateControlRegions = () => ({
	type: C.UPDATE_CONTROL_REGIONS,
});

export const updateTestRegions = () => ({
	type: C.UPDATE_TEST_REGIONS,
});

export const updateRegionsData = () => ({
	type: C.UPDATE_REGIONS_DATA,
});

export const updateWholeConfigUserInputs = data => ({
	type: C.UPDATE_WHOLE_CONFIG_USER_INPUTS,
	data,
});

export const toggleModel = data => ({
	type: C.TOGGLE_MODEL,
	data,
});

export const addDataset = data => ({
	type: C.ADD_DATASET,
	data,
});

export const removeDataset = data => ({
	type: C.REMOVE_DATASET,
	data,
});

export const addKpi = data => ({
	type: C.ADD_KPI,
	data,
});

export const removeKpi = data => ({
	type: C.REMOVE_KPI,
	data,
});

export const updateDatasetLocalParameters = data => ({
	type: C.UPDATE_DATASET_LOCAL_PARAMETERS,
	data,
});

export const updateModelLocalParameters = data => ({
	type: C.UPDATE_MODEL_LOCAL_PARAMETERS,
	data,
});

export const updateCategory = data => ({
	type: C.UPDATE_CATEGORY,
	data,
});

export const updateRunData = data => ({
	type: C.UPDATE_RUN_DATA,
	data,
});

export const reloadRunData = () => ({
	type: C.RELOAD_RUN_DATA,
});

export const refreshData = () => ({
	type: C.REFRESH_DATA,
});

export const updateDialogInfo = data => ({
	type: C.UPDATE_DIALOG_INFO,
	data,
});

export const reloadConfigs = () => ({
	type: C.RELOAD_CONFIGS,
});

export const toggleDataset = data => ({
	type: C.TOGGLE_DATASET,
	data,
});

export const toggleKpi = data => ({
	type: C.TOGGLE_KPI,
	data,
});

export const addToggleKpi = data => ({
	type: C.ADD_TOGGLE_KPI,
	data,
});

export const updateDatasetOnCountryChange = data => ({
	type: C.UPDATE_DATASET_ON_COUNTRY_CHANGE,
	data,
});

export const updateCategoryDataset = data => ({
	type: C.UPDATE_CATEGORY_DATASET,
	data,
});

export const selectAll = data => ({
	type: C.SELECT_ALL,
	data,
});

export const clearAll = data => ({
	type: C.CLEAR_ALL,
	data,
});

export const toggleSidenav = data => ({
	type: C.TOGGLE_SIDENAV,
	data,
});

export const getStandardDataset = data => ({
	type: C.GET_STANDARD_DATASET,
	data,
});

export const getMyDataset = data => ({
	type: C.GET_MY_DATASET,
	data,
});

export const getDatasetDetails = data => ({
	type: C.GET_DATASET_DETAILS,
	data,
});

export const getRunHistoryDetails = data => ({
	type: C.GET_RUN_HISTORY_DETAILS,
	data,
});

export const getRunHistory = data => ({
	type: C.GET_RUN_HISTORY_LIST,
	data,
});

export const getDatasetSchema = data => ({
	type: C.GET_DATASET_SCHEMA,
	data,
});

export const getRunhistorySchema = data => ({
	type: C.GET_RUNHISTORY_SCHEMA,
	data,
});

export const resetShowErrorPage = () => ({
	type: C.RESET_REDIRECT_TO_ERROR,
});

export const getRegisterDatasetData = data => ({
	type: C.GET_DATASET_REGISTER_DATA,
	data,
});

export const getValidationStatus = data => ({
	type: C.GET_VALIDATION_STATUS,
	data,
});

export const getAddValidationStatus = data => ({
	type: C.GET_ADD_VALIDATION_STATUS,
	data,
});

export const getRegisterStatus = data => ({
	type: C.GET_REGISTER_STATUS,
	data,
});

export const getAddDatasetStatus = data => ({
	type: C.GET_ADD_DATASET_STATUS,
	data,
});

export const getUploadStatus = data => ({
	type: C.GET_UPLOAD_STATUS,
	data,
});

export const getDownloadUrlStatus = data => ({
	type: C.GET_DOWNLOAD_URL_STATUS,
	data,
});

export const resetValidationStatus = () => ({
	type: C.RESET_VALIDATION_STATUS,
	data: { status: "pending", message: "", data: [] },
});

export const updateLoaderStatus = data => ({
	type: C.UPDATE_LOADER_STATUS,
	data,
});
