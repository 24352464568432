// Import required libraries
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Import styles
import "./AppRow.scss";

// Import custom components
import AppCard from "../AppCard/AppCard";
import noDataImage from "../../../assets/images/undraw_void.svg";

// Import utils
import config from "../../config/config";
import { flattenObject, getSortedData } from "../../utils/utils";

function AppRow({
	data, title, user, match, popOverFlag, allAppsFlag, customClassName,
}) {
	let sortedData = data.slice();

	if (match.params.app) {
		data = data.map(row => flattenObject(row));
		sortedData = getSortedData(
			data,
			user.selections.tableOrderBy,
			user.selections.tableOrder,
		);
	}

	return (
		<div
			className={`${user.theme} apps-row ${customClassName || ""
			}`}
		>
			{title && <h1 className="apps-row-title">{title}</h1>}
			{data.length ? (
				<div
					className={`apps-row-grid ${customClassName || ""}`}
				>
					{sortedData.map((obj, index) => (
						<AppCard
							key={index}
							config={obj}
							name={obj.name}
							match={match}
							popOverFlag={popOverFlag}
							allAppsFlag={allAppsFlag}
							customClassName={customClassName}
						/>
					))}
				</div>
			) : match.params.app ? (
				<p className="no-data">{config.messages.noConfigs}</p>
			) : (
				// <p className="no-data">{config.messages.noApps}</p>
				<div className="no-apps">
					<img src={noDataImage} alt="noDataImage" />
					<p className="no-data">
						<b>{config.messages.noApps}</b>
					</p>
				</div>
			)}
		</div>
	);
}

AppRow.propTypes = {
	title: PropTypes.string.isRequired,
	data: PropTypes.array.isRequired,
	user: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	popOverFlag: PropTypes.any.isRequired,
	allAppsFlag: PropTypes.any.isRequired,
	customClassName: PropTypes.any.isRequired,
};

const mapStateToProps = state => ({ user: state.user });

export default connect(mapStateToProps)(AppRow);
