// Import required libraies
import {
	React,
	useEffect,
	// useState
} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Import Custom Components
import LayoutTopSideBottom from "../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import AddDataset from "./AddDataset";
// import RegisterDataset from "./RegisterDataset";
// import Loader from "../../components/Loader/Loader";

// Import Utils , Data , Images

// Import API functions
import { updateData } from "../../../actions";

// Import Actions/Reducers

// Import Custom Hooks

// Import styles

// update state
// 5 fields - state

const AddDatasetContainer = (props) => {
	const { match, updateData: updateDataFn } = props;
	// const dispatch = useDispatch();

	useEffect(
		() => () => {
			updateDataFn("addDatasetStatus", {
				status: "pending",
				message: "",
				data: [],
			});
			updateDataFn("addUploadStatus", {
				status: "pending",
				message: "",
				data: [],
			});
			updateDataFn("addValidationStatus", {
				status: "pending",
				message: "",
				data: [],
			});
			updateDataFn("validationStatus", {
				status: "pending",
				message: "",
				data: [],
			});
		},
		[],
	);

	return (
		<>
			<LayoutTopSideBottom match={match}>
				<Breadcrumbs match={match} />
				<AddDataset />
			</LayoutTopSideBottom>
		</>
	);
};

AddDatasetContainer.propTypes = {
	match: PropTypes.object.isRequired,
	// dataRegister: PropTypes.any.isRequired,
	updateData: PropTypes.any.isRequired,
};
const mapDispatchToProps = { updateData };

export default connect(null, mapDispatchToProps)(AddDatasetContainer);
