import { put, takeLatest, all } from "redux-saga/effects";
import * as apiService from "../apiServices";
import C from "../actions/constants";

function* fetchStandardDataset(payload = {}) {
	const {
		isSuccess,
		data = [],
		error,
	} = yield apiService.getStandardDataset(payload.data);

	if (isSuccess) {
		yield put({ type: C.STANDARD_DATASET_RECEIVED, data });
	} else {
		console.log(error); // need to show error message
		yield put({ type: C.REDIRECT_TO_ERROR });
	}
}

function* fetchMyDataset(payload = {}) {
	const {
		isSuccess,
		data = [],
		error,
	} = yield apiService.getMyDataset(payload.data);

	if (isSuccess) {
		yield put({ type: C.MY_DATASET_RECEIVED, data });
	} else {
		console.log(error); // need to show error message
		yield put({ type: C.REDIRECT_TO_ERROR });
	}
}

function* fetchDatasetDetails(payload = {}) {
	const {
		isSuccess,
		data = [],
		error,
	} = yield apiService.getDataSetDetails(payload.data);

	if (isSuccess) {
		yield put({
			type: C.DATASET_DETAILS_RECEIVED,
			data,
			datasetId: payload.data.datasetId,
		});
	} else {
		console.log(error); // need to show error message
	}
}

function* fetchRunHistoryDetails(payload = {}) {
	const {
		isSuccess,
		data = [],
		error,
	} = yield apiService.getRunHistoryDetails(payload.data);
	if (isSuccess) {
		yield put({ type: C.RUN_HISTORY_DETAILS_RECEIVED, data });
	} else {
		console.log(error); // need to show error message
	}
}

function* fetchRunHistoryList(payload = {}) {
	const {
		isSuccess,
		data = [],
		error,
	} = yield apiService.getRunHistoryList(payload.data);
	if (isSuccess) {
		yield put({ type: C.RUN_HISTORY_LIST_RECEIVED, data });
	} else {
		console.log(error); // need to show error message
	}
}

function* fetchConfView(payload = {}) {
	const {
		isSuccess,
		data = [],
		error,
	} = yield apiService.fetchConfView(payload.data);
	if (isSuccess) {
		yield put({
			type: C.DATASET_SCHEMA_RECEIVED,
			data,
			datasetId: payload.data.datasetId,
		});
	} else {
		console.log(error); // need to show error message
	}
}

function* fetchRunHistoryConfView(payload = {}) {
	const {
		isSuccess,
		data = [],
		error,
	} = yield apiService.getRunHistoryConfView(payload.data);
	if (isSuccess) {
		yield put({
			type: C.RUNHISTORY_SCHEMA_RECEIVED,
			data,
			requestId: payload.data.requestId,
		});
	} else {
		console.log(error); // need to show error message
	}
}

function* fetchRegisterData(payload = {}) {
	const {
		isSuccess,
		data = [],
		error,
	} = yield apiService.getRegisterDatasetData(payload.data);
	if (isSuccess) {
		yield put({
			type: C.DATASET_REGISTER_DATA_RECIEVED,
			data: { data, message: "", status: "success" },
		});
	} else {
		yield put({
			type: C.DATASET_REGISTER_DATA_RECIEVED,
			data: { data, message: error, status: "error" },
		});
		console.log(error); // need to show error message
	}
}

function* fetchValidationStatus(payload = {}) {
	const {
		isSuccess,
		data = [],
		error,
	} = yield apiService.getValidationStatus(payload.data);

	console.log("error Ravindra");
	if (isSuccess) {
		yield put({
			type: C.VALIDATION_STATUS_RECIEVED,
			data: { data, message: "", status: "success" },
		});
	} else {
		yield put({
			type: C.VALIDATION_STATUS_RECIEVED,
			data: { data, message: error, status: "error" },
		});
		console.log(error); // need to show error message
	}
}

function* fetchAddValidationStatus(payload = {}) {
	const {
		isSuccess,
		data = [],
		error,
	} = yield apiService.getAddValidationStatus(payload.data);

	if (isSuccess) {
		yield put({
			type: C.ADD_VALIDATION_STATUS_RECIEVED,
			data: { data, message: "", status: "success" },
		});
	} else {
		yield put({
			type: C.ADD_VALIDATION_STATUS_RECIEVED,
			data: { data, message: error, status: "error" },
		});
		console.log(error); // need to show error message
	}
}

function* fetchRegisterStatus(payload = {}) {
	const {
		isSuccess,
		data = [],
		error,
	} = yield apiService.getRegisterStatus(payload.data);
	if (isSuccess) {
		yield put({
			type: C.REGISTER_STATUS_RECIEVED,
			data: { data, message: "", status: "success" },
		});
	} else {
		yield put({
			type: C.REGISTER_STATUS_RECIEVED,
			data: { data, message: error, status: "error" },
		});
		console.log(error); // need to show error message
	}
}

function* fetchAddDatasetStatus(payload = {}) {
	const {
		isSuccess,
		data = [],
		error,
	} = yield apiService.getAddDatasetStatus(payload.data);
	if (isSuccess) {
		yield put({
			type: C.ADD_DATASET_STATUS_RECIEVED,
			data: { data, message: "", status: "success" },
		});
	} else {
		yield put({
			type: C.ADD_DATASET_STATUS_RECIEVED,
			data: { data, message: error, status: "error" },
		});
		console.log("ERROR ===>", error); // need to show error message
	}
}

function* fetchUploadStatus(payload = {}) {
	console.log("INSIDE FETCH===>");
	const {
		isSuccess,
		data = [],
		error,
	} = yield apiService.getUploadStatus(payload.data);
	if (isSuccess) {
		yield put({ type: C.UPLOAD_STATUS_RECIEVED, data });
	} else {
		console.log(error); // need to show error message
	}
}

function* fetchDownloadUrlStatus(payload = {}) {
	const {
		isSuccess,
		data = [],
		error,
	} = yield apiService.getDownloadUrlStatus(payload.data);
	if (isSuccess) {
		yield put({ type: C.DOWNLOAD_URL_STATUS_RECIEVED, data });
	} else {
		console.log(error); // need to show error message
	}
}

export default function* rootSaga() {
	yield all([
		takeLatest(C.GET_STANDARD_DATASET, fetchStandardDataset),
		takeLatest(C.GET_MY_DATASET, fetchMyDataset),
		takeLatest(C.GET_DATASET_DETAILS, fetchDatasetDetails),
		takeLatest(C.GET_RUN_HISTORY_DETAILS, fetchRunHistoryDetails),
		takeLatest(C.GET_RUN_HISTORY_LIST, fetchRunHistoryList),
		takeLatest(C.GET_DATASET_SCHEMA, fetchConfView),
		takeLatest(C.GET_RUNHISTORY_SCHEMA, fetchRunHistoryConfView),
		takeLatest(C.GET_DATASET_REGISTER_DATA, fetchRegisterData),
		takeLatest(C.GET_VALIDATION_STATUS, fetchValidationStatus),
		takeLatest(C.GET_REGISTER_STATUS, fetchRegisterStatus),
		takeLatest(C.GET_UPLOAD_STATUS, fetchUploadStatus),
		takeLatest(C.GET_ADD_VALIDATION_STATUS, fetchAddValidationStatus),
		takeLatest(C.GET_ADD_DATASET_STATUS, fetchAddDatasetStatus),
		takeLatest(C.GET_DOWNLOAD_URL_STATUS, fetchDownloadUrlStatus),
	]);
}
