import {
	makeStyles, styled, TableCell, withStyles,
} from "@material-ui/core";
import Rating from '@mui/material/Rating';

export const StyledTableCell = withStyles(() => ({
	body: {
		color: "#46596a",
		fontFamily: "Hind Siliguri",
		padding: "20px 0px",
		alignItems: props => (props.center ? "center" : null),
		display: props => (props.flex ? "flex" : null),
	},
}))(TableCell);

export const useStyles = makeStyles(theme => ({
	pagination: {
		"& .MuiPaginationItem-root": {
			fontFamily: "Hind Siliguri",
			fontStyle: "normal",
			fontWeight: 600,
			fontSize: "12px",
			color: "#46596a",
		},
		"& .MuiPaginationItem-page.Mui-selected": {
			backgroundColor: "#EB367F",
			color: "#ffffff",
		},
	},
	paginationSelect: {
		paddingLeft: "10px",
		paddingRight: "10px",
		fontFamily: "Hind Siliguri",
		fontStyle: "normal",
		fontWeight: 600,
		fontSize: "12px",
		lineHeight: "23px",
		color: "#ffffff",
		backgroundColor: "#46596A",
		borderRadius: "5px",
		"& .MuiSvgIcon-root": {
			color: "#ffffff",
		},
	},
	pageContent: {
		margin: theme.spacing(5),
		padding: theme.spacing(3),
	},
	listIcon: {
		display: "flex",
		alignItems: "center",
	},
	name: {
		maxWidth: "500px",
		minWidth: "100px",
		fontStyle: "normal",
		fontWeight: 600,
		fontSize: "13px !important",
		lineHeight: "23px",
	},
	tableRow: {
		padding: "9px !important",
		fontStyle: "normal",
		fontWeight: 600,
		fontSize: "13px !important",
		lineHeight: "23px",
		borderBottom: "1px solid #E6E8ED",
		"&:last-child th": {
			borderBottom: 0,
		},
		backgroundColor: "white !important",
		"&:hover": {
			backgroundColor: "#4eafb342 !important",
		},
	},
	gridRoot: {
		flexGrow: 1,
		cursor: 'default !important',
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
	ratingContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginRight: '10px',
		marginTop: '8px',
		marginBottom: '6px',
	},
	ratingBox: {
		width: '10px',
		height: '14px',
		marginRight: '2px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	rating: {
		height: '100%',
	},
	empty: {
		height: '100%',
		backgroundColor: '#ECECEC',
	},
	fontStyle: {
		paddingLeft: '10px',
		fontFamily: 'Hind Siliguri',
		fontStyle: 'normal',
		fontWeight: 600,
		fontSize: '12px',
		lineHeight: '19px',
		color: '#465A69',
		background: '#fff',
	},
	rafifyBtn: {
		border: '1.3px solid #4EAFB3',
		boxSizing: 'border-box',
		borderRadius: '23px',
		color: '#4EAFB3',
		fontStyle: 'normal',
		fontWeight: '500',
		fontSize: '10px',
		lineHeight: '16px',
		textTransform: 'none',
		// backgroundColor: 'transparent',
		"&:hover": {
			color: '#fff',
			backgroundColor: '#4EAFB3',
		},
	},
	reportBtn: {
		color: "#fff",
		border: "1px solid #4eafb3",
		background: "#4eafb3",
		fontSize: "12px",
		height: "24px",
		padding: "0px 10px",
		fontFamily: "Hind Siliguri",
		textTransform: "none",
		"&:hover": {
			background: "#4eafb3",
			color: "#ffffff",
		},
		"&.Mui-disabled": {
			color: "#ffffff",
			opacity: 0.7,
		},
	},
	cancelBtn: {
		color: "#4eafb3",
		border: "1px solid #4eafb3",
		fontSize: "12px",
		height: "24px",
		padding: "0px 10px",
		fontFamily: "Hind Siliguri",
		textTransform: "none",
		marginRight: "10px",
	},
	container: {
		minWidth: "400px",
		maxWidth: "600px",
		padding: "10px 0",
	},
	textWraping: {
		whiteSpace: 'nowrap',
	},
	textAlignment: {
		textAlign: 'center',
	},
	tableSubHead: {
		borderBottom: "none",
		fontFamily: "Hind Siliguri",
		backgroundColor: "#E6E8ED",
		color: "rgba(70, 89, 106, 1)",
		fontWeight: 600,
		fontSize: "11px",
		textTransform: "capitalize",
		marginTop: '2px',
	},
	tablecell: {
		padding: '5px 12px',
	},
	displayNone: {
		opacity: 0,
	},
}));

export const rowStyle = {
	lineHeight: '24px',
	padding: '5px 5px',
	cursor: 'default',
};

export const iconStyle = {
	fontSize: '14px',
	marginLeft: '5px',
	color: '#4eafb3',
	marginTop: '3px',
};

export const popOverHeading = {
	fontWeight: 500,
	fontSize: '11px',
	textTransform: 'capitalize',
	color: '#B6B6B6',
};

export const popOverBody = {
	fontWeight: 600,
	fontSize: '13px',
	textTransform: 'capitalize',
	color: '#465A69',
};

export const popOverContent = {
	minWidth: '200px',
	minHeight: '100px',
	padding: '10px',
};

export const statusIconStyle = {
	width: '22px',
	height: '22px',
	float: 'left',
};

export const StyledRating = styled(Rating)({
	'& .MuiRating-iconFilled': {
		color: props => props.backColor,
	},
	'& .MuiRating-iconHover': {
		color: '#ff3d47',
	},
});

export const selectStyle = {
	paddingLeft: '10px',
	fontFamily: 'Hind Siliguri',
	fontStyle: 'normal',
	fontWeight: 600,
	fontSize: '12px',
	lineHeight: '19px',
	color: '#465A69',
	background: '#EDEDED',
	width: '100%',
};

export const customMenuItemStyle = {
	paddingLeft: '10px',
	fontFamily: 'Hind Siliguri',
	fontStyle: 'normal',
	fontWeight: 600,
	fontSize: '12px',
	lineHeight: '19px',
	color: '#465A69',
	background: '#fff',
};
