import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Tooltip from "@material-ui/core/Tooltip";

import { isString } from "lodash";
import CustomizedProgressBars from "./util/LinearProgress";
import { getRoundOfScore } from "../../utils";

import "./index.scss";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		"& h5": {
			fontWeight: "600",
			color: "#d11450",
		},
		"& .MuiTextField-root": {
			margin: theme.spacing(1),
			width: "25ch",
		},
		"& span": {
			fontWeight: "600",
		},
		deleteIcon1: {
			"& svg": {
				fontSize: "1 rem",
			},
		},
	},
	pageHeader: {
		fontFamily: "Hind Siliguri",
		fontStyle: "normal",
		fontSize: "14px",
		fontWeight: 600,
		lineHeight: "26px",
		color: "#465A69",
		[theme.breakpoints.down("md")]: {
			fontSize: "12px",
		},
		[theme.breakpoints.down("sm")]: {
			fontSize: "8px",
		},
		paddingLeft: "3px",
	},
	boxClass: {
		display: "flex",
		justifyContent: "space-evenly",
	},
	gridContainerClass: {
		margin: "10px",
	},
	gridItemClass: {
		textAlign: "start",
	},
	gridItemRight: {
		textAlign: "end",
	},
	resultDesc: {
		fontWeight: "600",
		textAlign: "center",
		paddingTop: "12px",
	},
	largeIcon: {
		fontSize: ".8em",
	},
	weightageText: {
		fontFamily: "Hind Siliguri",
		fontSize: "8px",
		background: "#E6E8ED",
		marginLeft: "9px",
		padding: "4px",
		borderRadius: "3px",
		fontWeight: "600",
		color: "#465A69",
	},
	scorePercent: {
		fontFamily: "Hind Siliguri",
		fontStyle: "normal",
		fontWeight: "500",
		fontSize: "10px",
		lineHeight: "10px",
		color: "#465A69",
		paddingLeft: "3px",
	},
	reviewTextStyle: {
		fontFamily: "Hind Siliguri",
		fontStyle: "normal",
		fontWeight: "600",
		fontSize: "12px",
		lineHeight: "2px",
		color: "#465A69",
		paddingTop: "3px",
	},
	checkPassTextStyle: {
		fontFamily: "Hind Siliguri",
		fontStyle: "normal",
		fontWeight: "600",
		fontSize: "10px",
		lineHeight: "2px",
		color: "#465A69",
		textAlign: "center",
	},
	statusTextStyle: {
		fontFamily: "Hind Siliguri",
		fontStyle: "normal",
		fontWeight: "525",
		fontSize: "10px",
		lineHeight: "19px",
		color: "#465A69",
	},
}));

function getProgressBarColor(percent) {
	const colorObj = {
		terrible: { color: '#FF2442', remarks: 'Terrible' },
		bad: { color: '#FF6464', remarks: 'Bad' },
		moderate: { color: '#FFbd67', remarks: 'Moderate' },
		good: { color: '#96cd39', remarks: 'Good' },
		excellent: { color: '#50cd93', remarks: 'Excellent' },
	};
	if (percent < 20) {
		return colorObj.terrible;
	}
	if (percent >= 21 && percent <= 40) {
		return colorObj.bad;
	}
	if (percent >= 41 && percent <= 70) {
		return colorObj.moderate;
	}
	if (percent >= 71 && percent <= 90) {
		return colorObj.good;
	}
	if (percent >= 91 && percent <= 100) {
		return colorObj.excellent;
	}
	return colorObj.terrible;
}
function getcateogaryLinkColors(score) {
	const scorePercent = isString(score)
		? parseInt((score || "0").split("%")[0], 10)
		: score;
	const { color } = getProgressBarColor(scorePercent);
	return color;
}

// const getCheckPassedFromRules = (ruleArray) => {
// 	let checkpassed = 0;
// 	if (ruleArray && ruleArray !== null) {
// 		ruleArray.forEach((val) => {
// 			if (val.status === "PASS") {
// 				checkpassed++;
// 			}
// 		});
// 		return `${checkpassed}/${ruleArray.length} Checks passed`;
// 	}
// 	return "In progress";
// };

function CateogaryDeatails({ categoryName, rules, score }) {
	const classes = useStyles();
	const scorePercent = score ? `${getRoundOfScore(score)}%` : "";
	const { color, remarks } = getProgressBarColor(parseInt(scorePercent, 10));
	// const checkPassed = getCheckPassedFromRules(rules);
	let numb;
	if (categoryName.toUpperCase() === "SYMPTOMATIC TREATMENT") {
		numb = "60%";
	} else {
		numb = "20%";
	}
	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Grid container style={{ marginBottom: "5px", paddingLeft: "35px" }}>
					<Grid item xs={6}>
						<Grid container spacing={1} style={{ textAlign: "center" }}>
							<Grid
								item
								xs={12}
								lg={12}
								className={classes.gridItemClass}
								style={{
									display: "flex",
									alignItems: "center",
									marginLeft: "10px",
								}}
							>
								<Typography variant="Subtitle1" className={classes.pageHeader}>
									{categoryName}
								</Typography>
								<Typography
									variant="Subtitle1"
									id="weightage-info"
									className={classes.weightageText}
								>
									{numb}
									{' '}
									Weightage
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Grid container style={{ alignItems: "center" }}>
									<Grid item xs={6}>
										<Box marginLeft="14px">
											<CustomizedProgressBars
												percentage={parseInt(scorePercent, 10)}
												color={color}
											/>
										</Box>
									</Grid>
									<Grid item xs={1}>
										<Typography className={classes.scorePercent}>
											{scorePercent}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={6} className={classes.resultDesc}>
								<Typography
									variant="h6"
									id="remarks"
									className={classes.reviewTextStyle}
								>
									{remarks}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={6} style={{ textAlign: "left" }}>
						{rules
							&& rules.map(val => (
								<Grid
									container
									style={{ paddingBottom: "3px", marginleft: "5px" }}
								>
									<Grid
										item
										xs={9}
										style={{ display: "flex", alignItems: "center" }}
									>
										<Box
											height="9px"
											width="9px"
											borderRadius="10px"
											marginRight="10px"
											style={{
												backgroundColor: getcateogaryLinkColors(val.score),
											}}
										/>
										<Typography
											id="remarks"
											className={classes.statusTextStyle}
										>
											{val.name}
										</Typography>
									</Grid>
									<Grid
										item
										xs={3}
										style={{ display: "flex", alignItems: "center" }}
									>
										<Grid item xs={3} style={{ display: "flex" }}>
											<Typography
												variant="Subtitle1"
												id="remarks"
												className={classes.statusTextStyle}
												style={{ color: getcateogaryLinkColors(val.score) }}
											>
												{(val.score >= 0) ? `${getRoundOfScore(val.score)}%` : ""}
											</Typography>
										</Grid>
										<Grid item xs={3}>
											{val.errorMessages?.length || val.errorsUrl?.length ? (
												<Box
													display="flex"
													ml="15px"
													onClick={() => {
														window.open(val.errorsUrl, "_blank");
													}}
												>
													<Tooltip
														title={(val.errorMessages?.length > 0)
															? `${[val.errorMessages || []].join()} Click to download`
															: "Click to download"}
														placement="top"
														className="reportTooltip"
													>
														<InfoOutlinedIcon className={classes.largeIcon} />
													</Tooltip>
												</Box>
											) : (
												""
											)}
										</Grid>
									</Grid>
								</Grid>
							))}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

CateogaryDeatails.propTypes = {
	categoryName: PropTypes.any.isRequired,
	rules: PropTypes.any.isRequired,
	score: PropTypes.any.isRequired,
};

export default CateogaryDeatails;
