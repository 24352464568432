/* eslint-disable indent */
import React from "react";
import {
    TableHead, TableRow, TableCell, Box,
} from "@material-ui/core";
import { useStyles } from "./styles";


const TableHeader = () => {
    const classes = useStyles();
    return (
        <TableHead>
            <TableRow>
                <TableCell className={classes.tablecell} style={{ padding: "10px" }}>
                    <Box>Dataset Name</Box>
                </TableCell>
                <TableCell className={classes.tablecell}>
                    <Box># of Columns</Box>
                </TableCell>
                <TableCell className={classes.tablecell}>
                    <Box>Platform</Box>
                </TableCell>
                <TableCell className={classes.tablecell}>
                    <Box>Last Checked On</Box>
                </TableCell>
                <TableCell className={classes.tablecell}>
                    <Box>Score</Box>
                </TableCell>
                <TableCell />
                <TableCell />
            </TableRow>
        </TableHead>
    );
};

export default TableHeader;
