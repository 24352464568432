import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import config from "../../config/config";

import logo from "../../../assets/images/rb-logo.png";

const headerMapping = {
	"/": "Data@Reckitt",
	"/ratify": "RB - RATIFY",
	"/data-catalog": "DATA CATALOG",
	"/covid-data": "COVID DATA",
	"/hygiene-ec": "Hygiene - EC",
};

function RbLogo({ match }) {
	const headerText = headerMapping[match.path] || "Data Quality Portal";
	return (
		<div className="top-left-nav">
			<Link to={config.hardCoded.homeLocation} className="rb-logo-container">
				<img className="rb-logo" src={logo} alt="RB Logo" />
			</Link>
			<span className="rb-logo-text">{headerText}</span>
		</div>
	);
}

RbLogo.propTypes = {
	match: PropTypes.object.isRequired,
};

export default RbLogo;
