// Import required libraries
import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import {
	TableBody,
	TableRow,
	Grid,
	MenuItem,
	Menu,
	Select,
	Box,
	Button,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Pagination } from "@material-ui/lab";
import Dialog from "@material-ui/core/Dialog";
import moment from "moment";

// Import data & utils
import config from "../../js/config/config";
import useTable from "../../js/utils/useTable";
import { formatDate, getRoundOfScore } from "../../utils";
import Loader from "../../js/components/Loader/Loader";
import RatifyModel from "./RatifyModal";
import ErrorAlert from "../RatifyForm/ErrorAlert";
import {
	getCircleBarColor,
	CustomMenuItem,
} from "../DiagnosticReport/ReportSideSecondStyles";
import ViewSchema from "../ViewSchema";
import DatasetDetails from "./DatasetDetails";
import TableHeader from "./TableHeader";

// Import action creators
import { getMyDataset, getStandardDataset } from "../../actions";
// Import styles
import { StyledTableCell, useStyles, customMenuItemStyle } from "./styles";
import "./index.scss";

const headCells1 = [
	{ id: "table_name", label: "Table Name", disableSorting: true },
	{ id: "dataset_name", label: "Dataset Name", disableSorting: true },
	{ id: "no_of_columns", label: "# of Columns", disableSorting: true },
	{ id: "location", label: "Platform", disableSorting: true },
	{ id: "last_run_details_1", label: " ", disableSorting: true },
];
const headCells2 = [
	{ id: "name", label: "Name", disableSorting: true },
	{ id: "last_run_details_1", label: "Last Run details", disableSorting: true },
	{ id: "last_run_details_2", label: " ", disableSorting: true },
	{ id: "last_run_details_3", label: " ", disableSorting: true },
	{ id: "last_run_details_4", label: " ", disableSorting: true },
];

const StyledRating = ({ value, color }) => {
	const classes = useStyles();
	let ratingBox = [];
	if (value > 0 && value <= 10) {
		let newValue = value;
		for (let i = 0; i < 10; i++) {
			newValue = value - i;
			if (newValue >= 1) {
				ratingBox.push(100);
			} else if (newValue > 0 && newValue < 1) {
				ratingBox.push(newValue * 100);
			} else {
				ratingBox.push(0);
			}
		}
	} else {
		ratingBox = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
	}

	return (
		<div className={classes.ratingContainer}>
			{ratingBox.map((rate, i) => (
				<div className={classes.ratingBox} key={i}>
					<div
						className={classes.rating}
						style={{ width: `${rate}%`, backgroundColor: color }}
					/>
					<div className={classes.empty} style={{ width: `${100 - rate}%` }} />
				</div>
			))}
		</div>
	);
};

StyledRating.propTypes = {
	value: PropTypes.any.isRequired,
	color: PropTypes.any.isRequired,
};

const triggerRatifyRequest = (payload) => {
	const url = config.api.triggerRatifyUrl;
	return fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(payload),
	});
};

const PopOverDataset = ({ item, viewSchema, isEditable }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [anchorEl, setAnchorEl] = useState(null);
	const classes = useStyles();
	const [openModal, setOpenModal] = useState(false);
	const [deleteStatus, setDeleteStatus] = useState("pending");
	const [alertMsg, setAlertMsg] = useState("");
	const open = Boolean(anchorEl);


	const handleClose = () => setAnchorEl(null);
	const handleClick = event => setAnchorEl(event.currentTarget);
	const handleCloseDeleteModal = () => {
		setAnchorEl(null);
		setOpenModal(false);
	};
	const onItemClick = () => {
		viewSchema(item);
		handleClose();
	};

	const { subtab } = useParams();

	let deleteType;
	let url;
	if (subtab === "mydataset") {
		deleteType = "deleteMyDataset";
		url = `${config.api.deleteMyDataset}?datasetId=${item.datasetId}`;
	} else if (subtab === "standard") {
		deleteType = "deleteStdDataset";
		url = `${config.api.deleteStdDataset}?datasetId=${item.datasetId}`;
	}
	const handleRefreshEvent = () => {
		if (deleteType === "deleteMyDataset") {
			dispatch(getMyDataset());
		} else if (deleteType === "deleteStdDataset") {
			dispatch(getStandardDataset());
		}
	};

	const handleEditDataset = () => {
		history.push(`/home/datasets/addnew/${item.datasetId}`);
	};

	const onDelete = () => {
		fetch(url, {
			method: "DELETE",
		}).then((response) => {
			response
				.json()
				.then((result) => {
					if (result.status.statusCode === "ratify-200") {
						// debugger
						// setOpenModal(false);
						// handleRefreshEvent();
						setDeleteStatus("success");
						setAlertMsg(result.status.statusMessage);
					} else {
						setDeleteStatus("error");
						setAlertMsg(result.status.statusMessage);
						// setAlert(true);
						// setOpenModal(false);

						// setAlertMessage(result.status.statusMessage);
					}
					// setWaiting(false);
					// handleClose();
				})
				.catch(() => {
					setDeleteStatus("error");
					setAlertMsg("Deletion Failed , Please try later.");
					// setOpenModal(false);
				});
		});
	};
	const openModalFn = () => {
		setOpenModal(true);
		handleClose();
	};

	return (
		<>
			<i
				className="material-icons more-vert-icon-style"
				aria-label="more"
				aria-controls="long-menu"
				aria-haspopup="true"
				onClick={handleClick}
			>
				more_vert
			</i>
			<Menu
				id="long-menu"
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={handleClose}
			>
				<MenuItem
					onClick={onItemClick}
					className={classes.fontStyle}
					style={{ fontWeight: "400" }}
				>
					{isEditable ? "Edit Schema" : "View Schema"}
				</MenuItem>
				{subtab === "mydataset" && (
					<MenuItem
						onClick={openModalFn}
						className={classes.fontStyle}
						style={{ fontWeight: "400" }}
					>
						Delete
					</MenuItem>
				)}
				{(subtab === "standard" && isEditable) && (
					<MenuItem
						onClick={openModalFn}
						className={classes.fontStyle}
						style={{ fontWeight: "400" }}
					>
						Delete
					</MenuItem>
				)}
				{(subtab === "standard" && isEditable) && (
					<MenuItem
						onClick={handleEditDataset}
						className={classes.fontStyle}
						style={{ fontWeight: "400" }}
					>
						Edit Dataset
					</MenuItem>
				)}
			</Menu>
			<RatifyModel
				dataSetDetails={item}
				handleCloseModal={handleCloseDeleteModal}
				open={openModal}
				functionType={deleteType}
				myDataset
				deleteRunConfig={onDelete}
				handleRefreshEvent={handleRefreshEvent}
				item={item}
				setDeleteStatus={setDeleteStatus}
				deleteStatus={deleteStatus}
				alertMsg={alertMsg}
			/>
		</>
	);
};

PopOverDataset.propTypes = {
	item: PropTypes.any.isRequired,
	viewSchema: PropTypes.func.isRequired,
	isEditable: PropTypes.bool.isRequired,
};

const RatifyBtn = ({ item }) => {
	const classes = useStyles();
	const [waiting, setWaiting] = useState(false);
	const [messageAlert, setAlertMessage] = useState(false);
	const [alert, setAlert] = useState(false);
	const dispatch = useDispatch();

	const handleRefreshEvent = () => {
		dispatch(getMyDataset());
	};
	const ratifyMydataset = () => {
		const data = {};
		const datasetTimeRange = {
			endDate: moment().valueOf(),
			startDate: 0,
		};
		Object.defineProperties(data, {
			datasetId: {
				value: item?.datasetId,
				enumerable: true,
			},
			runConfigName: {
				value: item?.datasetId,
				enumerable: true,
			},
			datasetFilters: {
				value: [],
				enumerable: true,
			},
			datasetTimeRange: {
				value: datasetTimeRange,
				enumerable: true,
			},
			timeRange: {
				value: "fullRange",
				enumerable: true,
			},
			forceRunRequired: {
				value: true,
				enumerable: true,
			},
			isScheduled: {
				value: "ondemand",
				enumerable: true,
			},
		});
		setWaiting(true);
		triggerRatifyRequest(data).then((response) => {
			setWaiting(false);
			response
				.json()
				.then((result) => {
					setWaiting(false);
					if (result.status.statusCode === "ratify-200") {
						setAlert(true);
						setAlertMessage(result.status.statusMessage);
					} else {
						setAlert(true);
						setAlertMessage(result.status.statusMessage);
					}
				})
				.catch(() => {
					setAlert(true);
					setAlertMessage("Server error occured");
				});
		});
	};

	const handleAlertClose = () => {
		setAlert(false);
		handleRefreshEvent();
	};

	return (
		<>
			<Button
				variant="outlined"
				onClick={ratifyMydataset}
				className={classes.rafifyBtn}
				disabled={waiting}
			>
				{waiting ? <Loader /> : "Run Now"}
			</Button>
			<ErrorAlert
				message={messageAlert}
				open={alert}
				redirect={false}
				handleClose={handleAlertClose}
			/>
		</>
	);
};

RatifyBtn.propTypes = {
	item: PropTypes.array.isRequired,
};

const DatasetTable = ({
	data = [],
	paginationFlag,
	showDatasetDetails,
	datasetDetails,
	criteria,
	userRole,
	myDatasetPage = false,
	type = "",
	isEditable = false,
}) => {
	const classes = useStyles();
	const filterFn = { fn: items => items };
	const [showDetails, setShowDetails] = useState({});
	const [clickedItem, setClickedItem] = useState(null);
	const [openViewConf, setOpenViewConf] = useState(false);
	const colSpan = myDatasetPage ? headCells2.length : headCells1.length;
	const [open, setOpen] = React.useState(false);
	const handleClickOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const history = useHistory();

	const navigateToDetails = (item) => {
		const keys = Object.keys(showDetails);
		const datasetDetailsKeys = Object.keys(datasetDetails.data);
		keys.forEach((key) => {
			if (!datasetDetailsKeys.includes(key)) {
				showDetails[key].show = false;
			}
			showDetails[key].selected = false;
		});
		if (!showDetails[item.datasetId] || !showDetails[item.datasetId].show) {
			showDatasetDetails(item);
			showDetails[item.datasetId] = {
				show: !showDetails[item.datasetId]?.show,
				selected: !showDetails[item.datasetId]?.selected,
			};
		} else {
			showDetails[item.datasetId] = {
				show: !showDetails[item.datasetId]?.show,
				selected: !showDetails[item.datasetId]?.selected,
			};
		}
		setShowDetails({ ...showDetails });
	};

	const navigateToRow = () => {
		console.log("clicked");
		history.push("/home/datasets/edap_stg.igk_ekko");
	};

	const showViewSchema = (item) => {
		console.log(`item`, item);
		setClickedItem(item);
		setOpenViewConf(true);
	};

	const onViewSchemaClose = () => {
		setClickedItem(null);
		setOpenViewConf(false);
	};

	const getHeadCells = () => {
		if (myDatasetPage) {
			return headCells2;
		}
		return headCells1;
	};

	const { TblContainer, TblHead } = useTable(
		data,
		getHeadCells(),
		filterFn,
		{},
		() => { },
		criteria,
	);

	const pageSizes = [...config.hardCoded.pageSizes];
	const isViewOnly = true;

	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);

	const handleChangePage = (ev, newPage) => {
		setPage(newPage);
	};

	const handlePageSizeChange = (event) => {
		setPageSize(event.target.value);
		setPage(1);
	};
	const showLoader = (datasetDetailsObj, showDetailsObj, item) => {
		const show = datasetDetailsObj.status === "loading"
			&& showDetailsObj[item.datasetId]?.show
			&& showDetailsObj[item.datasetId]?.selected;
		return show;
	};

	const showData = (datasetDetailsObj, showDetailsObj, item) => {
		const show = showDetailsObj[item.datasetId]?.show;
		return show;
	};

	const displayRecords = data.length > page ? [...data].splice((page - 1) * pageSize, pageSize) : data;
	const noOfPages = Math.ceil(data.length / pageSize);

	return (
		<>
			<div className="dataset-details-container">
				<TblContainer>
					{myDatasetPage ? (
						<TblHead />
					) : (
						<TableHeader myDatasetPage={myDatasetPage} />
					)}
					<TableBody>
						{displayRecords.map((item, index) => {
							const { color } = getCircleBarColor(item.lastSuccessfulRunScore);
							return (
								<React.Fragment key={index}>
									<TableRow className={classes.tableRow} hover>
										{!myDatasetPage && (
											<StyledTableCell onClick={() => navigateToRow(item)} style={{ cursor: "pointer", fontWeight: "600", textDecoration: "underline" }}>
												<div
													className="dataset-location-name"
													title={item.tableName}
												>
													{item.tableName}
												</div>
											</StyledTableCell>
										)}
										{!myDatasetPage && (
											<StyledTableCell>
												<div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
													<div
														className="dataset-location-name"
														title={item.noOfColumns}
													>
														{item.noOfColumns}
													</div>
													<span
														className="material-icons-outlined"
														style={{
															fontSize: "16px",
														}}
														onClick={() => { showViewSchema(item); }}
													>
														info
													</span>
												</div>
											</StyledTableCell>
										)}
										{!myDatasetPage && (
											<StyledTableCell>
												<div
													className="dataset-location-name"
													title={item.datasetLocation}
												>
													{item.datasetLocation}
												</div>
											</StyledTableCell>
										)}
										<StyledTableCell>
											{item.lastRunOn === 0
												? " - "
												: formatDate(item.lastRunOn, "MMMM DD, yyyy")}
										</StyledTableCell>
										<StyledTableCell>
											<Box display="flex" alignItems="center">
												<StyledRating
													value={item.lastSuccessfulRunScore / 10}
													color={color}
												/>
												<div className="dataset-score">
													{item.lastSuccessfulRunScore ? (
														<span>
															{`${getRoundOfScore(
																item.lastSuccessfulRunScore,
															)}%`}
														</span>
													) : (
														<span title="Not Rated">NR</span>
													)}
												</div>
											</Box>
										</StyledTableCell>
										{!myDatasetPage && (
											<StyledTableCell>
												<button
													type="button"
													className="report-btn"
													onClick={handleClickOpen}
												>
													Diagnostic Report
												</button>
											</StyledTableCell>
										)}
										{myDatasetPage && (
											<StyledTableCell>
												<RatifyBtn item={item} />
											</StyledTableCell>
										)}
										<StyledTableCell>
											<PopOverDataset
												item={item}
												viewSchema={showViewSchema}
												userRole={userRole}
												isEditable={isEditable}
											/>
										</StyledTableCell>
									</TableRow>

									{showLoader(datasetDetails, showDetails, item) && (
										<TableRow key={`${index}-det`}>
											<StyledTableCell colSpan={colSpan}>
												<Loader />
											</StyledTableCell>
										</TableRow>
									)}
									{!showLoader(datasetDetails, showDetails, item)
										&& showData(datasetDetails, showDetails, item) && (
										<DatasetDetails
											key={`details-${index}`}
											datasetDetails={datasetDetails?.data[item.datasetId]}
											item={item}
											showDatasetDetails={showDatasetDetails}
											userRole={userRole}
											colSpan={colSpan}
											myDatasetPage={myDatasetPage}
											navigateToDetails={navigateToDetails}
										/>
									)}
								</React.Fragment>
							);
						})}
					</TableBody>
				</TblContainer>
				<Dialog
					fullWidth
					maxWidth="lg"
					open={open}
					onClose={handleClose}
					aria-labelledby="max-width-dialog-title"
					style={{ display: "flex", justifyContent: "center" }}
				>
					<Box>
						<img src="https://storage.googleapis.com/data-reckitt-images-dev/poc-dataquality/dq_latest.png?x-goog-signature=2254a8e069a80d47e9812b05533c715463c8b9175019e95f903688480e794ec06c4c8a709d772a57faa28f6e2f3f6ed2dfd499deb55c35773c98795cb5981730ad6e9d079f6d026a9826daab1009ad06910b5890f9d80eeb0e4f6e809a1b1628926f1b3c7dfec6a391b6f074563b4c3f39f6ac7f8008617904ad537ee49455071e340e7f9bad449ad88e9c514721c7c35f0e7826320bc2fd983e5ae7416e26fd804a59e4a0424a32369a1a0cd5e66d1369da42ac4e7ef7ffb61835a3141e5a62f6ebc06e2d67659b96126c88f14d37f75b5f891166100d261e3340342340b2ffa01bc321a7db0c275229a1329019270e7164f7f8232eac4125d3d3958b079bbb&x-goog-algorithm=GOOG4-RSA-SHA256&x-goog-credential=devrb-srvacc-terraform-state%40dev-consumer-data-hub.iam.gserviceaccount.com%2F20220824%2Fus%2Fstorage%2Fgoog4_request&x-goog-date=20220824T063243Z&x-goog-expires=604800&x-goog-signedheaders=host" alt="img" style={{ height: "550px" }} />
					</Box>
				</Dialog>
			</div>

			{paginationFlag && data.length > 10 && (
				<Grid container style={{ marginTop: "15px" }}>
					<Grid item>
						<Pagination
							count={noOfPages}
							page={page}
							shape="rounded"
							onChange={handleChangePage}
							siblingCount={0}
							boundaryCount={2}
							className={classes.pagination}
						/>
					</Grid>
					<Grid item xs={1}>
						<Select
							onChange={handlePageSizeChange}
							value={pageSize}
							disableUnderline
							className={classes.paginationSelect}
							IconComponent={ExpandMoreIcon}
						>
							{pageSizes.map(size => (
								<CustomMenuItem
									key={size}
									value={size}
									style={customMenuItemStyle}
								>
									View
									{' '}
									{size}
								</CustomMenuItem>
							))}
						</Select>
					</Grid>
				</Grid>
			)}
			{clickedItem && openViewConf && (
				<ViewSchema
					datasetId={clickedItem.datasetId}
					datasetName={clickedItem.datasetName}
					clickedItem={clickedItem}
					onViewSchemaClose={onViewSchemaClose}
					isViewOnly={isViewOnly}
					isEditable={isEditable}
					type={type}
				/>
			)}
		</>
	);
};

DatasetTable.propTypes = {
	data: PropTypes.array.isRequired,
	paginationFlag: PropTypes.bool.isRequired,
	showDatasetDetails: PropTypes.any.isRequired,
	datasetDetails: PropTypes.any.isRequired,
	criteria: PropTypes.any.isRequired,
	userRole: PropTypes.any.isRequired,
	myDatasetPage: PropTypes.any,
	type: PropTypes.any.isRequired,
	isEditable: PropTypes.any.isRequired,
};
DatasetTable.defaultProps = {
	myDatasetPage: false,
};

const mapStateToProps = state => ({
	datasetDetails: state.dataset.datasetDetails || {},
	userRole: state.user.role || "",
});
export default connect(mapStateToProps)(React.memo(DatasetTable));
