import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import * as htmlToImage from "html-to-image";
import { jsPDF as JSPDF } from "jspdf";
import Select from "@material-ui/core/Select";

import FormControl from "@material-ui/core/FormControl";
import { formatDate } from "../../utils";
import CateogaryDeatails from "./CategoryTemplate";
import CircleProgress from "./util/CircleProgress";
import runProgress from '../../assets/images/ratify/run-progress.svg';
import runError from '../../assets/images/ratify/run-error.svg';
import runSuccess from '../../assets/images/ratify/run-success.svg';
import ViewSchema from "../ViewSchema";

import notifyImage from "../../assets/images/notify.svg";
// styles
import {
	useStyles,
	getCircleBarColor,
	CustomMenuItem,
} from "./ReportSideSecondStyles";

import "./index.scss";


const getErrorMsg = (status, res) => {
	if (status === "failed") {
		return res.status?.statusMessage || "Ratification for this subset failed. Please try again";
	}
	return "Run Is In Progress";
};
const statusIconMapping = {
	"in-progress": runProgress,
	pending: runProgress,
	failed: runError,
	completed: runSuccess,
};

function ReportSideSecond({
	data,
	aggregateScore,
	cateogaries,
	closeModal,
	dates,
	onDateChange,
	selectedDateIdx,
}) {
	const classes = useStyles();
	const [openViewConf, setOpenViewConf] = useState(false);
	const {
		lastRunOn, datasetName: dataSetName, runConfigName: subSection, lastRunStatus, response,
	} = data;
	const { color, remarks } = getCircleBarColor(parseInt(aggregateScore, 10));
	const printDocument = async () => {
		let fileName = "";
		if (dataSetName) {
			fileName += dataSetName;
		}
		if (fileName) {
			fileName += "_";
		}
		if (subSection) {
			fileName += subSection;
		}
		if (fileName) {
			fileName += "_";
		}
		if (lastRunOn) {
			fileName += formatDate(lastRunOn, "YYYY-MM-DD-HH-mm-ss");
		}
		fileName += ".pdf";

		const reportHeader = document
			.querySelector("#reportHeaderDetails")
			.cloneNode(true);
		reportHeader.className = "";
		reportHeader.style["padding-left"] = "35px";
		reportHeader.style["padding-top"] = "50px";
		// reportHeader.querySelector("#reportHeaderBody").style["padding-left"] = "35px";

		const reportHeaderBody1 = reportHeader.querySelector("#reportHeaderBody1");
		reportHeaderBody1.classList.remove("MuiGrid-grid-xs-12");
		reportHeaderBody1.classList.remove("MuiGrid-grid-lg-12");
		reportHeaderBody1.classList.add("MuiGrid-grid-xs-6");
		reportHeaderBody1.classList.add("MuiGrid-grid-lg-6");
		reportHeaderBody1.style.overflow = "hidden";
		reportHeaderBody1.style["align-items"] = "";

		reportHeaderBody1.querySelector("#runConfigNameElem").style["line-height"] = "23px";

		const reportHeaderBody2 = reportHeader.querySelector("#reportHeaderBody2");
		reportHeaderBody2.classList.remove("MuiGrid-grid-xs-12");
		reportHeaderBody2.classList.remove("MuiGrid-grid-lg-12");
		reportHeaderBody2.classList.add("MuiGrid-grid-xs-5");
		reportHeaderBody2.classList.add("MuiGrid-grid-lg-5");
		reportHeaderBody2.style.overflow = "hidden";

		const reportDetailsClone = document
			.querySelector("#divToPrint")
			.cloneNode(true);
		reportDetailsClone.className = "";

		const divToPrintChild = reportDetailsClone.querySelector("#divToPrintChild");
		divToPrintChild.className = "";
		divToPrintChild.style["padding-top"] = "20px";

		const runtext = reportDetailsClone.querySelector("#run-on-select").innerHTML;
		const reportRunOnSelect = reportDetailsClone.querySelector("#runOnSelect");
		reportRunOnSelect.classList = "";
		reportRunOnSelect.style.display = "none";
		const runOnValue = reportDetailsClone.querySelector("#runOnValue");
		runOnValue.innerHTML = runtext;
		runOnValue.style["margin-left"] = "10px";

		const tooltips = divToPrintChild.querySelectorAll(".reportTooltip");
		// eslint-disable-next-line
		for (const tooltip of tooltips) {
			tooltip.style.display = "none";
		}

		reportDetailsClone.insertBefore(reportHeader, divToPrintChild);

		const placeholderForPrint = document.querySelector("#placeholderForPrint");
		placeholderForPrint.style.width = "50%";
		placeholderForPrint.innerHTML = "";
		placeholderForPrint.appendChild(reportDetailsClone);

		const dataUrl = await htmlToImage.toPng(placeholderForPrint, {
			quality: 0.95,
		});
		placeholderForPrint.innerHTML = "";

		const link = document.createElement("a");
		link.download = "my-image-name.png";
		const pdfInstance = new JSPDF();
		const imgProps = pdfInstance.getImageProperties(dataUrl);
		const pdfWidth = pdfInstance.internal.pageSize.getWidth();
		const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
		pdfInstance.addImage(dataUrl, "PNG", 0, 0, pdfWidth, pdfHeight);
		pdfInstance.save(fileName);
	};

	const handleChange = (event) => {
		const { value } = event.target;
		onDateChange(value);
	};

	const handleOpenViewSchema = () => {
		setOpenViewConf(true);
	};
	const onViewSchemaClose = () => {
		setOpenViewConf(false);
	};

	return (
		<Grid
			item
			xs={7}
			style={{
				backgroundColor: "#f5f5f5",
				padding: "10px",
				paddingTop: "20px",
			}}
		>
			<Grid container spacing={1} justifyContent="space-between" style={{ height: '100%' }}>
				<Grid item xs={12}>
					<Grid item xs={12}>
						<Grid container>
							<Grid item xs={12}>
								<Grid
									container
									spacing={1}
									alignItems="center"
									justifyContent="space-between"
									className={classes.gridContainerClass}
								>
									<Grid item xs={11}>
										<Box marginLeft="40px">
											<Button
												onClick={printDocument}
												className={classes.downloadButton}
											>
												<span
													className="material-icons-outlined"
													style={{ fontSize: "16px", marginRight: "8px" }}
												>
													file_download
												</span>
												Download as PDF
											</Button>
										</Box>
									</Grid>
									<Grid item xs={1}>
										<i
											className="material-icons-outlined drawer-cancel-icon"
											onClick={closeModal}
										>
											cancel
										</i>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid container id="divToPrint">
						<Grid container id="divToPrintChild" style={{ paddingTop: "10px" }}>
							<Grid item xs={12}>
								<Grid container>
									<Grid
										item
										xs={7}
										style={{ marginTop: "20px", marginBottom: "20px" }}
									>
										<Grid
											container
											spacing={1}
											alignContent="space-between"
											style={{ alignItems: "end" }}
											className={classes.gridContainerClass}
										>
											<Grid item xs={10}>
												<Box marginLeft="15px">
													<Typography className={classes.runOnTextCSS}>
														<strong>Run on:</strong>
														<span id="runOnValue">
															<FormControl
																variant="standard"
																id="runOnSelect"
																style={{
																	verticalAlign: "baseline",
																	background: "#EDEDED",
																	marginLeft: "10px",
																	borderRadius: "5px",
																}}
															>
																<Select
																	labelId="run-on-select"
																	id="run-on-select"
																	onChange={handleChange}
																	value={selectedDateIdx}
																	disableUnderline
																	IconComponent={ExpandMoreIcon}
																	classes={{ root: classes.selectRoot }}
																	style={{
																		paddingLeft: "10px",
																		fontFamily: "Hind Siliguri",
																		fontStyle: "normal",
																		fontWeight: 600,
																		fontSize: "12px",
																		lineHeight: "19px",
																		color: "#465A69",
																		background: "#EDEDED",
																		borderRadius: "5px",
																	}}
																>
																	{dates.map((ele, val) => (
																		<CustomMenuItem
																			classes={{
																				selected: classes.selected,
																				root: classes.rootMenuItem,
																			}}
																			key={val}
																			value={val}
																			style={{
																				paddingLeft: "10px",
																				fontFamily: "Hind Siliguri",
																				fontStyle: "normal",
																				fontWeight: 600,
																				fontSize: "12px",
																				lineHeight: "19px",
																				color: "#465A69",
																				background: "#EDEDED",
																			}}
																		>
																			<Box display="flex" alignItems="center">
																				<img
																					src={
																						statusIconMapping[
																							ele.lastRunStatus?.toLowerCase() || "pending"
																						]
																					}
																					className={classes.statusIconStyle}
																					alt="statusIcon"
																				/>
																				<span>
																					{ele.display}
																				</span>
																			</Box>
																		</CustomMenuItem>
																	))}
																</Select>
															</FormControl>
														</span>
													</Typography>
												</Box>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={5}>
										{
											!(lastRunStatus === "pending" || lastRunStatus === "in-progress" || lastRunStatus === "failed") ? (
												<CircleProgress
													percentage={aggregateScore}
													color={color}
													text="Total Score"
													remark={remarks}
												/>
											) : null
										}
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12} style={{ marginTop: "20px" }}>
								{!(lastRunStatus === "pending" || lastRunStatus === "in-progress" || lastRunStatus === "failed") && cateogaries.map(val => (
									<CateogaryDeatails
										categoryName={val.categoryName || val.CategoryName}
										rules={val.rules}
										score={val.score}
									/>
								))}
								{
									(lastRunStatus === "pending" || lastRunStatus === "in-progress" || lastRunStatus === "failed") && (
										<div className={classes.heading}>
											<Box height="50%" display="flex" alignItems="flex-end">
												<img src={notifyImage} alt="NotifyImage" style={{ height: "80%" }} />
											</Box>
											<div>
												{getErrorMsg(lastRunStatus, response)}
											</div>
										</div>
									)
								}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					item
					xs={12}
					style={{
						textAlign: "end",
						marginRight: "20px",
						marginBottom: "10px",
					}}
				>
					<Box height="100%" display="flex" alignItems="end" justifyContent="end">
						<button
							type="button"
							className={classes.doneButton}
							onClick={() => handleOpenViewSchema(data.id)}
							style={{ marginRight: '10px' }}
						>
							View Schema
						</button>
						<button
							type="button"
							className={classes.doneButton}
							onClick={closeModal}
						>
							Done
						</button>
					</Box>
					{
						openViewConf && (
							<ViewSchema
								datasetId={data.id}
								datasetName={data.datasetName}
								openViewConf={openViewConf}
								setOpenViewConf={setOpenViewConf}
								onViewSchemaClose={onViewSchemaClose}
								fromReport
								type="remote-view"
								isViewOnly
							/>
						)
					}
				</Grid>
			</Grid>
		</Grid>
	);
}

ReportSideSecond.propTypes = {
	data: PropTypes.object.isRequired,
	dates: PropTypes.array.isRequired,
	onDateChange: PropTypes.func.isRequired,
	aggregateScore: PropTypes.object.isRequired,
	cateogaries: PropTypes.object.isRequired,
	closeModal: PropTypes.object.isRequired,
	selectedDateIdx: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
	datasetConfig: state.dataset.runHistoryConfig || {},
});

export default connect(mapStateToProps)(ReportSideSecond);
