import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

const StyledRadio = withStyles({
	root: {
		"&:hover": {
			backgroundColor: "transparent",
		},
		color: "#4eafb3",
		"&$checked": {
			color: "#4eafb3 !important",
		},
		"&$disabled": {
			color: "#4eafb3 !important",
			opacity: 0.5,
		},
		"& .MuiSvgIcon-root": {
			height: 15,
			weight: 15,
		},
	},
	checked: {},
	disabled: {
		color: "#4eafb3 !important",
		opacity: 0.5,
	},
})(props => <Radio color="default" {...props} />);

const useStyles = makeStyles({
	label: {
		fontFamily: 'Hind Siliguri!important',
		fontStyle: 'normal!important',
		fontWeight: '600!important',
		fontSize: "13px!important",
		textTransform: 'capitalize!important',
		color: '#465A69!important',
		lineHeight: "0 !important",
	},
});
const SubHeading = withStyles({
	root: {
		fontFamily: "Hind Siliguri",
		fontStyle: "normal",
		fontWeight: "600",
		fontSize: "16px",
		color: "#465A69",

	},
})(Typography);

export default function RatificationType({ handleChange, currentRatifyType, isEditable }) {
	const classes = useStyles();

	const ratificationChange = (e, value) => {
		handleChange(value);
	};

	return (
		<Grid
			container
			xl={6}
			lg={6}
			md={8}
			sm={12}
			xs={12}
			direction="row"
			justifyContent="flex-start"
			alignItems="center"
			rowSpacing={1}
			style={{ marginTop: "10px" }}
		>
			<Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
				<SubHeading className="sub-heading">Ratification Type:</SubHeading>
			</Grid>
			<Grid item xl={5} lg={7} md={7} sm={7}>
				<FormControl component="fieldset">
					<RadioGroup row aria-label="position" name="position" defaultValue={currentRatifyType} onChange={ratificationChange}>
						{!isEditable && (
							<FormControlLabel
								classes={{ label: classes.label }}
								value="ondemand"
								style={{ alignItems: "center" }}
								checked={currentRatifyType === "ondemand"}
								control={(
									<StyledRadio
										size="small"
										sx={{
											color: '#465A69',
											'&.Mui-checked': {
												color: '#465A69',
											},
										}}
									/>
								)}
								label="Run Now"
								labelPlacement="end"
							/>
						)}
						<FormControlLabel
							classes={{ label: classes.label }}
							value="scheduled"
							checked={currentRatifyType === "scheduled"}
							style={{ alignItems: "center" }}
							control={(
								<StyledRadio
									size="small"
									sx={{
										color: '#465A69',
										'&.Mui-checked': {
											color: '#465A69',
										},
									}}
								/>
							)}
							label="scheduled"
							labelPlacement="end"
						/>
					</RadioGroup>
				</FormControl>
			</Grid>
		</Grid>
	);
}
RatificationType.propTypes = {
	handleChange: PropTypes.any.isRequired,
	currentRatifyType: PropTypes.any.isRequired,
	isEditable: PropTypes.any.isRequired,
};
