import React from "react";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import PopOver from "../PopOver/PopOver";
import { updateAlertInfo } from '../../../actions';

function AppCard({
	name, match, popOverFlag, config, allAppsFlag, updateAlertInfo: updateAlertInfoFn,
}) {
	const history = useHistory();
	const location = `/home/${match.params.function}/${match.params.app}/${match.params.tab}/${name}/`;

	const navigateToDetails = () => {
		if (allAppsFlag) {
			window.open(config.to, "mywindow");
			return;
		}

		const { openRecordError } = config;
		if (openRecordError && openRecordError.trim()) {
			updateAlertInfoFn({ open: true, message: openRecordError, severity: "info" });
			return;
		}
		history.push(location);
	};

	return match.params.app ? (
		<div className="card-container">
			{popOverFlag && (
				<div className="popover-container">
					<PopOver match={match} name={name} config={config} />
				</div>
			)}
			<div
				className="disabled-style app"
				onClick={navigateToDetails}
			>
				<i className="material-icons-outlined app-icon">widgets</i>
				<p className="app-title" title={config.name}>
					{config.name}
				</p>
			</div>
		</div>
	) : (
		<div className="card-container">
			{allAppsFlag ? (
				<div
					className="disabled-style app"
					onClick={() => window.open(config.to, "mywindow")}
				>
					<i className="material-icons-outlined app-icon">widgets</i>
					<p className="app-title">{config.name}</p>
				</div>
			) : (
				<Link
					to={`/home/${match.params.function}/${name}/myconfigurations`}
					className="disabled-style app"
				>
					<i className="material-icons-outlined app-icon">widgets</i>
					<p className="app-title">{config.name}</p>
				</Link>
			)}
		</div>
	);
}

AppCard.propTypes = {
	name: PropTypes.string.isRequired,
	match: PropTypes.object.isRequired,
	popOverFlag: PropTypes.any.isRequired,
	config: PropTypes.any.isRequired,
	allAppsFlag: PropTypes.any.isRequired,
	updateAlertInfo: PropTypes.any.isRequired,
};

const mapStateToProps = state => ({ user: state.user });

const mapDispatchToProps = {
	updateAlertInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppCard);
