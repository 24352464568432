import React from "react";
import PropTypes from "prop-types";
import { useDispatch, connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { SubHeading } from "../../styles/js/Styles";
import download from "../../assets/images/download.svg";
import { downloadFile, getLocationDetailsData2 } from "../../js/utils/utils";
import { updateAlertInfo, getDownloadUrlStatus } from "../../actions";
import config from "../../js/config/config";
import Loader from "../../js/components/Loader/Loader";
import { useNonInitialEffect } from "../../js/Hooks/useNonInitialEffect";
import LocationDetailsMapping from "../../assets/data/LocationDetailsMapping.json";

const downloadDocument = (path, name) => {
	if (path) {
		downloadFile(path, `${name}.pdf`);
	} else {
		updateAlertInfo({
			open: true,
			message: `${config.messages.noDocuments} for ${name}.`,
			severity: "success",
		});
	}
};

const DetailsTab = (props) => {
	const {
		type = "remote",
		addUploadStatus = {},
		datasetConfig = {},
		downloadStatus = {},
		detailsData = {},
		envType = "",
		filterColumnSelected = [],
	} = props;
	const dispatch = useDispatch();

	useNonInitialEffect(() => {
		if (downloadStatus.status === "success") {
			downloadDocument(
				downloadStatus.data.downloadURL,
				datasetConfig.data.datasetDetails.locationMetaData.fileName,
			);
		}
	}, [downloadStatus]);

	const getDownloadUrl = () => {
		const data = {
			bucketName: datasetConfig.data.datasetDetails.locationMetaData.bucketName,
			fileName: datasetConfig.data.datasetDetails.locationMetaData.fileName,
			filePath: datasetConfig.data.datasetDetails.locationMetaData.filePath,
		};
		dispatch(getDownloadUrlStatus(data));
	};
	let numbering = 0;
	let arr;
	if (type === "remote-add") {
		arr = getLocationDetailsData2(detailsData);
	} else {
		arr = datasetConfig.data?.datasetDetails?.locationMetaData || [];
	}
	const filColArr = datasetConfig.data?.datasetFilterColumns;
	return (
		<div style={{ marginLeft: "20px" }}>
			{/* <p>details Page</p> */}
			{(addUploadStatus.status === "loading"
				|| datasetConfig.status === "loading") && <Loader />}
			{type === "local-add" && addUploadStatus.status === "success" && (
				<div>
					<SubHeading>File Name</SubHeading>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<p>{addUploadStatus.data.data.fileName}</p>
						<img
							style={{ marginLeft: "50px" }}
							src={download}
							title="Download file"
							alt="download"
							onClick={() => {
								downloadDocument(
									addUploadStatus.data.data.downloadURL,
									addUploadStatus.data.data.fileName,
								);
							}}
						/>
						{downloadStatus.status === "loading" && <Loader />}
					</div>
				</div>
			)}
			{type === "local-view" && datasetConfig.status === "success" && (
				<div>
					<SubHeading>File Name</SubHeading>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<p>{datasetConfig.data.datasetDetails.locationMetaData.fileName}</p>
						<img
							style={{ marginLeft: "50px" }}
							src={download}
							title="Download file"
							alt="download"
							onClick={() => {
								getDownloadUrl();
							}}
						/>
					</div>
				</div>
			)}
			{type === "remote-add" && (
				<div>
					<Grid style={{ display: "flex", alignItems: "baseline" }} item xs={6}>
						<SubHeading>
							Environment name
							{" :"}
						</SubHeading>
						<p style={{ fontSize: "12px", marginLeft: "5px" }}>
							{envType.datasetDetails.location}
						</p>
					</Grid>
					{arr.map((row) => {
						const { id = "", name = "" } = row;
						const el = (
							<Grid
								style={{ display: "flex", alignItems: "baseline" }}
								item
								xs={6}
							>
								<SubHeading>
									{name}
									{' '}
									{" :"}
								</SubHeading>
								<p style={{ fontSize: "12px", marginLeft: "5px" }}>
									{" "}
									{detailsData[id]}
								</p>
							</Grid>
						);
						return <span key={id}>{el}</span>;
					})}
					{filterColumnSelected.length > 0 && (
						<div style={{ display: "flex", flexDirection: "row" }}>
							<SubHeading>
								Filterable Columns
								{" :"}
							</SubHeading>
							<div style={{ display: "flex", flexDirection: "row" }}>
								{filterColumnSelected.map((row) => {
									numbering++;
									const el = (
										<p style={{ fontSize: "12px", marginLeft: "5px" }}>
											{" "}
											{numbering}
											{") "}
											{row}
										</p>
									);
									return <span key={row}>{el}</span>;
								})}
							</div>
						</div>
					)}
				</div>
			)}
			{type === "remote-view" && datasetConfig.status === "success" && (
				<div>
					<Grid style={{ display: "flex", alignItems: "baseline" }} item xs={6}>
						<SubHeading>
							Environment name
							{" :"}
						</SubHeading>
						<p style={{ fontSize: "12px", marginLeft: "5px" }}>
							{datasetConfig.data.datasetDetails.location}
						</p>
					</Grid>
					{arr.map((row) => {
						const el = (
							<Grid
								style={{ display: "flex", alignItems: "baseline" }}
								item
								xs={6}
							>
								<SubHeading>
									{Object.keys(row).length > 0 ? LocationDetailsMapping[Object.keys(row)[0]] : ""}
									{' '}
									{" :"}
								</SubHeading>
								<p style={{ fontSize: "12px", marginLeft: "5px" }}>
									{" "}
									{Object.values(row).length > 0 ? Object.values(row)[0] : ""}
								</p>
							</Grid>
						);
						return <span key={row}>{el}</span>;
					})}
					<div style={{ display: "flex", flexDirection: "row" }}>
						<SubHeading>
							Filterable Columns
							{" :"}
						</SubHeading>
						<div style={{ display: "flex", flexDirection: "row" }}>
							{filColArr.map((row) => {
								numbering++;
								const el = (
									<p style={{ fontSize: "12px", marginLeft: "5px" }}>
										{" "}
										{numbering}
										{") "}
										{row}
									</p>
								);
								return <span key={row}>{el}</span>;
							})}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

DetailsTab.propTypes = {
	addUploadStatus: PropTypes.any.isRequired,
	type: PropTypes.any.isRequired,
	datasetConfig: PropTypes.any.isRequired,
	downloadStatus: PropTypes.any.isRequired,
	detailsData: PropTypes.any.isRequired,
	envType: PropTypes.any.isRequired,
	filterColumnSelected: PropTypes.any.isRequired,
};

const mapStateToProps = state => ({
	addUploadStatus: state.dataset.addUploadStatus || {},
	datasetConfig: state.dataset.datasetConfig || {},
	downloadStatus: state.dataset.downloadStatus || {},
});

export default connect(mapStateToProps)(DetailsTab);
