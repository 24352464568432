// Import required libraies
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// Import Custom Component
import Loader from "../../js/components/Loader/Loader";
import noDataImage from "../../assets/images/undraw_void.svg";
import DatasetTable from "../DatasetTable";
import Filter from "../Filter";

import messages from "../../utils/messages";
import {
	getDatasetDetails,
	getStandardDataset,
} from "../../actions";
import pipelineData from "../../assets/data/PipelineData.json";

import "./index.scss";


const StandardDataset = ({ userRole }) => {
	const datasetsObj = pipelineData;
	const dispatch = useDispatch();
	const [criteria, setCriteria] = useState({ searchText: "" });
	const [filteredData, setFilteredData] = useState([]);

	const isEditable = userRole === "admin";

	const showDatasetDetails = ({ datasetId }) => {
		dispatch(getDatasetDetails({ datasetId }));
	};

	useEffect(() => {
		setFilteredData(datasetsObj.data || []);
	}, [datasetsObj.data]);

	useEffect(() => {
		dispatch(getStandardDataset({}));
	}, []);

	const changeCriteria = (param, value) => {
		criteria[param] = value;
		setCriteria({ ...criteria });
		if (param === "searchText") {
			let modifiedData = null;
			if (value && value.trim()) {
				modifiedData = datasetsObj.data.filter(obj => (obj.datasetName || "").toLowerCase().includes(value.toLowerCase()));
			} else {
				modifiedData = datasetsObj.data.slice();
			}
			setFilteredData([...modifiedData]);
		}
	};

	return (
		<>
			<div className="standard-dataset-register-container">
				<Filter criteria={criteria} changeCriteria={changeCriteria} placeholderText="Search Pipelines" />
				{userRole === "admin" && (
					<Link
						to="/home/datasets/addnew"
						style={{ textDecoration: "none" }}
					>
						<button type="button" className="register-button">
							Add New
						</button>
					</Link>
				)}
			</div>

			{datasetsObj.status === "loading" && <Loader />}
			{datasetsObj.status === "success" && !!filteredData.length && (
				<div className="my-configs-container">
					<DatasetTable
						data={filteredData}
						paginationFlag
						showDatasetDetails={showDatasetDetails}
						criteria={criteria}
						type="remote-view"
						isEditable={isEditable}
					/>
				</div>
			)}
			{datasetsObj.status === "success" && !filteredData.length && (
				<div className="no-configs">
					<img src={noDataImage} alt="noDataImage" />
					<p className="no-data">
						<b>{messages.noDatasetsFound}</b>
					</p>
				</div>
			)}
		</>
	);
};

StandardDataset.propTypes = {
	userRole: PropTypes.any.isRequired,
};

const mapStateToProps = state => ({
	// datasetDetails: state.dataset.datasetDetails || {},
	userRole: state.user.role || "",
});

export default connect(mapStateToProps)(StandardDataset);

// user = {
// 	data: {
// 		name: "Yadav, Shraddha (Contractor)",
// 		preferred_username: "Shraddha.Yadav@rb.com",
// 		role: "admin",
// 	},
// 	status: { statusCode: "ratify-200", statusMessage: "Successful" },
// };
