/** @format */

import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
	Button, makeStyles,
} from "@material-ui/core";
import PropTypes from "prop-types";
// import CloseIcon from "@material-ui/icons/Close";
// import { withStyles } from "@material-ui/core/styles";

// Import utils and data
import "./index.scss";
import { useHistory } from "react-router-dom";
import Loader from "../../js/components/Loader/Loader";
import DiagnosticReport from "../DiagnosticReport";
import ErrorAlert from "./ErrorAlert";

// const SubHeading = withStyles({
// 	root: {
// 		fontFamily: "Hind Siliguri",
// 		fontStyle: "normal",
// 		fontWeight: "600",
// 		fontSize: "13px",
// 		lineHeight: "26px",
// 		color: "#465A69",
// 	},
// })(Typography);

export const triggerRatifyRequest = (payload) => {
	const url = "/api/v1/trigger";
	return fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(payload),
	});
};
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	boxShadow: 24,
	borderRadius: "4px",
};
const useStyles = makeStyles(() => ({
	reportBtn: {
		color: "#fff",
		border: "1px solid #4eafb3",
		background: "#4eafb3",
		fontSize: "12px",
		height: "24px",
		padding: "0px 10px",
		fontFamily: "Hind Siliguri",
		textTransform: "none",
		"&:hover": {
			background: "#4eafb3",
			color: "#ffffff",
		},
	},
}));

function TriggerModel({
	open,
	handleClose,
	dataSetDetails,
	userDefaultName,
	role,
	datasetName,
}) {
	const classes = useStyles();
	const [waiting, setWaiting] = React.useState(false);
	const [messageAlert, setAlertMessage] = React.useState(false);
	const [alert, setAlert] = React.useState(false);
	const handleAlertClose = () => {
		setAlert(false);
	};
	const history = useHistory();

	const handleRoute = () => {
		history.push("/home");
	};
	const triggerRatify = () => {
		const data = {};
		Object.defineProperties(data, {
			datasetId: {
				value: dataSetDetails?.data?.datasetId,
				enumerable: true,
			},
			runConfigName: {
				value:
					role === "admin"
						? dataSetDetails?.data?.runConfigName
						: userDefaultName,
				enumerable: true,
			},
			datasetFilters: {
				value: dataSetDetails?.data?.datasetFilters,
				enumerable: true,
			},
			datasetTimeRange: {
				value: dataSetDetails?.data?.datasetTimeRange,
				enumerable: true,
			},
			timeRange: {
				value: dataSetDetails?.data?.timeRange,
				enumerable: true,
			},
			forceRunRequired: {
				value: true,
				enumerable: true,
			},
			isScheduled: {
				value: "ondemand",
				enumerable: true,
			},
		});
		setWaiting(true);
		triggerRatifyRequest(data).then((response) => {
			setWaiting(false);
			response
				.json()
				.then((result) => {
					setWaiting(false);
					if (result.status.statusCode === "ratify-200") {
						handleClose(false);
						handleRoute();
					} else {
						setAlertMessage(result.status.statusMessage);
						setAlert(true);
					}
				})
				.catch(() => {
					setAlertMessage("Server error occured");
					setAlert(true);
				});
		});
	};
	return (
		<>
			<ErrorAlert
				message={messageAlert}
				open={alert}
				redirect={false}
				handleClose={handleAlertClose}
			/>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Box display="flex" flexDirection="column" height="100%" p="9px 15px">
						<Box display="flex" alignItems="center" justifyContent="space-between">
							<span>
								Subset with same parameters already exists?
							</span>
							<span>
								<i
									className="material-icons-outlined drawer-cancel-icon iconSize"
									onClick={() => handleClose(false)}
								>
									cancel
								</i>
							</span>
						</Box>

						<Box
							display="flex"
							alignItems="center"
							justifyContent="flex-end"
							mt={3}
						>
							<Button
								disabled={waiting}
								style={{ marginRight: "20px" }}
								onClick={triggerRatify}
								className={classes.reportBtn}
							>
								Ratify Anyways
							</Button>
							{waiting && <Loader />}
							<DiagnosticReport
								dataobj={{
									runConfigId: dataSetDetails?.runConfigId,
									datasetId: dataSetDetails?.datasetId,
									buttonName: "View Report",
									datasetName,
									datasetFilters: dataSetDetails?.data?.datasetFilters,
								}}
								fromTrigger
							/>
						</Box>
					</Box>
				</Box>
			</Modal>
		</>
	);
}

TriggerModel.propTypes = {
	open: PropTypes.any.isRequired,
	handleClose: PropTypes.any.isRequired,
	dataSetDetails: PropTypes.any.isRequired,
	userDefaultName: PropTypes.any.isRequired,
	role: PropTypes.any.isRequired,
	datasetName: PropTypes.any.isRequired,
};
export default TriggerModel;
