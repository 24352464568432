import React, { useState } from "react";
import PropTypes from "prop-types";
import Popover from "@material-ui/core/Popover";

import config from "../../config/config";

import "./UserInfo.scss";

function UserInfo({ user }) {
	const [anchorEl, setAnchorEl] = useState(null);
	const handleClick = event => setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);
	const open = Boolean(anchorEl);

	const logout = () => {
		window.location.href = config.api.signOutUrl;
	};

	return (
		<div className="user-info-container">
			<i
				className="material-icons-outlined header-menu-icon"
				aria-owns={open ? "mouse-over-popover" : undefined}
				aria-haspopup="true"
				onClick={handleClick}
			>
				{" "}
				person
				{" "}
			</i>
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				PaperProps={{
					style: { width: "15%" },
				}}
			>
				<div className="user-info-name">
					<p className="user-info-text">{user.name}</p>
					{/* <p className="user-info-sub-text">Shraddha.Yadav@rb.com</p> */}
				</div>
				<div className="user-info-sign-out">
					<p
						className="user-info-sign-out-text"
						onClick={logout}
					>
						Sign Out
					</p>
				</div>
			</Popover>
		</div>
	);
}

UserInfo.propTypes = {
	user: PropTypes.object.isRequired,
};

export default UserInfo;
