import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { getRunHistoryDetails } from "../../actions";
import Loader from "../../js/components/Loader/Loader";
import ReportSideFirst from './ReportSideFirst';
import ReportSideSecond from './ReportSideSecond';

// Import utils, data
import { formatDate } from '../../utils';
import config from "../../js/config/config";
import noDataImage from "../../assets/images/undraw_void.svg";

import './index.scss';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		'& h5': {
			fontWeight: '600',
			color: "#d11450",
		},
		'& .MuiTextField-root': {
			margin: theme.spacing(1),
			width: '150px',
		},
		'& .MuiInputBase-root': {
			fontSize: '0.7em',

		},
	},
}));

const detailsData = (data) => {
	const cateogaryDetailsObj = {
		aggregateScore: 0,
		cateogaryDetails: [],
	};
	if (data.response) {
		const responseObj = data.response;
		if (responseObj.aggregateScore) {
			cateogaryDetailsObj.aggregateScore = parseInt(responseObj.aggregateScore, 10);
		}
		Object.keys(responseObj).forEach((key) => {
			if (key !== "aggregateScore" && key !== "outputLocation") {
				cateogaryDetailsObj.cateogaryDetails.push(responseObj[key]);
			}
		});
	}
	return cateogaryDetailsObj;
};

const datesWithKey = (data) => {
	const dates = [];
	data.forEach((element) => {
		const dataSetId = element.id;
		const formattedDate = formatDate(element.lastRunOn, 'MMMM DD, yyyy');
		dates.push({
			display: formattedDate,
			val: dataSetId,
			lastRunStatus: element.lastRunStatus,
		});
	});
	return dates;
};

const DataSetTemplate = ({
	data, closeModal, runHistoryObj, fromTrigger,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [selectedDataSet, setSelectedDataSet] = useState({});
	const [dates, setDatesList] = useState([]);
	const [latestSucessRun, setLatestSucessRun] = useState({});
	const [cateogaryDetailsObj, setcateogaryDetailsObj] = useState({
		aggregateScore: 0,
		cateogaryDetails: [],
		selectedDate: 0,
	});

	const setCateogaryDetailsObjFunc = (obj, idx) => {
		const selectedDate = idx !== undefined && idx !== "" ? idx : 0;
		const { aggregateScore, cateogaryDetails } = detailsData(obj);
		setcateogaryDetailsObj({
			aggregateScore,
			cateogaryDetails,
			selectedDate,
		});
	};

	const onDateChange = (id) => {
		const objId = id;
		const filteredDataSet = runHistoryObj.data.filter((_obj, idx) => idx === objId);
		setCateogaryDetailsObjFunc(filteredDataSet[0], objId);
		setSelectedDataSet(filteredDataSet[0]);
	};
	const getLatestRun = () => {
		const lastestRun = runHistoryObj.data.find(item => item.lastRunStatus === "completed");
		if (lastestRun && Object.keys(lastestRun).length > 0) {
			setLatestSucessRun(lastestRun);
		} else {
			setLatestSucessRun(runHistoryObj.data[0]);
		}
	};

	useEffect(() => {
		dispatch(getRunHistoryDetails({ runConfigId: data.runConfigId, datasetId: data.datasetId }));
	}, []);

	useEffect(() => {
		if (runHistoryObj.data.length > 0) {
			setCateogaryDetailsObjFunc(runHistoryObj.data[0]);
			setSelectedDataSet(runHistoryObj.data[0]);
			setDatesList(datesWithKey(runHistoryObj.data));
			getLatestRun();
		}
	}, [runHistoryObj]);

	return (
		<>
			{runHistoryObj.status === "loading" && <Loader />}
			{runHistoryObj.status === "success" && runHistoryObj.data.length > 0 && Object.keys(selectedDataSet).length > 0 && (
				<div className={classes.root}>
					<Grid container alignContent="space-between">
						<ReportSideFirst
							details={data}
							aggregateScore={data.response?.aggregateScore}
							lastRunOn={data.lastRunOn}
							runHistoryObj={runHistoryObj}
							onDateChange={onDateChange}
							latestSucessRun={latestSucessRun}
							fromTrigger={fromTrigger}
						/>
						<ReportSideSecond
							data={selectedDataSet}
							dates={dates}
							selectedDateIdx={cateogaryDetailsObj.selectedDate}
							onDateChange={onDateChange}
							aggregateScore={cateogaryDetailsObj.aggregateScore}
							cateogaries={cateogaryDetailsObj.cateogaryDetails}
							closeModal={closeModal}
						/>
					</Grid>
				</div>
			)}
			{runHistoryObj.status === "success" && runHistoryObj.data.length === 0 && (
				<div className="no-configs">
					<img src={noDataImage} alt="noDataImage" />
					<p className="no-data">
						<b>{config.messages.noRecordsText}</b>
					</p>
				</div>
			)}
		</>
	);
};

DataSetTemplate.propTypes = {
	data: PropTypes.object.isRequired,
	closeModal: PropTypes.any.isRequired,
	runHistoryObj: PropTypes.any.isRequired,
	fromTrigger: PropTypes.any.isRequired,
};

const mapStateToProps = state => ({
	runHistoryObj: state.dataset.runHistoryList || [],
});

export default connect(mapStateToProps)(DataSetTemplate);
