/** @format */

import React from "react";
import PropTypes from "prop-types";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
	root: {
		marginTop: "5px",
		fontFamily: "Hind Siliguri !important",
		width: "100%",
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center",
	},
	chipStyling: {
		marginTop: "0px",
		fontFamily: "Hind Siliguri",
		fontSize: "12px",
		border: "1px solid #dedede",
		height: "28px",
		borderRadius: "4px",
		width: "fit-content",
		margin: "0px 5px",
		background: "#465A69",
		fontStyle: "normal",
		fontWeight: "500",
		color: "#FFFFFF",
		"&:focus": {
			backgroundColor: "#465A69",
		},
		"& .MuiChip-deleteIcon": {
			fontSize: 20,
			border: "none",
			color: "white",
		},
	},
	chipContainer: {
		"& .MuiChip-root.Mui-disabled": {
			opacity: 1,
		},
	},
}));

export default function SelectedFilterChip({
	chips, handleDelete, isEditable, defaultChips,
}) {
	const classes = useStyles();
	const isDisabled = chip => (Boolean(isEditable) || Boolean(defaultChips?.includes(chip)));
	return (
		<div style={{ display: "flex", flexWrap: "wrap", width: "calc(100% - 250px)" }} className={classes.container}>
			{(chips && Array.isArray(chips)) && chips.map((chip, index) => (
				<Chip
					key={index}
					label={chip}
					onDelete={() => handleDelete(chip)}
					deleteIcon={<i className="material-icons-outlined">clear</i>}
					className={classes.chipStyling}
					disabled={isDisabled(chip)}
				/>
			))}
		</div>
	);
}

SelectedFilterChip.propTypes = {
	chips: PropTypes.array.isRequired,
	handleDelete: PropTypes.any.isRequired,
	isEditable: PropTypes.any.isRequired,
	defaultChips: PropTypes.any.isRequired,
};
