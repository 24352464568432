import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import config from "../../config/config";

import "./Footer.scss";

function Footer(props) {
	const { user } = props;
	const currentYear = new Date().getFullYear();
	return (
	  <footer className={user.theme}>
			<p className="footer-text">{`Copyright @${currentYear}. ${config.footerText}`}</p>
	  </footer>
	);
}

Footer.propTypes = {
	user: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({ user: state.user });

export default connect(mapStateToProps)(Footer);
