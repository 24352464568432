import React, { useEffect, useState } from "react";
import {
	Table,
	TableHead,
	TableRow,
	TableCell,
	makeStyles,
	TablePagination,
	TableSortLabel,
} from "@material-ui/core";
import config from "../config/config";

const useStyles = makeStyles(() => ({
	table: {
		"& thead th": {
			borderBottom: "none",
			fontFamily: "Hind Siliguri",
			backgroundColor: "#E6E8ED",
			color: "rgba(70, 89, 106, 1)",
			fontWeight: 600,
			fontSize: "13px",
			// lineHeight: "24px",
			textTransform: "capitalize",
			// padding: "12px",
		},
		"& tbody": {
			borderRadius: "4px",
			padding: "10px",
		},
		"& tbody td": {
			fontWeight: "300",
			fontSize: "12px",
			padding: "10px",
		},
		"& tbody tr:hover": {
			cursor: "default",
		},
		"& thead": {
			borderBottom: "none",
		},
		"& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
			color: "#46596a",
		},
		"& .MuiTypography-colorInherit": {
			color: "#46596a !important",
		},
		"& .MuiTypography-body2": {
			fontFamily: "Hind Siliguri !important",
		},
		"&:nth-of-type(odd)": {
			backgroundColor: "white",
		},
		"&:nth-of-type(even)": {
			backgroundColor: "rgba(70, 89, 106, 0.07);",
		},
	},
}));

function useTable(records, headCells, filterFn, user, updateMultiSelections, criteriaChange) {
	const classes = useStyles();
	const pages = [...config.hardCoded.pageSizes];
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(pages[page]);

	// eslint-disable-next-line
	const TblContainer = ({ children }) => (<Table stickyHeader className={classes.table}>{children}</Table>);

	useEffect(() => {
		setPage(0);
	}, [criteriaChange]);

	const TblHead = () => {
		const handleSortRequest = (cellId) => {
			const isAsc = user?.selections?.tableOrderBy === cellId
				&& user?.selections?.tableOrder === "asc";
			updateMultiSelections({
				tableOrderBy: cellId,
				tableOrder: isAsc ? "desc" : "asc",
			});
		};

		return (
			<TableHead>
				<TableRow>
					{headCells.map(headCell => (
						<TableCell
							key={headCell.id}
							sortDirection={
								user?.selections?.tableOrderBy === headCell.id
									? user?.selections?.tableOrder
									: false
							}
						>
							{headCell.disableSorting ? (
								headCell.label
							) : (
								<TableSortLabel
									active={user?.selections?.tableOrderBy === headCell.id}
									direction={
										user?.selections?.tableOrderBy === headCell.id
											? user?.selections?.tableOrder
											: "asc"
									}
									onClick={() => {
										handleSortRequest(headCell.id);
									}}
								>
									{headCell.label}
								</TableSortLabel>
							)}
						</TableCell>
					))}
				</TableRow>
			</TableHead>
		);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const TblPagination = () => (
		<TablePagination
			component="div"
			page={page}
			rowsPerPageOptions={pages}
			rowsPerPage={rowsPerPage}
			count={records.length}
			onChangePage={handleChangePage}
			onChangeRowsPerPage={handleChangeRowsPerPage}
			className={classes.table}
		/>
	);

	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) return order;
			return a[1] - b[1];
		});
		return stabilizedThis.map(el => el[0]);
	}

	function descendingComparator(a, b, orderBy) {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	}

	function getComparator(order, orderBy) {
		return order === "desc"
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}

	const recordsAfterPaging = () => filterFn.fn(records).slice(page * rowsPerPage, (page + 1) * rowsPerPage);

	const recordsAfterPagingAndSorting = () => stableSort(
		filterFn.fn(records),
		getComparator(user?.selections?.tableOrder, user?.selections?.tableOrderBy),
	).slice(page * rowsPerPage, (page + 1) * rowsPerPage);

	return {
		TblContainer,
		TblHead,
		TblPagination,
		recordsAfterPagingAndSorting,
		recordsAfterPaging,
	};
}

export default useTable;
