import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { Typography, Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import PlotlyChart from "./util/PlotlyChart";
// import diagnosticChart from '../../assets/images/diagnostic_left_charts.svg';
import { formatDate, getRoundOfScore } from '../../utils';

import { getSchedulingDetails, useStyles } from "./ReportSideFirstStyles";
import './index.scss';


const DateRangeBtns = [
	{
		labels: 'Full range',
		value: 'fullRange',
	},
	{
		labels: 'Last 2year',
		value: 'last2Year',
	},
	{
		labels: 'Last 1year',
		value: 'last1Year',
	},
	{
		labels: 'Last 6months',
		value: 'last6Month',
	},
	{
		labels: 'Last 30days',
		value: 'last30days',
	},
	{
		labels: 'Last 7days',
		value: 'last7days',
	},
];
function ReportSideFirst({
	aggregateScore, lastRunOn, runHistoryObj, onDateChange, latestSucessRun,
}) {
	const classes = useStyles();
	// const selection = getSelectionDetails(details);
	const schedulingDetails = getSchedulingDetails(latestSucessRun);
	const [allDates, setAllDates] = useState([]);
	const [dateRange, setDateRange] = useState([]);
	const [active, setActive] = useState("fullRange");
	const [graphData, setGraphData] = useState({});
	const [totalRunsInfo, setTotalRunsInfo] = useState({
		avg: 0, totalRun: 0, successfullRun: 0,
	});

	const getData = (value) => {
		const labels = [];
		const scores = [];
		const colors = [];
		const lastRunOnDates = [];
		const currentDate = new Date();
		runHistoryObj.data.forEach((item) => {
			if (item.lastRunOn && item.response?.aggregateScore && item.lastRunOn >= value) {
				const date = moment(item.lastRunOn).format('YYYY-MM-DD hh:mm:ss');
				colors.push('#4EAFB3');
				lastRunOnDates.push(item.lastRunOn);
				labels.push(date);
				scores.push(item.response.aggregateScore);
			}
		});
		// const firstdayOfRange = moment(Math.min(...lastRunOnDates)).format("YYYY-MM-01");
		// const LastdayOfRange = moment(currentDate).format("YYYY-MM-") + moment(currentDate).daysInMonth();
		// const firstDate = new Date(Math.min(...lastRunOnDates) || currentDate);
		const firstDate = new Date(lastRunOnDates.length > 0 ? Math.min(...lastRunOnDates) : currentDate);
		const firstdayOfRange = firstDate.setDate(firstDate.getDate() - 3);
		const LastdayOfRange = currentDate.setDate(currentDate.getDate() + 2);
		setGraphData({
			labels, scores, colors,
		});
		setDateRange([firstdayOfRange, LastdayOfRange]);
	};

	const getFullRangeData = () => {
		const labels = [];
		const scores = [];
		const colors = [];
		const lastRunOnDates = [];
		const currentDate = new Date();
		if (runHistoryObj.data) {
			runHistoryObj.data.forEach((item) => {
				if (item.lastRunOn && item.response && item.response.aggregateScore) {
					const date = moment(item.lastRunOn).format('YYYY-MM-DD hh:mm:ss');
					colors.push('#4EAFB3');
					labels.push(date);
					lastRunOnDates.push(item.lastRunOn);
					scores.push(item.response.aggregateScore);
				}
			});
		}
		setGraphData({
			labels, scores, colors,
		});
		// const firstdayOfRange = moment(Math.min(...lastRunOnDates)).format("YYYY-MM-01");
		// const LastdayOfRange = moment(currentDate).format("YYYY-MM-") + moment(currentDate).daysInMonth();
		const firstDate = new Date(lastRunOnDates.length > 0 ? Math.min(...lastRunOnDates) : currentDate);
		const firstdayOfRange = firstDate.setDate(firstDate.getDate() - 3);
		const LastdayOfRange = currentDate.setDate(currentDate.getDate() + 3);
		setGraphData({
			labels, scores, colors,
		});
		setDateRange([firstdayOfRange, LastdayOfRange]);
	};

	const handleRange = useCallback((time, value) => {
		const labelsMin = Math.min(...value);
		const date = new Date();
		let RangeMin = labelsMin;
		if (time === "last7days") {
			RangeMin = date.setDate(date.getDate() - 7);
			getData(RangeMin);
		} else if (time === "last30days") {
			RangeMin = date.setMonth(date.getMonth() - 1);
			getData(RangeMin);
		} else if (time === "last6Month") {
			RangeMin = date.setMonth(date.getMonth() - 6);
			getData(RangeMin);
		} else if (time === "last1Year") {
			RangeMin = date.setMonth(date.getMonth() - 12);
			getData(RangeMin);
		} else if (time === "last2Year") {
			RangeMin = date.setMonth(date.getMonth() - 24);
			getData(RangeMin);
		} else if (time === "fullRange") {
			getFullRangeData(RangeMin);
		}
		setActive(time);
	}, []);

	useEffect(() => {
		const dates = [];
		let successfullRun = 0;
		let totalScore = 0;
		let avg = 0;
		if (runHistoryObj.data) {
			runHistoryObj.data.forEach((item) => {
				if (item.lastRunOn && item.response && item.response.aggregateScore) {
					dates.push(item.lastRunOn);
				}
				if (item.lastRunStatus === "completed" && item.response?.aggregateScore) {
					successfullRun++;
					totalScore += item.response?.aggregateScore;
				}
			});
		}
		if (successfullRun === 0) {
			avg = "N.A";
		} else {
			avg = parseInt(totalScore / successfullRun, 10);
		}
		setTotalRunsInfo({ avg, successfullRun, totalRun: runHistoryObj.data?.length || 0 });
		handleRange("last30days", dates);
		setAllDates(dates);
	}, []);


	return (
		<Grid item xs={5} style={{ paddingLeft: "8px" }}>
			<Box paddingTop="30px" padding="10px">
				<Grid container spacing={3} alignContent="space-between" className={classes.gridContainerClass} id="reportHeaderDetails">
					<Grid item xs={12}>
						<div
							variant="Subtitle1"
							className={classes.AmazonHeader}
						>
							{latestSucessRun.datasetName}
						</div>
					</Grid>
					<Grid item xs={12} id="reportHeaderBody">
						<Grid container>
							<Grid
								item
								xs={12}
								lg={12}
								id="reportHeaderBody1"
								style={{
									display: 'flex', alignItems: 'center', marginLeft: '10px', paddingBottom: '10px',
								}}
							>
								<Typography
									variant="Subtitle1"
									className={classes.subsection}
								>
									Subset Name:
								</Typography>
								<Typography
									variant="subtitle2"
									className={classes.subsectionVal}
									id="runConfigNameElem"
								>
									{latestSucessRun?.runConfigName}
								</Typography>
							</Grid>
							<Grid
								item
								xs={12}
								lg={12}
								id="reportHeaderBody2"
								style={{ display: 'flex', alignItems: 'baseline', marginLeft: '10px' }}
							>
								{
									(latestSucessRun.datasetFilters && latestSucessRun.datasetFilters.length > 0) && (
										<>
											<Typography
												variant="subtitle2"
												className={classes.subsection}
											>
												Filters:
											</Typography>
											<Box display="block">
												{
													latestSucessRun.datasetFilters.map(item => (
														<Typography
															variant="subtitle2"
															className={classes.subsectionVal}
														>
															<span style={{ textTransform: 'capitalize' }}>{item.name}</span>
															{':  '}
															{item.value.join()}
														</Typography>
													))
												}
											</Box>
										</>
									)
								}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>
			<Box
				paddingRight="15px"
				paddingLeft="10px"
				marginTop="20px"
			>
				<Grid container spacing={1}>
					<Grid item xs={4}>
						<Paper elevation={0} className={classes.cardDesign}>
							<Grid item xs={12} style={{ paddingTop: '24px' }}>
								<Typography
									variant="subtitle2"
									className={classes.cardHeader}
								>
									{
										latestSucessRun?.lastRunStatus !== "completed" ? "-" : `${getRoundOfScore(aggregateScore)}%`
									}
								</Typography>
							</Grid>
							<Grid item xs={12} style={{ paddingTop: '7px' }}>
								<Typography
									variant="subtitle2"
									className={classes.cardContent}
								>
									Latest Score
								</Typography>
							</Grid>
						</Paper>
					</Grid>
					<Grid item xs={4}>
						<Paper elevation={0} className={classes.cardDesign}>
							<Grid item xs={12} style={{ paddingTop: '24px' }}>
								<Typography
									variant="subtitle2"
									className={classes.cardHeader}

								>
									{
										latestSucessRun?.lastRunStatus !== "completed" ? "-"
											: formatDate(lastRunOn, 'MMMM DD, yyyy')
									}
								</Typography>
							</Grid>
							<Grid item xs={12} style={{ paddingTop: '7px' }}>
								<Typography
									variant="subtitle2"
									className={classes.cardContent}
								>
									Last Scored On
								</Typography>
							</Grid>
						</Paper>
					</Grid>
					<Grid item xs={4}>
						<Paper elevation={0} className={classes.cardDesign}>
							<Grid item xs={12} style={{ paddingTop: '24px' }}>
								<Typography
									variant="subtitle2"
									className={classes.cardHeader}
								>
									{latestSucessRun?.lastRunStatus !== "completed" ? "-" : schedulingDetails || "On Demand Run"}
								</Typography>
							</Grid>
							{
								(schedulingDetails || latestSucessRun?.lastRunStatus !== "completed") && (
									<Grid item xs={12} style={{ paddingTop: '7px' }}>
										<Typography
											variant="subtitle2"
											className={classes.cardContent}
										>
											Scoring Scheduled On
										</Typography>
									</Grid>
								)
							}
						</Paper>
					</Grid>
				</Grid>
			</Box>
			<div>
				<Grid container style={{ marginTop: '30px' }}>
					<Grid item xs={12} style={{ marginLeft: '10px', marginRight: '20px' }}>
						<Box display="flex" alignItems="center" justifyContent="flex-end" boxShadow="none" border="none" width="100%" mb="12px">
							{
								DateRangeBtns.map(item => (
									<Button
										className={active === item.value ? classes.activeBtn : classes.button}
										onClick={() => handleRange(item.value, allDates)}
									>
										{item.labels}
									</Button>
								))
							}
						</Box>
						<Paper
							elevation={0}
							style={{
								background: '#f6f6f6',
								display: 'flex',
								justifyContent: 'center',
								height: '222px',
								width: '481px',
								textAlign: 'center',
								padding: '10px',
							}}
						>
							<Box display="flex" height="100%" width="100%" alignItems="center" justifyContent="center" position="relative" pl="25px">
								<Box className={classes.chartTitle}>Ratification Score</Box>
								<PlotlyChart graphData={graphData} onDateChange={onDateChange} runHistoryObj={runHistoryObj} range={dateRange} />
							</Box>
						</Paper>
						<Box display="flex" width="100%" justifyContent="space-between" alignItems="center" mb="10px">
							<Typography
								className={classes.avgScore}
							>
								Average Score:
								<span style={{ marginLeft: '5px' }}>
									{totalRunsInfo.avg === "N.A" ? totalRunsInfo.avg : `${totalRunsInfo.avg}%`}
								</span>
							</Typography>
							<Typography
								className={classes.avgScore}
							>
								Showing Runs:
								<span style={{ marginLeft: '5px' }}>
									{`${totalRunsInfo.successfullRun}/${totalRunsInfo.totalRun}`}
								</span>
							</Typography>
						</Box>
					</Grid>
				</Grid>
			</div>
		</Grid>
	);
}

ReportSideFirst.propTypes = {
	aggregateScore: PropTypes.any.isRequired,
	lastRunOn: PropTypes.any.isRequired,
	runHistoryObj: PropTypes.any.isRequired,
	onDateChange: PropTypes.any.isRequired,
	latestSucessRun: PropTypes.any.isRequired,
};

export default ReportSideFirst;
