import moment from "moment";
import orderBy from "lodash/orderBy";
import isEqual from "lodash/isEqual";
import breadcrumbsMapping from "../../assets/data/breadcrumbsMapping.json";
import locationDetailsData from "../../assets/data/LocationDetailsMapping.json";
import DatasetLocationMapping from "../../assets/data/DatasetLocationMapping.json";
import TableColumnMapping from "../../assets/data/TableColumnMapping.json";

export const splitUrlToBreadcrumbData = (url) => {
	const routes = url.replace(/^\//g, "").replace(/\/$/g, "").split("/");
	const data = routes.map((name, index) => ({
		name,
		label: breadcrumbsMapping[name] ? breadcrumbsMapping[name] : name,
		path: routes
			.slice(0, index + 1)
			.reduce((finalPath, route) => `${finalPath + route}/`, "/"),
	}));
	return data;
};

export const flattenObject = (obj) => {
	const flattened = {};
	Object.keys(obj).forEach((key) => {
		if (typeof obj[key] === "object" && key === "metadata") {
			Object.assign(flattened, flattenObject(obj[key]));
		} else {
			flattened[key] = obj[key];
		}
	});
	return flattened;
};

export const makeDefaultResponseJson = () => ({ data: [], error: "" });

// Feb-12-2021 important
export const formatDate = (date, format = "MMM-DD-YYYY H:mm:ss") => moment(date).format(format);

// hh:mm:ss 17:39:07
export const formatTime = date => moment(date).format("H:mm:ss");

// Calculate Duration false-->00:06 true--> 0 hr 6 mins
export const timeDiffCalc = (start_time, end_time) => {
	const diffInDate = moment.duration(moment(start_time).diff(moment(end_time)));
	return `${diffInDate._data.hours}hr ${diffInDate._data.minutes}min`;
};

// MROI-Australia-Vanish-Test-20-Jan-21-2021-13:08:04
export const makeRunNameFromRun = run => `${run.metadata.config_name}-${formatDate(run.created_on)}`;

export const makeRunNameFromRunNew = run => `${run.config_name}-${formatDate(run.created_on)}`;

export const generateMessageNotification = (obj) => {
	const {
		config_name, completed_at, triggered_at, notification_type,
	} = obj;
	let message = "";

	switch (notification_type) {
	case "run-success":
		message = `${config_name} run successfully completed at ${formatTime(
			completed_at,
		)}`;
		break;
	case "run-failure":
		message = `${config_name} run failed at ${formatTime(completed_at)}`;
		break;
	case "run-triggered":
		message = `${config_name} run triggered at ${formatTime(triggered_at)}`;
		break;
	default:
		message = "No notifications";
	}

	return message;
};

export const getSortedData = (data, orderField = "name", order = "asc") => orderBy(data, [orderField], [order]);

// download file from given path
export const downloadFile = (path, filename) => {
	const anchor = document.createElement("a");
	anchor.href = path;
	anchor.download = filename;
	anchor.target = "_blank";
	document.body.appendChild(anchor);
	anchor.click();
	document.body.removeChild(anchor);
};

export const getOrdinal = (type, date, day) => {
	const ord = ["st", "nd", "rd"];
	const exceptions = [11, 12, 13];
	let message = "";

	switch (type) {
	case "Monthly":
		message = `${type}, ${date}${
			ord[(date % 10) - 1] === undefined || exceptions.includes(date % 100)
				? "th"
				: ord[(date % 10) - 1]
		}`;
		break;
	case "Weekly":
		message = `${type}, ${day}`;
		break;
	case "Daily":
		message = `${type}`;
		break;
	default:
		message = " - ";
	}
	return message;
};

// For getting location details
export const getLocationDetailsData = (inputData) => {
	// const locationData = Object.keys(inputData);
	// const arrayData = locationData.map(element => ({
	// 	id: element,
	// 	name: locationDetailsData[element] || element,
	// }));
	const arrayData = inputData.map(element => ({
		id: element,
		name: locationDetailsData[element] || element,
	}));
	return arrayData;
};
export const getLocationDetailsData2 = (inputData) => {
	const locationData = Object.keys(inputData);
	const arrayData = locationData.map(element => ({
		id: element,
		name: locationDetailsData[element] || element,
	}));

	return arrayData;
};

// For getting Location Type details
export const getLocationTypeDetailsData = (locationDataType) => {
	const locationTypeArray = locationDataType.map(element => ({
		id: element.datasetDetails.locationID,
		name: element.datasetDetails.location,
	}));
	return locationTypeArray;
};

export const getKeyValueData = (key, value) => ({ [key]: value });

export const isEqualArray = (arrayVal, otherVal) => isEqual(arrayVal, otherVal);

export const validateRegistrationData = (data = {}, value) => {
	let result = false;
	let keysArray = [];
	const filterData = DatasetLocationMapping.data.find(elem => elem.datasetDetails.locationID === value.id).datasetDetails.LocationMetaData;
	const dataFound = Object.keys(data);
	dataFound.forEach((elem) => {
		if (data[elem] !== "") {
			keysArray = [...keysArray, elem];
		}
	});
	result = isEqualArray(filterData.sort(), keysArray.sort());
	return result;
};

export const validateTableColumnData = (value = "") => {
	const tableName = TableColumnMapping.map(elem => elem.tableName);
	if (tableName.includes(value) || value === "") {
		return { result: true, message: "" };
	}
	return { result: false, message: "Please provide valid table name" };
};
