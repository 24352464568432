// Import required liraries
import React from "react";
import { Link } from "react-router-dom";

// Import styles
import "./PageNotFound.scss";

// Import Custom Component
import LayoutNone from "../../layouts/LayoutNone/LayoutNone";

function PageNotFound() {
	return (
		<LayoutNone>
			<div className="page-not-found-container">
				<div className="title-container">
					<i className="material-icons-outlined page-not-found-icon">
						sentiment_very_dissatisfied
					</i>
					<h1 className="page-not-found-title">
						This page is not yet available. Thank you for your patience.
					</h1>
				</div>
				<Link to="/home">
					<button type="button" className="button" style={{ background: '#4EAFB3' }}>Go to Home</button>
				</Link>
			</div>
		</LayoutNone>
	);
}

PageNotFound.propTypes = {};

export default PageNotFound;
