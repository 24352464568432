const dataset = {
	myList: { status: "loading", message: "", data: [] },
	standardList: { status: "loading", message: "", data: [] },
	datasetDetails: { status: "loading", message: "", data: {} },
	runHistoryList: { status: "loading", message: "", data: [] },
	runHistory: { status: "loading", message: "", data: [] },
	datasetConfig: { status: "pending", message: "", data: {} },
	datasetRegister: { status: "loading", message: "", data: [] },
	validationStatus: { status: "pending", message: "", data: [] },
	registerStatus: { status: "pending", message: "", data: [] },
	addDatasetStatus: { status: "pending", message: "", data: [] },
	addValidationStatus: { status: "pending", message: "", data: [] },
	addUploadStatus: { status: "pending", message: "", data: [] },
	downloadStatus: { status: "pending", message: "", data: [] },
	updateStatusOFUpload: { status: "pending", message: "", data: [] },
};

const user = {
	isAuth: false,
	theme: "light",
	name: "",
	preferred_username: "",
	role: "",
	token: "",
	error: null,
	errorCode: null,
	selections: {
		country: "ALL",
		segment: "ALL",
		brand: "ALL",
		searchText: "",
		appView: "table",
		tableOrder: "desc",
		tableOrderBy: "modified_on",
		runView: "data",
		activeRunId: null,
		activeRunPayload: null,
		runFilters: {
			searchText: "",
			startTime: "",
			endTime: "",
			orderBy: "created_on",
			dayCat: "all",
		},
	},
	alertInfo: {
		message: "",
		open: false,
		severity: "success",
	},
	dialogInfo: {
		message: "",
		open: false,
		handleYes: () => {},
		handleNo: () => {},
	},
	screen: {
		isSidenavBarExpanded: true,
	},
};

export default { user, dataset };
