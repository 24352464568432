import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
// import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from "@material-ui/core/styles";

import PropTypes from 'prop-types';

import "./ErrorAlert.scss";

// const SubHeading = withStyles({
// 	root: {
// 		fontFamily: "Hind Siliguri",
// 		fontStyle: "normal",
// 		fontWeight: "600",
// 		fontSize: "13px",
// 		lineHeight: "26px",
// 		color: "#465A69",
// 	},
// })(Typography);

const useStyles = makeStyles(() => ({
	contained: {
		borderColor: "#4EAFB3",
		backgroundColor: "#4EAFB3",
		color: "white",
		marginRight: 4,
		fontSize: 11,
		padding: 10,
		height: 23,
		minWidth: 15,
		textTransform: "capitalize",
		fontFamily: "Hind Siliguri",
		"&:hover": {
			borderColor: "#4EAFB3",
			backgroundColor: "#4EAFB3",
			color: "white",
			boxShadow: "none",
		},
	},
}));
export default function ErrorAlert({
	message,
	open,
	redirect,
	handleClose,
}) {
	const classes = useStyles();
	return (
		<div>
			<Dialog
				open={open}
				onClose={() => handleClose(redirect)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<div className="alert-container">
					<DialogContent>
						<DialogContentText>
							<span>{message}</span>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button variant="contained" onClick={handleClose} className={classes.contained} autoFocus>
							Close
						</Button>
					</DialogActions>
				</div>
			</Dialog>
		</div>
	);
}
ErrorAlert.propTypes = {
	message: PropTypes.any.isRequired,
	open: PropTypes.any.isRequired,
	redirect: PropTypes.any.isRequired,
	handleClose: PropTypes.any.isRequired,
};
