// Import required libraies
import React from "react";

// Import Custom Component
import LayoutNone from "../../layouts/LayoutNone/LayoutNone";

import SomethingWentWrongImg from "../../../assets/images/something-went-wrong.svg";

const Error = () => (
	<LayoutNone>
		<div style={{ textAlign: 'center' }}>
			<h3 style={{ marginBottom: '10px', marginTop: '20px' }}>Something went wrong!</h3>
			<img src={SomethingWentWrongImg} alt="" style={{ width: '500px' }} />
		</div>
	</LayoutNone>
);

export default Error;
