// Import required libraries
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles, createTheme, ThemeProvider } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";

// Import custom components
import Loader from "../Loader/Loader";

import StandardDatasetsImg from "../../../assets/images/standard-datasets.svg";

// Import styles
import "./Sidenav.scss";

// Import action creators
import {
	updateData,
	updateAlertInfo,
	updateUserInfo,
	updateDialogInfo,
	toggleSidenav,
} from "../../../actions";
import config from "../../config/config";

// Import data & utils
import { downloadFile } from "../../utils/utils";

const useStyles = makeStyles(() => ({
	list: {
		width: 500,
	},
	typographyStyle: {
		fontFamily: "Hind Siliguri",
		fontSize: 14,
		color: "#46596a",
		minWidth: 400,
		maxWidth: 600,
	},
	contained: {
		color: "white",
		backgroundColor: "#46596a",
		marginRight: 4,
		fontSize: 11,
		padding: 10,
		height: 23,
		minWidth: 15,
		textTransform: "capitalize",
		fontFamily: "Hind Siliguri",
		boxShadow: "none",
		"&:hover": {
			backgroundColor: "#46596a",
			boxShadow: "none",
		},
	},
	outlined: {
		borderColor: "#46596a",
		backgroundColor: "transparent",
		color: "#46596a",
		marginRight: 4,
		fontSize: 11,
		padding: 10,
		height: 23,
		minWidth: 15,
		textTransform: "capitalize",
		fontFamily: "Hind Siliguri",
		"&:hover": {
			backgroundColor: "transparent",
			boxShadow: "none",
		},
	},
}));

const alertTheme = createTheme({
	palette: {
		success: {
			main: "#f0f2f4",
			contrastText: "#46596a",
		},
		info: {
			main: "#f0f2f4",
			contrastText: "#46596a",
		},
		error: {
			main: "#f0f2f4",
			contrastText: "#46596a",
		},
	},
	"& .MuiAlert-filledSuccess": {
		color: "#46596a",
		backgroundColor: "#f0f2f4",
		width: "800px",
	},
});

function Alert(props) {
	const classes = useStyles();
	return (
		<ThemeProvider theme={alertTheme}>
			<MuiAlert
				className={classes.typographyStyle}
				elevation={6}
				variant="filled"
				{...props}
			/>
		</ThemeProvider>
	);
}

const tabNames = [
	{
		key: 'addglobalrules',
		title: 'Add Global Rules',
		specialStyling: true,
		materialUiIconName: "add_box",
		materialUiClassName: "material-icons-outlined",
	},
	{
		key: 'datasets',
		svgIcon: StandardDatasetsImg, // not used as it didn't match VD
		materialUiIconName: "filter_none",
		materialUiClassName: "material-icons-outlined",
		title: 'Datasets',
	},
	{
		key: 'mydatasets',
		materialUiIconName: "folder",
		materialUiClassName: "material-icons-outlined",
		title: 'My Datasets',
	}, {
		key: 'comparedatasets',
		materialUiIconName: "difference",
		materialUiClassName: "material-icons-outlined",
		title: 'Compare Datasets',
	}];

function Sidenav({
	match,
	user,
	updateAlertInfo: updateAlertInfoFn,
	toggleSidenav: toggleSidenavFn,
}) {
	const classes = useStyles();
	const [state, setState] = useState({
		top: false,
		left: false,
		bottom: false,
		right: false,
	});
	const [appResourceData] = useState({
		status: "loading",
		message: "",
		data: [],
	});

	const handleClose = () => {
		updateAlertInfoFn({ ...user.alertInfo, open: false });
	};

	const handleDialogClose = () => {
		updateDialogInfo({ ...user.dialogInfo, open: false });
	};

	const handleMenuClose = () => {
		toggleSidenavFn();
	};

	const toggleDrawer = (anchor, open) => (event) => {
		if (
			event.type === "keydown"
			&& (event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}
		setState({ ...state, [anchor]: open });
	};

	const downloadDocument = (path, name) => {
		if (path) {
			downloadFile(path, `${name}.pdf`);
		} else {
			updateAlertInfoFn({
				open: true,
				message: `${config.messages.noDocuments} for ${name}.`,
				severity: "success",
			});
		}
	};

	const list = anchor => (
		<div
			className={clsx(classes.list, {
				[classes.fullList]: anchor === "top" || anchor === "bottom",
			})}
			role="presentation"
		>
			<div className="list">
				<div className="faq-text-cross-container">
					<p className="faq-text">Resources</p>
					<i
						className="material-icons-outlined drawer-cancel-icon"
						onClick={toggleDrawer(anchor, false)}
					>
						cancel
					</i>
				</div>
				{appResourceData.status === "loading" && <Loader />}
				{appResourceData.status === "success"
					&& (appResourceData.data.length ? (
						appResourceData.data?.map(appItem => (
							<div key={appItem.name} className="list-item">
								<div className="list-row-container">
									<p className="list-primary-text">{appItem.name}</p>
									<i
										className="material-icons-outlined download-icon"
										onClick={() => {
											downloadDocument(appItem.location, appItem.name);
										}}
									>
										file_download
									</i>
								</div>
								{appItem.description
									? appItem.description.split("\n").map((text, key) => (
										<p className="list-secondary-text" key={key}>
											{text}
										</p>
									))
									: null}
							</div>
						))
					) : (
						<p className="no-comments">No Data available</p>
					))}
				{appResourceData.status === "error" && (
					<p className="no-comments">{appResourceData.message}</p>
				)}
			</div>
		</div>
	);

	return (
		<>
			<aside
				className={`${user.theme} ${user.screen.isSidenavBarExpanded
					? "sidenav-expanded"
					: "sidenav-collapsed"
				}`}
			>
				<nav>
					<div>
						<div>
							<div
								className="nav-item-menu disabled-style"
								onClick={handleMenuClose}
							>
								<i className="material-icons-outlined nav-icon-menu">
									reorder
								</i>
								<p
									className={`${user.screen.isSidenavBarExpanded ? "visible" : "hidden"
									} nav-text-menu`}
								>
									Menu

								</p>
							</div>
							{tabNames.map(obj => (
								<NavLink
									to={`/home/${obj.key}`}
									className={`nav-item disabled-style ${obj.specialStyling ? "special" : ""
									}`}
									key={obj.key}
									title={obj.title}
								>
									{obj.materialUiIconName ? (
										<i className={`${obj.materialUiClassName} nav-icon`}>
											{obj.materialUiIconName}
										</i>
									)
										: <img src={obj.svgIcon} alt="svgIcon" style={{ width: '20px', marginRight: '15px' }} />
									}
									<p
										className={`${user.screen.isSidenavBarExpanded
											? "visible"
											: "hidden"
										} nav-text`}
									>
										{obj.title}
									</p>
								</NavLink>
							))}
						</div>
					</div>
				</nav>
				{match.params.app ? (
					<>
						<div
							className="covid-faq-div"
							onClick={toggleDrawer("right", true)}
						>
							<i className="material-icons-outlined nav-icon">help_outline</i>
							<p
								className={`${user.screen.isSidenavBarExpanded ? "visible" : "hidden"
								} nav-text`}
							>
								Resources
							</p>
						</div>
						<Drawer
							anchor="right"
							open={state.right}
							onClose={toggleDrawer("right", false)}
						>
							{list("right")}
						</Drawer>
					</>
				) : null}
			</aside>
			<ThemeProvider theme={alertTheme}>
				<Snackbar
					open={user.alertInfo.open}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					autoHideDuration={config.autoHideDuration}
				// onClose={handleClose}
				>
					<Alert onClose={handleClose} icon={false} severity="success">
						{user.alertInfo.message.split("\n").map((i, key) => <div key={key}>{i}</div>)}
					</Alert>
				</Snackbar>
			</ThemeProvider>
			<Dialog
				onClose={handleDialogClose}
				aria-labelledby="simple-dialog-title"
				open={user.dialogInfo.open}
			>
				<div className="dialog-popup-title">
					{user.dialogInfo.message.split("\n").map((i, key) => <div key={key}>{i}</div>)}
				</div>
				<div className="dialog-popup-btn">
					<Button
						variant="outlined"
						className={classes.outlined}
						onClick={() => {
							user.dialogInfo.handleNo();
						}}
					>
						No
					</Button>
					<Button
						variant="contained"
						className={classes.contained}
						onClick={() => {
							user.dialogInfo.handleYes();
						}}
					>
						Yes
					</Button>
				</div>
			</Dialog>
		</>
	);
}

Sidenav.propTypes = {
	user: PropTypes.any.isRequired,
	match: PropTypes.any.isRequired,
	updateAlertInfo: PropTypes.any.isRequired,
	toggleSidenav: PropTypes.any.isRequired,
};

const mapStateToProps = state => ({
	user: state.user,
});

const mapDispatchToProps = {
	updateData,
	updateAlertInfo,
	updateUserInfo,
	toggleSidenav,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidenav);
