// Import required libraies
import { React, useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

// Import Libraries
import {
	makeStyles, withStyles, Grid, Button,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

// Import Utils
import {
	getLocationTypeDetailsData,
	getLocationDetailsData,
	validateRegistrationData,
	validateTableColumnData,
} from "../../utils/utils";
import DatasetLocationMappingData from "../../../assets/data/DatasetLocationMapping.json";

// Import Styles
import {
	StyledAutoComplete,
	useStyles02,
	StyledInputBase,
	SubHeading,
} from "../../../styles/js/Styles";
import runError from "../../../assets/images/ratify/run-error.svg";

// Import actions
import { updateData } from "../../../actions";

// Import custom Components

// Import Styles
import "../MyDataset/addDataSet.scss";
import Loader from "../../components/Loader/Loader";
import DatasetRules from "./DatasetRules";

// Defining styles
const CssTextField = withStyles({
	root: {
		width: "100%",
		backgroundColor: "#f3f3f3",
		padding: "6px 10px",
		height: "24px",
		borderRadius: "4px",
		"& label.Mui-focused": {
			color: "green",
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "#dedede",
		},
		"& .MuiOutlinedInput-root": {
			backgroundColor: "white",
			"& fieldset": {
				borderColor: "#dedede",
			},
			"&:hover fieldset": {
				borderColor: "#dedede",
			},
			"&.Mui-focused fieldset": {
				border: "0.5px solid #dedede",
			},
		},
	},
})(TextField);

const makeRegisterDatasetFooterStyles = () => {
	const useStyles1 = makeStyles(() => ({
		mainContainer: {
			display: "flex",
			height: "calc(100% - 28px)",
			width: "100%",
			flexDirection: "column",
			justifyContent: "space-between",
			overflow: "auto",
		},
		registerDatasetContainer: {
			display: "flex",
			height: "100%",
			width: "100%",
			flexDirection: "column",
			overflow: "auto",
		},
		inputContainerClass: {
			display: "flex",
			"&:disabled": {
				backgroundColor: "#A4D4D7",
				cursor: "default",
			},
		},
		inputDatasetClass: {
			display: "flex",
			flexDirection: "column",
			paddingRight: "50px",
		},
		validateButtonClass: {
			"&:disabled": {
				backgroundColor: "#9F9F9F",
				cursor: "default",
			},
			backgroundColor: "#465A69",
			fontWeight: "700",
			fontFamily: "Hind Siliguri !important",
		},
		validateButtonContainer: {
			display: "flex",
			alignItems: "center",
		},
		footerButtonClass: {
			"&:disabled": {
				backgroundColor: "#A4D4D7",
				cursor: "default",
			},
			backgroundColor: "#4EAFB3",
			fontWeight: "700",
			fontFamily: "Hind Siliguri !important",
		},
		cancelBtn: {
			color: "#4eafb3",
			border: "1px solid #4eafb3",
			fontSize: "13px",
			padding: "0px 10px",
			fontFamily: "Hind Siliguri",
			textTransform: "none",
			marginRight: "10px",
			fontWeight: "700",
		},
		registerDatasetFooterContainer: {
			padding: "25px 0px",
		},
		leftFooterButtons: {
			marginRight: "20px",
		},
		footerButtonsContainer: {
			display: "flex",
			justifyContent: "end",
			alignItems: "flex-end",
		},
		leftButtonsContainer: {
			display: "flex",
			justifyContent: "space-between",
			// width: "225px !important",
			alignItems: "flex-end",
		},
		validatedText: {
			fontFamily: "Hind Siliguri !important",
			fontSize: "14px !important",
			fontWeight: "700",
		},
		validateContainer: {
			"& .validateContentContainer": {
				border: "1px solid rgba(0, 0, 0, 0.3);",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				borderRadius: 5,
				padding: "20px 0",
				textAlign: "center",
				"& p": {
					margin: "0 !important",
					padding: 0,
					color: "#4EAFB3",
					fontSize: 13,
				},
				"& span": {
					color: "#415A6C",
					fontWeight: 600,
					fontSize: 15,
				},
			},
		},
		validationSuccess: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			"& p": {
				color: "#4EAFB3 !important",
			},
		},
		schemaValidationContainer: {
			"& .schemaVerifyContainer": {
				"& p": {
					color: "#415A6C",
				},
				"& span": {
					fontWeight: 500,
					fontSize: 14,
					color: "#4EAFB3",
					textDecoration: "underline",
					cursor: "pointer",
				},
			},
		},
		mainContent: {
			display: "flex",
			// justifyContent: "space-between",
			flexDirection: "column",
			height: "100%",
		},
		textUnderLine: {
			textDecoration: "underline",
			cursor: "pointer",
		},
		rulesContainer: {
			display: "flex",
			alignItems: "end",
			position: "relative",

			"& span": {
				fontSize: 12,
				color: "#415A6C",
				fontWeight: 600,
				cursor: "pointer",
			},
		},
	}));
	return useStyles1;
};

// Create Dataset Registration form
const RegisterDataset = (props) => {
	const classes = useStyles02();
	const {
		registerDatasetState = {},
		setregisterDatasetState = {},
		validationStatus = {},
		registerStatus = {},
		updateData: updateDataFn,
		defaultData,
		isUpdating,
	} = props;

	const dataRegister = DatasetLocationMappingData;

	// defining the required states
	// For updating environment value
	const [datasetEnvData, setDatasetEnvData] = useState([]);
	const [error, setError] = useState({});

	// For updating temporary fields
	const [locationDetailsData, setlocationDetailsData] = useState([]);

	// For updating values of temporary fields
	const [validationDetailsMetaData, setvalidationDetailsMetaData] = useState(
		{},
	);

	// for updating the filter datasets
	const [filteredDataset, setFilteredDataset] = useState([]);

	// for updating the validation massage
	const initialValue = {
		datasetDetails: {
			LocationMetaData: ["server", "location", "schema", "table"],
			dataFormat: "bqtable",
			location: "Azure",
			locationID: "azure",
			locationName: "Azure",
			sourceType: "remote",
		},
	};
	const [envType, setEnvType] = useState(initialValue);
	const [validState, setValidState] = useState({});
	// for updating the showing the schema
	const [showSchemaState, setShowSchemaState] = useState(false);

	// for the showing the retrying again
	// const [showValidation, setValidation] = useState(false);

	// useDispatch
	// const dispatch = useDispatch();

	// define require variable
	let locationType;

	// use effect
	useEffect(() => {
		if (validationStatus.data && validationStatus.data.datasetSchema) {
			const newColumnName = validationStatus.data.datasetSchema?.filter(
				item => item.dataType === "string",
			);
			const arr2 = [];
			newColumnName.filter((item) => {
				if (filteredDataset.includes(item.columnName)) {
					arr2.push(item.columnName);
					return item.columnName;
				}
				return "";
			});
			setFilteredDataset(arr2);
		}
	}, [validationStatus]);
	useEffect(() => {
		locationType = getLocationTypeDetailsData(dataRegister.data);
		setDatasetEnvData(locationType);
		setvalidationDetailsMetaData({});
		setEnvType(initialValue);
		const locationDetailsArray = getLocationDetailsData(
			initialValue.datasetDetails.LocationMetaData,
		);
		setregisterDatasetState(prevState => ({
			...prevState,
			dataFormat: initialValue.datasetDetails.dataFormat,
			datasetType: initialValue.datasetDetails.sourceType,
		}));
		setlocationDetailsData(locationDetailsArray);
	}, []);
	// for the updating the reset validation status and data, whenever the environment will change
	useEffect(() => {
		updateDataFn("validationStatus", {
			status: "pending",
			message: "",
			data: [],
		});
	}, [envType]);

	useEffect(() => {
		updateDataFn("validationStatus", {
			status: "pending",
			message: "",
			data: [],
		});
	}, [validationDetailsMetaData]);

	const getInitialMetaData = (value) => {
		const ReqObject = value.reduce(
			(obj, item) => ({
				...obj,
				[Object.keys(item)[0]]: Object.values(item)[0],
			}),
			{},
		);
		return ReqObject;
	};

	// used to prefilled all value on register page .
	useEffect(() => {
		if (
			defaultData
			&& Object.keys(defaultData)
			&& Object.keys(defaultData).length > 0
		) {
			setvalidationDetailsMetaData(
				getInitialMetaData(defaultData.datasetDetails?.locationMetaData),
			);
			setFilteredDataset(defaultData.datasetFilterColumns);
			const envData = [];
			defaultData.datasetDetails?.locationMetaData?.map(item => envData.push(Object.keys(item)[0]));
			setEnvType({
				datasetDetails: {
					LocationMetaData: ["server", "location", "schema", "table"],
					dataFormat: "bqtable",
					location: "Azure",
					locationID: "azure",
					locationName: "Azure",
					sourceType: "remote",
				},
			});
		}
	}, [defaultData]);

	// handle Register data set
	const handleChange = (key, value) => {
		setregisterDatasetState({
			...registerDatasetState,
			[key]: value,
		});
	};

	const handleFocus = () => {
		// setValidation(true);
	};

	const useRegisterFooterStyles = makeRegisterDatasetFooterStyles();
	const registerFooterClasses = useRegisterFooterStyles();
	const {
		inputDatasetClass,
		inputContainerClass,
		registerDatasetContainer,
		mainContainer,
		registerDatasetFooterContainer,
		mainContent,
		footerButtonClass,
	} = registerFooterClasses;
	const envValue = {
		id: envType.datasetDetails?.locationID,
		name: envType.datasetDetails?.location,
	};

	return (
		<>
			<div className={mainContainer}>
				<div className={registerDatasetContainer}>
					<div className={mainContent}>
						<div>
							<div className={inputContainerClass}>
								<div className={inputDatasetClass}>
									<SubHeading className="sub-heading">
										Select Environment
									</SubHeading>
									<StyledAutoComplete
										id="share-select-user"
										size="small"
										className={classes.typographyStyle}
										options={datasetEnvData}
										value={envValue}
										style={{
											fontFamily: "Hind Siliguri !important",
											width: "305px",
											height: "35px",
										}}
										disabled={isUpdating}
										disableClearable
										getOptionLabel={option => option.name || ""}
										getOptionSelected={(option, value) => option.name === value.name
										}
										onChange={(e, value) => {
											updateDataFn("registerStatus", {
												status: "pending",
												message: "",
												data: [],
											});
											handleChange("environmentName", value.name);
											setvalidationDetailsMetaData({});
											const locationDetailsDataValue = dataRegister?.data.find(
												row => row.datasetDetails.locationID === value.id,
											);
											setEnvType(locationDetailsDataValue);
											setregisterDatasetState(prevState => ({
												...prevState,
												dataFormat:
													locationDetailsDataValue.datasetDetails.dataFormat,
												datasetType:
													locationDetailsDataValue.datasetDetails.sourceType,
											}));
											const locationDetailsArray = getLocationDetailsData(
												locationDetailsDataValue.datasetDetails.LocationMetaData,
											);
											setlocationDetailsData(locationDetailsArray);
											setValidState({});
										}}
										noOptionsText="No option"
										renderInput={params => (
											<CssTextField
												{...params}
												placeholder="Select Environment Name"
												variant="standard"
												className={classes.typographyStyle}
											/>
										)}
									/>
								</div>
							</div>
							<div className={inputContainerClass}>
								<>
									<LocationDetailsFields
										validationStatus={validationStatus}
										locationDetailsData={locationDetailsData}
										validationDetailsMetaData={validationDetailsMetaData}
										handleFocus={handleFocus}
										setvalidationDetailsMetaData={setvalidationDetailsMetaData}
										isUpdating={isUpdating}
										setValidState={setValidState}
										validState={validState}
									/>
								</>
							</div>
						</div>
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								marginTop: "20px",
							}}
						>
							<button
								type="button"
								className={footerButtonClass}
								disabled={!validateRegistrationData(validState, envValue)}
								onClick={() => setError(validateTableColumnData(validState?.table || ""))
								}
							>
								Validate
							</button>
						</div>
						<div>
							<p
								style={{
									display: "flex",
									justifyContent: "center",
									color: "red",
									fontSize: "12px",
								}}
							>
								{error?.message || ""}
							</p>
						</div>
						<div>
							<DatasetRules
								tableName={validState?.table || ""}
								disable={
									validateRegistrationData(validState, envValue)
									&& error?.result
								}
							/>
						</div>
					</div>
				</div>
				<div className={registerDatasetFooterContainer}>
					<RegisterDatasetFooter
						registerDatasetState={registerDatasetState}
						validationDetailsMetaData={validationDetailsMetaData}
						dataRegister={dataRegister}
						validationStatus={validationStatus}
						setShowSchemaState={setShowSchemaState}
						showSchemaState={showSchemaState}
						filteredDataset={filteredDataset}
						registerStatus={registerStatus}
						envType={envType}
						isUpdating={isUpdating}
						defaultData={defaultData}
						disableFlag={
							validateRegistrationData(validState, envValue) && error?.result
						}
					/>
				</div>
			</div>
		</>
	);
};

const LocationDetailsFields = (props) => {
	const classes = useStyles02();
	const {
		locationDetailsData = [],
		validationDetailsMetaData = {},
		setvalidationDetailsMetaData,
		handleFocus,
		isUpdating,
		setValidState,
		validState,
	} = props;
	const useRegisterFooterStyles = makeRegisterDatasetFooterStyles();
	const registerFooterClasses = useRegisterFooterStyles();
	const { inputDatasetClass } = registerFooterClasses;

	const handleChange = (key, value) => {
		setValidState({ ...validState, [key]: value });
		setvalidationDetailsMetaData({
			...validationDetailsMetaData,
			[key]: value,
		});
	};

	return (
		<>
			<Grid
				container
				spacing={{ xs: 2, md: 3 }}
				columns={{ xs: 4, sm: 8, md: 12 }}
			>
				{locationDetailsData.map((row) => {
					const { id = "", name = "" } = row;
					const el = (
						<Grid item xs={6}>
							<SubHeading>{name}</SubHeading>
							<StyledInputBase
								className={classes.inputTextVal}
								onFocus={e => handleFocus(id, e.target.value)}
								onChange={e => handleChange(id, e.target.value)}
								placeholder={`Enter ${name.toLowerCase()}`}
								disabled={isUpdating}
								value={validationDetailsMetaData[id] || ""}
								style={{
									fontFamily: "Hind Siliguri !important",
									width: "320px",
								}}
								size="small"
							/>
						</Grid>
					);
					return (
						<div className={inputDatasetClass} key={id}>
							{el}
						</div>
					);
				})}
			</Grid>
		</>
	);
};

const RegisterDatasetFooter = (props) => {
	const { registerStatus = {}, disableFlag } = props;
	const history = useHistory();

	const useRegisterFooterStyles = makeRegisterDatasetFooterStyles();
	const registerFooterClasses = useRegisterFooterStyles();
	const {
		footerButtonClass,
		footerButtonsContainer,
		leftButtonsContainer,
		cancelBtn,
	} = registerFooterClasses;
	const [displayMessage, setDisplayMessage] = useState({
		message: "",
		open: false,
	});

	const registerDatasetSend = () => {
		setDisplayMessage({ message: "Changes saved successfully.", open: true });
	};

	useEffect(() => {
		if (registerStatus?.status === "success") {
			history.push("/home/datasets/");
		} else if (registerStatus?.status === "error") {
			console.log("API failed", registerStatus.message);
		}
	}, [registerStatus]);

	return (
		<div
			className={footerButtonsContainer}
			style={{ display: "flex", alignItems: "center" }}
		>
			<p
				style={{
					color: "#4eafb3",
					fontSize: "13px",
					fontFamily: "Hind Siliguri !important",
					paddingRight: "20px",
				}}
			>
				{displayMessage.message}
			</p>
			<div className={leftButtonsContainer}>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "end",
					}}
				>
					{registerStatus.status === "error" && (
						<div style={{ display: "flex", alignItems: "center" }}>
							<span style={{ color: "red", fontSize: "12px" }}>
								{registerStatus.message}
							</span>
							<img src={runError} title="validated" alt="Success" />
						</div>
					)}
					{registerStatus.status === "loading" && <Loader />}
					<div>
						<Link to="/home/datasets" style={{ textDecoration: "none" }}>
							<Button className={cancelBtn}>Cancel</Button>
						</Link>
						<button
							type="button"
							className={footerButtonClass}
							disabled={!disableFlag}
							onClick={registerDatasetSend}
						>
							Save Dataset
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

RegisterDatasetFooter.propTypes = {
	registerStatus: PropTypes.any.isRequired,
	disableFlag: PropTypes.any.isRequired,
};

LocationDetailsFields.propTypes = {
	locationDetailsData: PropTypes.any.isRequired,
	validationDetailsMetaData: PropTypes.any.isRequired,
	setvalidationDetailsMetaData: PropTypes.any.isRequired,
	handleFocus: PropTypes.any.isRequired,
	isUpdating: PropTypes.any.isRequired,
	setValidState: PropTypes.any.isRequired,
	validState: PropTypes.any.isRequired,
};

RegisterDataset.propTypes = {
	match: PropTypes.object.isRequired,
	validationStatus: PropTypes.any.isRequired,
	dataRegister: PropTypes.any.isRequired,
	registerDatasetState: PropTypes.any.isRequired,
	setregisterDatasetState: PropTypes.any.isRequired,
	registerStatus: PropTypes.any.isRequired,
	updateData: PropTypes.any.isRequired,
	defaultData: PropTypes.any.isRequired,
	isUpdating: PropTypes.any.isRequired,
};

const mapStateToProps = state => ({
	dataRegister: { ...state.dataset.datasetRegister },
	validationStatus: { ...state.dataset.validationStatus },
	registerStatus: { ...state.dataset.registerStatus },
});
const mapDispatchToProps = { updateData };

export default connect(mapStateToProps, mapDispatchToProps)(RegisterDataset);
