import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { v4 } from "uuid";

import { splitUrlToBreadcrumbData } from "../../utils/utils";

import "./Breadcrumbs.scss";

const labelNameMapping = {
	addglobalrules: "Add GLobal Rules",
	datasets: "Datasets",
	mydatsets: "My Datasets",
	addnew: "Add New",
};

const removehyphens = label => label.replace(/-/g, ' ');

function Breadcrumbs({ match, user }) {
	const data = splitUrlToBreadcrumbData(match.url);
	return (
		<div className={`${user.theme} breadcrumb`}>
			{data.map((obj, index) => {
				const label = removehyphens(labelNameMapping[obj.label] || obj.label);
				if (!label) {
					return null;
				}
				return (
					<span key={v4()} className="breadcrumb-item-container">
						{index !== data.length - 1 ? (
							<>
								<Link className="breadcrumb-item disabled-style" to={obj.path}>
									{label}
								</Link>
								<span>&nbsp;/&nbsp;</span>
							</>
						) : (
							<span className="breadcrumb-item active">{label}</span>
						)}
					</span>
				);
			})}
		</div>
	);
}

Breadcrumbs.propTypes = {
	match: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({ user: state.user });

export default connect(mapStateToProps)(Breadcrumbs);
