import React from "react";
import "./uploadLoader.scss";

export default function UploadLoader() {
	return (
		<div className="upload-loader">
			<div className="bar" />
		</div>
	);
}
