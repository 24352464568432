import React from "react";
import PropTypes from "prop-types";

import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";

import DataSetTemplate from "./DataSetTemplate";

import "./index.scss";

export default function DiagnosticReport({ dataobj, fromTrigger }) {
	const [open, setOpen] = React.useState(false);
	const handleClickOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	let buttonName = "Diagnostic Report";
	let diagnosticReportClass = "";
	if (dataobj?.buttonName) {
		diagnosticReportClass = "view-report-btn";
		buttonName = dataobj?.buttonName;
	} else {
		diagnosticReportClass = "report-btn";
	}

	return (
		<React.Fragment>
			<button
				type="button"
				className={diagnosticReportClass}
				onClick={handleClickOpen}
			>
				{buttonName}
			</button>
			<Dialog
				fullWidth
				maxWidth="lg"
				open={open}
				onClose={handleClose}
				aria-labelledby="max-width-dialog-title"
			>
				<Box style={{ overflowX: "hidden" }}>
					<DataSetTemplate
						data={{
							runConfigId: dataobj.runConfigId,
							runConfigName: dataobj.runConfigName,
							datasetId: dataobj.datasetId,
							datasetName: dataobj.datasetName,
							datasetFilters: dataobj.datasetFilters,
							schedulingDetails: dataobj.schedulingDetails,
							timeRange: dataobj.timeRange,
							response: dataobj.response,
							lastRunOn: dataobj.lastRunOn,
						}}
						closeModal={handleClose}
						fromTrigger={fromTrigger}
					/>
				</Box>
			</Dialog>
		</React.Fragment>
	);
}

DiagnosticReport.propTypes = {
	dataobj: PropTypes.object.isRequired,
	fromTrigger: PropTypes.object.isRequired,
};
