// Import required libraies
import React, { useEffect, useState } from "react";
import Popover from "@material-ui/core/Popover";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TimeAgo from "react-timeago";
import { NavLink } from "react-router-dom";
import Badge from "@material-ui/core/Badge";
import { makeStyles } from "@material-ui/core/styles";

// Import custom components
import Loader from "../Loader/Loader";

// Import utils/data
import {
	makeDefaultResponseJson,
	generateMessageNotification,
	makeRunNameFromRunNew,
} from "../../utils/utils";

// Import action creators
import {
	updateData,
	updateSelections,
	updateUserInfo,
} from "../../../actions";

// Import config
import config from "../../config/config";

// Import styles
import "./Notifications.scss";

const useStyles = makeStyles(theme => ({
	bellIconStyle: {
		"& .MuiBadge-dot": {
			backgroundColor: "#F10000 !important",
		},
		"& .MuiBadge-anchorOriginTopRightRectangle": {
			top: 3,
			right: 6,
		},
	},
	root: {
		padding: 0,
		"& > * + *": {
			marginTop: theme.spacing(3),
		},
	},
}));

function NotificationRow({
	item, updateNotification, updateSelections: updateSelectionsFn, match,
}) {
	return (
		<div className={`notification-container ${item.notification_status}`}>
			<div className="notification-message-row">
				<div className={`run-icon ${item.payload.notification_type}`} />
				<p className="notification-message">
					{generateMessageNotification(item.payload)}
				</p>
			</div>
			<div className="notification-sub-container">
				<p className="notification-time-message">
					<TimeAgo date={new Date(+item.created_time)} />
				</p>
				<NavLink
					to={`/home/${match.params.function}/${match.params.app
					}/viewruns/${makeRunNameFromRunNew(item.payload)}`}
					className="nav-run-item disabled-style"
				>
					<p
						className="notification-run-message"
						onClick={() => {
							updateNotification([item.notification_id]);
							updateSelectionsFn("activeRunId", item.payload.run_id);
						}}
					>
						View Run
					</p>
				</NavLink>
			</div>
			<hr className="notification-separator" />
		</div>
	);
}

NotificationRow.propTypes = {
	item: PropTypes.object.isRequired,
	updateNotification: PropTypes.any.isRequired,
	updateSelections: PropTypes.any.isRequired,
	match: PropTypes.any.isRequired,
};


function Notifications({
	notificationData, match, updateSelections: updateSelectionsFn, updateData: updateDataFn, allData,
}) {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const classes = useStyles();

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	// Defining required variables
	const app = match.params.app.toLowerCase();
	const activeApp = allData?.apps?.data.length > 0
		? allData?.apps?.data.find(
			obj => obj.name.toLowerCase() === app.toLowerCase(),
		)
		: null;

	// Get Notification data
	const fetchData = () => {
		if (activeApp) {
			const url = `${config.api.notificationUrl}?app_id=${activeApp?.id}`;
			fetch(url)
				.then((response) => {
					console.groupCollapsed("requesting", url);
					console.log("REPSONSE -> ", response);
					if (response.status === 200) {
						return response.json();
					}
					const error = response.statusText;
					const errorCode = response.status;
					console.log("ERROR -> ", error);
					return { ...makeDefaultResponseJson(), error, errorCode };
				})
				.then((json) => {
					console.log("JSON -> ", json);
					console.groupEnd();
					if (json.errorCode) updateUserInfo({ error: json.error, errorCode: json.errorCode });
					else {
						updateDataFn("notificationData", json.data);
					}
				});
		}
		return () => { };
	};

	// fetch notification data in every 10 mins
	useEffect(() => {
		if (activeApp) {
			fetchData();
			const intervalId = setInterval(() => {
				fetchData();
			}, config.fetchNotificationDuration);
			return () => clearInterval(intervalId);
		}
		return null;
	}, [activeApp]);

	const updateNotification = (arrId) => {
		handleClose();
		const body = arrId;
		const url = config.api.notificationUrl;
		fetch(url, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(body),
		})
			.then((response) => {
				console.groupCollapsed("requesting", url);
				console.log("REPSONSE -> ", response);
				if (response.status === 200) {
					return response.json();
				}
				const error = response.statusText;
				const errorCode = response.status;
				console.log("ERROR -> ", error);
				return { ...makeDefaultResponseJson(), error, errorCode };
			})
			.then((json) => {
				console.log("JSON -> ", json);
				console.groupEnd();
				fetchData();
				if (json.errorCode) updateUserInfo({ error: json.error, errorCode: json.errorCode });
				else {
					updateDataFn("notificationData", notificationData);
				}
			});
	};
	const invisible = notificationData
		? !notificationData.some(i => i.notification_status === "unread")
		: true;

	return (
		<div className="notification-container">
			<Badge
				// color="primary"
				variant="dot"
				invisible={invisible}
				className={classes.bellIconStyle}
			>
				<i
					className="material-icons-outlined header-menu-icon notification"
					onClick={handleClick}
				>
					notifications
				</i>
			</Badge>
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
			>
				<div className="notification-header-container">
					<p className="notification-header">notifications</p>
					{notificationData ? (
						notificationData.some(
							i => i.notification_status === "unread",
						) && (
							<button
								type="button"
								className="notification-button"
								onClick={() => {
									updateNotification(
										notificationData
											.filter(i => i.notification_status === "unread")
											.map(i => i.notification_id),
									);
								}}
							>
								Mark all as read
							</button>
						)
					) : (
						<Loader />
					)}
				</div>
				<div className="notification-message-container">
					{notificationData ? (
						notificationData.length > 0 ? (
							notificationData.map(item => (
								<NotificationRow
									item={item}
									key={item.notification_id}
									updateNotification={updateNotification}
									updateSelections={updateSelectionsFn}
									match={match}
								/>
							))
						) : (
							<div className="notification-container">
								<p className="no-notification-message">No notifications</p>
							</div>
						)
					) : (
						<Loader />
					)}
				</div>
			</Popover>
		</div>
	);
}

Notifications.propTypes = {
	notificationData: PropTypes.array.isRequired,
	updateSelections: PropTypes.any.isRequired,
	updateData: PropTypes.any.isRequired,
	allData: PropTypes.any.isRequired,
	match: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	notificationData: state.data.notificationData,
	allData: state.data,
});

const mapDispatchToProps = {
	updateUserInfo,
	updateData,
	updateSelections,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
