// Import required libraies
import React from "react";
import PropTypes from "prop-types";

// Import Custom Component
import WorkInProgress from "../../js/components/WorkInProgress/WorkInProgress";

// Import tabs
import StandardDataset from "../StandardDataset";
import LayoutTopSideBottom from "../../js/layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import Breadcrumbs from "../../js/components/Breadcrumbs/Breadcrumbs";
import MyDataset from "../MyDataset";

// Import action creators


function Dataset(props) {
	const { match = {} } = props;
	const { params: { subtab } = {} } = match;

	const tabMapping = {
		ratify: {
			component: null,
		},
		mydatasets: {
		    component: <MyDataset match={match} />,
		},
		datasets: {
			component: <StandardDataset match={match} />,
		},
	};

	return (
		<LayoutTopSideBottom match={match}>
			<Breadcrumbs match={match} />
			{
				subtab
					? tabMapping[subtab]?.component ? tabMapping[subtab].component : <WorkInProgress />
					: <WorkInProgress />
				// <Redirect
				//     to={`${url}${url.endsWith("/") ? "" : "/"}standard`} />
			}
		</LayoutTopSideBottom>
	);
}


Dataset.propTypes = {
	match: PropTypes.object.isRequired,
};

export default Dataset;
