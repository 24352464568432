/** @format */

import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import "./index.scss";
import PropTypes from "prop-types";
import SelectCmp from "./SelectCmp";
import SelectTimeTrigger from './SelectTime';

const useStyles = makeStyles({
	root: {
		width: "35px",
		height: "35px",
		background: "#ECECEC",
		borderRadius: "3px",
		margin: "1.5px",
		textTransform: "capitalize",
		fontFamily: "Hind Siliguri",
		fontSize: "12px",
	},
	noBorder: {
		border: "none",
	},
	inputTextval: {
		fontFamily: "Hind Siliguri!important",
		fontSize: "12px!important",
		fontStyle: "normal!important",
		fontWeight: "500!important",
		lineHeight: "19px!important",
		letterSpacing: "0em!important",
		textAlign: "left!important",
		background: "#EDEDED!important",
		paddinLeft: "10px!important",
		color: "#465A69!important",
		borderRadius: "5px!important",
		padding: "5px 12px 5px 12px!important",
	},
});

const SubHeading = withStyles({
	root: {
		fontFamily: "Hind Siliguri",
		fontStyle: "normal",
		fontWeight: "600",
		fontSize: "12px",
		lineHeight: "19px",
		color: "#465A69",
		marginBottom: "7px",
		marginTop: "13px",
	},
})(Typography);

function getTriggerTime() {
	const maxTime = 23;
	const timeArray = [];
	let time = "";
	for (let index = 0; index <= maxTime; index++) {
		if (index < 10) {
			time = `0${index.toString()}`;
		} else {
			time = index.toString();
		}
		timeArray.push(`${time}:00`);
	}
	return timeArray;
}
function getMonthDays() {
	const maxDays = 28;
	const daysArray = [];
	for (let index = 1; index <= maxDays; index++) {
		daysArray.push(index.toString());
	}
	return daysArray;
}

const Scheduler = ({
	setFrequency,
	setFrequencyValue,
	setTriggerTime,
	setTriggerTimeZone,
	defaultValues = {},
	isModle,
	size,
	width,
	triggerTime,
}) => {
	const weeklyDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
	const monthlyOptions = ["First day of Month", "Last day of Month", "Any day of Month"];
	const initialScheduleState = {
		isMonthly: false,
		days: weeklyDays,
		monthlySelection: "First day of Month",
		selected: "Mon",
		frequency: "Weekly",
		triggerDate: "",
		triggerDay: "Mon",
		triggerTime: "09:00",
		triggerTimezone: "UTC",
	};
	const classes = useStyles();

	const triggerTimeArray = getTriggerTime();
	const frequencyList = ["Daily", "Weekly", "Monthly"];
	const [schedulerState, setSchedulerState] = React.useState(initialScheduleState);

	const showFrequencyDays = (frequency) => {
		if (frequency === "Weekly") {
			setSchedulerState({ ...schedulerState, ...initialScheduleState });
			setFrequencyValue("Mon");
			setTriggerTime("09:00");
			// setSchedulerState({ ...schedulerState, days: weeklyDays, isMonthly: false });
		} else if (frequency === "Monthly") {
			setSchedulerState({ ...schedulerState, isMonthly: true, days: [] });
			setFrequencyValue(1);
			setTriggerTime("09:00");
		} else {
			setSchedulerState({ ...schedulerState, days: [], isMonthly: false });
			setFrequencyValue(1);
			setTriggerTime("09:00");
		}
	};

	const handleFrequencyChange = (e) => {
		const frequency = e.target.value;
		setFrequency(frequency);
		showFrequencyDays(frequency);
	};

	const handleSelectedFrequencyDay = (e) => {
		const { name } = e.currentTarget;
		setFrequencyValue(name);
		setSchedulerState({ ...schedulerState, selected: name });
	};

	const getMonthlyDefaultValue = (schedule) => {
		if (schedule?.triggerDate) {
			if (schedule.triggerDate === 1) return "First day of Month";
			if (schedule.triggerDate === "EOM") return "Last day of Month";
		}
		return "Any day of Month";
	};

	const handleMonthlyState = (currentState) => {
		let days = [];
		let selected = "0";
		const monthlySelection = getMonthlyDefaultValue(currentState);
		if (currentState.triggerDate === 1) { setFrequencyValue(1); }
		if (currentState.triggerDate === "EOM") { setFrequencyValue("LASTDAY"); }
		if (currentState.triggerDate !== 1 && currentState.triggerDate !== "EOM") {
			setFrequencyValue(currentState.triggerDate);
			days = getMonthDays();
			selected = currentState.triggerDate;
		}
		const monthlySchedulerState = {
			...schedulerState,
			...currentState,
			isMonthly: true,
			days,
			monthlySelection,
			selected,
		};
		setSchedulerState(monthlySchedulerState);
	};

	const handleState = (currentState) => {
		const days = [];
		if (currentState.frequency === "Daily") {
			setSchedulerState({
				...schedulerState, ...currentState, isMonthly: false, days,
			});
			setFrequencyValue(currentState.triggerDay);
		} else if (currentState.frequency === "Weekly") {
			setSchedulerState({
				...schedulerState, ...currentState, isMonthly: false, days: weeklyDays, ...{ selected: currentState.triggerDate || "Mon" },
			});
			// setFrequencyValue(schedulerState.triggerDate || "Mon");
			setFrequencyValue(currentState.triggerDate || "Mon");
		} else if (currentState.frequency === "Monthly") {
			handleMonthlyState(currentState);
		}
		setTriggerTime(currentState.triggerTime);
		setTriggerTimeZone("UTC");
		setFrequency(currentState.frequency);
	};

	const monthlyChange = (e) => {
		const monthlyFrequency = e.target.value;
		const days = (monthlyFrequency === "First day of Month") || (monthlyFrequency === "Last day of Month") ? [] : getMonthDays();
		setSchedulerState({
			...schedulerState, days, selected: "1",
		});
		if (monthlyFrequency === "First day of Month") setFrequencyValue(1);
		if (monthlyFrequency === "Last day of Month") setFrequencyValue("LASTDAY");
		if (monthlyFrequency === "Any day of Month") setFrequencyValue(1);
	};

	const setDefaults = () => {
		if (defaultValues && !Object.keys(defaultValues).length > 0) {
			setFrequencyValue("Mon");
			setTriggerTime("09:00");
			setTriggerTimeZone("UTC");
			setFrequency("Weekly");
		} else handleState(defaultValues);
	};

	useEffect(setDefaults, []);

	const getSelctedStyle = fd => (fd === schedulerState.selected
		? {
			background: "#465A69",
			color: "#ffffff",
			minWidth: "58px",
			width: "60px",
		}
		: {
			background: "#ECECEC",
			color: "#465A69",
			minWidth: "58px",
			width: "60px",
		});

	return (
		<>
			<Grid container className={isModle ? "" : "scheduler-main-container"}>
				<Grid item xs={12} container direction="row">
					<Grid item md={size || 3} style={{ paddingRight: "20px" }}>
						<Grid item xs={isModle ? 12 : 8} md={12}>
							<SelectCmp
								data={frequencyList}
								change={handleFrequencyChange}
								defaultValue={defaultValues?.frequency || "Weekly"}
							/>
						</Grid>
						{schedulerState.isMonthly
							&& (
								<Grid item xs={isModle ? 12 : 8} md={12} style={{ marginTop: "20px" }}>
									<SelectCmp
										data={monthlyOptions}
										change={monthlyChange}
										defaultValue={schedulerState.monthlySelection}
									/>
								</Grid>
							)
						}
						<Grid item md={12}>
							<Grid container direction="row" md={12}>
								<Grid item xs={6} style={{ paddingRight: "20px" }}>
									<SubHeading>Trigger Time</SubHeading>
									<SelectTimeTrigger
										data={triggerTimeArray}
										change={e => setTriggerTime(e.target.value)}
										value={triggerTime || "09:00"}
									/>
								</Grid>
								<Grid item xs={6}>
									<SubHeading>TimeZone</SubHeading>
									<SelectCmp
										data={["UTC"]}
										defaultValue="UTC"
										isDisabled
										change={e => setTriggerTimeZone(e.target.value)}

									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item md={width || true}>
						<Box marginLeft={isModle ? "0px" : "30px"}>
							{schedulerState.days.map(fd => (
								<Button
									onClick={handleSelectedFrequencyDay}
									className={classes.root}
									style={getSelctedStyle(fd)}
									value={fd}
									key={fd}
									name={fd}
								>
									{fd}
								</Button>
							))
							}
						</Box>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
Scheduler.propTypes = {
	setFrequency: PropTypes.any.isRequired,
	setFrequencyValue: PropTypes.any.isRequired,
	setTriggerTime: PropTypes.any.isRequired,
	setTriggerTimeZone: PropTypes.any.isRequired,
	defaultValues: PropTypes.any.isRequired,
	isModle: PropTypes.any.isRequired,
	size: PropTypes.any.isRequired,
	width: PropTypes.any.isRequired,
	triggerTime: PropTypes.any.isRequired,
};
export default Scheduler;
