import { makeStyles, TableCell, withStyles } from "@material-ui/core";
import Rating from "@mui/material/Rating";
import { styled } from "@mui/material/styles";

export const StyledTableCell = withStyles(() => ({
	body: {
		color: "#46596a",
		fontFamily: "Hind Siliguri",
		padding: "20px 0px",
		cursor: "default",
		alignItems: props => (props.center ? "center" : null),
		display: props => (props.flex ? "flex" : null),
	},
}))(TableCell);

export const useStyles = makeStyles(theme => ({
	pageContent: {
		margin: theme.spacing(5),
		padding: theme.spacing(3),
	},
	listIcon: {
		display: "flex",
		alignItems: "center",
	},
	name: {
		maxWidth: "500px",
		minWidth: "100px",
		fontStyle: "normal",
		fontWeight: 600,
		fontSize: "13px !important",
		lineHeight: "23px",
	},
	tableRow: {
		cursor: "default",
		padding: "9px !important",
		fontStyle: "normal",
		fontWeight: 600,
		fontSize: "13px !important",
		lineHeight: "23px",
		borderBottom: "1px solid #E6E8ED",
		"&:last-child th": {
			borderBottom: 0,
		},
		backgroundColor: "white !important",
		"&:hover": {
			backgroundColor: "#4eafb342 !important",
		},
	},
	gridRoot: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: "center",
		color: theme.palette.text.secondary,
	},
	table: {
		"& thead th": {
			borderBottom: "none",
			fontFamily: "Hind Siliguri",
			backgroundColor: "#E6E8ED",
			color: "rgba(70, 89, 106, 1)",
			fontWeight: 600,
			fontSize: "13px",
			lineHeight: "24px",
			textTransform: "capitalize",
			padding: "12px",
			cursor: "default",
		},
		"& tbody": {
			borderRadius: "4px",
			padding: "10px",
		},
		"& tbody td": {
			fontWeight: "300",
			fontSize: "12px",
			padding: "10px",
		},
		"& tbody tr:hover": {
			cursor: "default",
		},
		"& thead": {
			borderBottom: "none",
		},
		"& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
			color: "#46596a",
		},
		"& .MuiTypography-colorInherit": {
			color: "#46596a !important",
		},
		"& .MuiTypography-body2": {
			fontFamily: "Hind Siliguri !important",
		},
		"&:nth-of-type(odd)": {
			backgroundColor: "white",
		},
		"&:nth-of-type(even)": {
			backgroundColor: "rgba(70, 89, 106, 0.07);",
		},
	},
	selectStyle: {
		paddingLeft: "10px",
		fontFamily: "Hind Siliguri",
		fontStyle: "normal",
		fontWeight: 600,
		fontSize: "12px",
		lineHeight: "19px",
		color: "#465A69",
		background: "#EDEDED",
		width: "100%",
		borderRadius: "5px",
		backgroundColor: "#F2F2F2",
		"& .MuiSvgIcon-root": {
			opacity: 0,
		},
	},

	pagination: {
		"& .MuiPaginationItem-root": {
			fontFamily: "Hind Siliguri",
			fontStyle: "normal",
			fontWeight: 600,
			fontSize: "12px",
			color: "#46596a",
		},
		"& .MuiPaginationItem-page.Mui-selected": {
			backgroundColor: "#EB367F",
			color: "#ffffff",
		},
	},
	paginationSelect: {
		paddingLeft: "10px",
		paddingRight: "10px",
		fontFamily: "Hind Siliguri",
		fontStyle: "normal",
		fontWeight: 600,
		fontSize: "12px",
		lineHeight: "23px",
		color: "#ffffff",
		backgroundColor: "#46596A",
		borderRadius: "5px",
		"& .MuiSvgIcon-root": {
			color: "#ffffff",
		},
	},
	selectDatatype: {
		fontFamily: "Hind Siliguri",
		fontStyle: "normal",
		fontWeight: "500",
		fontSize: "14px",
		lineHeight: "23px",
		textTransform: "capitalize",
		color: "#465A69",
		backgroundColor: "transparent",
		"&:focus": {
			border: "none",
		},
		"&:selected": {
			border: "none",
		},
	},
}));

export const rowStyle = {
	lineHeight: "24px",
	padding: "5px 5px",
};

export const iconStyle = {
	fontSize: "14px",
	marginLeft: "5px",
	color: "#4eafb3",
	marginTop: "3px",
};

export const popOverHeading = {
	fontWeight: 500,
	fontSize: "11px",
	textTransform: "capitalize",
	color: "#B6B6B6",
};

export const popOverBody = {
	fontWeight: 600,
	fontSize: "13px",
	textTransform: "capitalize",
	color: "#465A69",
};

export const popOverContent = {
	minWidth: "200px",
	minHeight: "100px",
	padding: "10px",
};

export const statusIconStyle = {
	width: "22px",
	height: "22px",
	float: "left",
};

export const StyledRating = styled(Rating)({
	"& .MuiRating-iconFilled": {
		color: props => props.backColor,
	},
	"& .MuiRating-iconHover": {
		color: "#ff3d47",
	},
});

export const titleStyle = {
	paddingLeft: "10px",
	paddingTop: "10px",
	fontWeight: 600,
	color: "#465A69",
	display: "flex",
	alignItems: "center",
};

export const selectStyle = {
	paddingLeft: "10px",
	fontFamily: "Hind Siliguri",
	fontStyle: "normal",
	fontWeight: 600,
	fontSize: "12px",
	lineHeight: "19px",
	color: "#465A69",
	background: "#EDEDED",
	width: "100%",
};

export const customMenuItemStyle = {
	paddingLeft: "10px",
	fontFamily: "Hind Siliguri",
	fontStyle: "normal",
	fontWeight: 600,
	fontSize: "12px",
	lineHeight: "19px",
	color: "#465A69",
	background: "#fff",
};

export const tabStyle = {
	fontFamily: "Hind Siliguri",
	fontStyle: "normal",
	fontWeight: 600,
	fontSize: "14px",
	lineHeight: "23px",
	textTransform: "none",
	color: "#465A69",
	opacity: 0.6,
	"&.Mui-selected": {
		color: "#465A69",
		opacity: 1,
	},
	"&.Mui-focusVisible": {
		backgroundColor: "transparent",
	},
};
export const tabListStyle = {
	"& .MuiTabs-indicator": {
		display: "flex",
		justifyContent: "center",
		backgroundColor: "transparent",
		height: "2.5px",
		marginBottom: "5px",
	},
	"& .MuiTabs-indicatorSpan": {
		maxWidth: 30,
		width: "100%",
		backgroundColor: "#465A69",
	},
};
