// Import required libraies
import { React, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { getMyDataset, getDatasetDetails } from "../../actions";
import DatasetTable from "../DatasetTable";
import Loader from "../../js/components/Loader/Loader";
import no_datasets from "../../assets/images/no-datasets.svg";
import Filter from "../Filter";

const MyDataset = ({ myDatasetObj, userRole }) => {
	const dispatch = useDispatch();
	const [criteria, setCriteria] = useState({ searchText: "" });
	const [filteredData, setFilteredData] = useState([]);

	const showDatasetDetails = ({ datasetId }) => {
		dispatch(getDatasetDetails({ datasetId }));
	};
	console.log(userRole);

	useEffect(() => {
		dispatch(getMyDataset());
	}, []);

	useEffect(() => {
		setFilteredData(myDatasetObj.data || []);
	}, [myDatasetObj.data]);

	const changeCriteria = (param, value) => {
		criteria[param] = value;
		setCriteria({ ...criteria });
		if (param === "searchText") {
			let modifiedData = null;
			if (value && value.trim()) {
				modifiedData = myDatasetObj.data.filter(obj => (obj.datasetName || "").toLowerCase().includes(value.toLowerCase()));
			} else {
				modifiedData = myDatasetObj.data.slice();
			}
			setFilteredData([...modifiedData]);
		}
	};

	return (
		<>
			<div className="standard-dataset-register-container">
				<Filter criteria={criteria} changeCriteria={changeCriteria} />
				<Link
					to="/home/mydatasets/addnew"
					style={{ textDecoration: "none" }}
				>
					<button type="button" className="register-button">
						Add New
					</button>
				</Link>
			</div>

			{myDatasetObj.status === "loading" && <Loader />}
			{myDatasetObj.status === "success" && !!filteredData.length && (
				<div className="my-configs-container">
					<DatasetTable
						data={filteredData}
						paginationFlag
						showDatasetDetails={showDatasetDetails}
						criteria={criteria}
						myDatasetPage
						type="local-view"
						isEditable
					/>
				</div>
			)}
			{myDatasetObj.status === "success" && !filteredData.length && (
				<div className="no-configs">
					<img src={no_datasets} style={{ height: "300px", width: "300px" }} alt="noDataImage" />
					<p className="no-data" style={{ margin: "0px" }}><b>No Datasets Available</b></p>
					<p className="no-data" style={{ margin: "0px" }}>It seems like there are no local datasets available.</p>
				</div>
			)}
		</>
	);
};

MyDataset.propTypes = {
	userRole: PropTypes.any.isRequired,
	myDatasetObj: PropTypes.any.isRequired,
};

const mapStateToProps = state => ({
	userRole: state.user.role || "",
	myDatasetObj: state.dataset.myList || {},
});

export default connect(mapStateToProps)(MyDataset);
