// Import required libraies
import { React, useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";
import PropTypes from "prop-types";

// Import Custom Components
import { useHistory, useParams } from "react-router";
import LayoutTopSideBottom from "../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import RegisterDataset from "./RegisterDataset";
import Loader from "../../components/Loader/Loader";

// Import Utils , Data , Images

// Import API functions
import { getRegisterDatasetData, updateData, getDatasetSchema } from "../../../actions";

// Import Actions/Reducers

// Import Custom Hooks

// Import styles

// Defining Initial State
const initialRegisterDatasetData = {
	datasetName: "",
	environmentName: "",
};

// update state
// 5 fields - state

const DatasetRegisterContainer = (props) => {
	const {
		match, dataRegister, updateData: updateDataFn, datasetConfig, userRole,
	} = props;
	const dispatch = useDispatch();
	const history = useHistory();
	const { datasetId } = useParams();
	const [registerDatasetState, setregisterDatasetState] = useState(
		initialRegisterDatasetData,
	);
	const isUpdating = (Boolean(datasetId) && userRole === "admin");

	const getDefaultValue = () => {
		if (!isUpdating) return {};
		return datasetConfig.data;
	};

	useEffect(() => {
		if (datasetId) {
			dispatch(getDatasetSchema({ datasetId }));
		} else {
			dispatch(getRegisterDatasetData());
		}
		return () => {
			updateDataFn("registerStatus", {
				status: "pending",
				message: "",
				data: [],
			});
			updateDataFn("datasetRegister", {
				status: "loading",
				message: "",
				data: [],
			});
			updateDataFn("validationStatus", {
				status: "pending",
				message: "",
				data: [],
			});
		};
	}, [history.location?.pathname]);

	useEffect(() => {
		if (datasetConfig.status === "success" && isUpdating) {
			setregisterDatasetState({
				datasetName: datasetConfig.data.datasetDetails.name,
				environmentName: datasetConfig.data.datasetDetails.location,
				dataFormat: datasetConfig.data.datasetDetails.dataFormat,
				datasetType: datasetConfig.data.datasetDetails.sourceType,
			});
		} else {
			setregisterDatasetState(initialRegisterDatasetData);
		}
	}, [datasetConfig, history.location?.pathname]);

	// use to replace dataset Id with dataset name from breadcrumbs
	const getMatchValue = () => {
		if (isUpdating) {
			let obj = {};
			const { url } = match;
			const newUrl = url?.split("/");
			newUrl.pop();
			if (datasetConfig.status === "success") {
				newUrl.push(datasetConfig.data?.datasetDetails?.name);
			}
			obj = { ...match, url: newUrl.join("/") };
			return obj;
		}
		return match;
	};

	return (
		<>
			<LayoutTopSideBottom match={match}>
				<Breadcrumbs match={getMatchValue()} />
				{((dataRegister.status === "loading" && !isUpdating) || (datasetConfig.status === "loading" && isUpdating)) && <Loader />}
				{((dataRegister.status === "success" && !isUpdating) || (datasetConfig.status === "success" && isUpdating)) && (
					<RegisterDataset
						registerDatasetState={registerDatasetState}
						setregisterDatasetState={setregisterDatasetState}
						defaultData={getDefaultValue()}
						isUpdating={isUpdating}
					/>
				)}
				{dataRegister.status === "error" && <p>{dataRegister.message}</p>}
			</LayoutTopSideBottom>
		</>
	);
};

DatasetRegisterContainer.propTypes = {
	match: PropTypes.object.isRequired,
	dataRegister: PropTypes.any.isRequired,
	updateData: PropTypes.any.isRequired,
	datasetConfig: PropTypes.any.isRequired,
	userRole: PropTypes.any.isRequired,
};

const mapStateToProps = state => ({
	dataRegister: { ...state.dataset.datasetRegister },
	datasetConfig: state.dataset.datasetConfig || {},
	userRole: state.user.role || "",
});
const mapDispatchToProps = { updateData };

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(DatasetRegisterContainer);
