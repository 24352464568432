import React, { useEffect, useState, useRef } from "react";
// import userEvent from "@testing-library/user-event";
import { useRouteMatch, useParams } from "react-router-dom";
import {
	Drawer,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Table,
	Grid,
	Select,
	Box,
	MenuItem,
	makeStyles,
} from "@material-ui/core";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Pagination } from "@material-ui/lab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Loader from "../../js/components/Loader/Loader";
import { CustomMenuItem } from "../DiagnosticReport/ReportSideSecondStyles";
import Filter from "../Filter";
import DetailsTab from "./DetailsTab";

import {
	getDatasetSchema,
	updateData,
	getStandardDataset,
	getRunhistorySchema,
} from "../../actions";

import messages from "../../utils/messages";
import { getKeyValueData } from "../../js/utils/utils";

import config from "../../js/config/config";

import noDataImage from "../../assets/images/undraw_void.svg";

// Import styles
import "../../js/screens/MyDataset/addDataSet.scss";

import {
	titleStyle,
	StyledTableCell,
	useStyles,
	customMenuItemStyle,
	tabStyle,
	tabListStyle,
} from "./styles";
import "./index.scss";
import {
	StyledInputBase,
	StyledTextField,
	SubHeading,
	useStyles02,
} from "../../styles/js/Styles";

// Import custom Hooks
import { useNonInitialEffect } from "../../js/Hooks/useNonInitialEffect";

const dataTypeOptions = ["string", "decimal", "date", "boolean"];

const updateSchema = (datasetId, payload) => {
	const url = `/api/v1/confView?datasetId=${datasetId}`;
	return fetch(url, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(payload),
	});
};

const makeDatasetSchemaStyles = () => {
	const useStyles2 = makeStyles(() => ({
		ViewSchemaDropDown: {
			width: "120px !important",
			height: "32px !important",
			borderRadius: "3px",
			// backgroundColor: "#FFFFFF",
			color: "#FFFFFF",
			"& .MuiInput-underline:before": {
				border: "none",
			},
			"& .MuiInput-underline:hover:not(.Mui-disabled):before": {
				border: "none",
			},
			"& .MuiSelect-select:focus": {
				backgroundColor: "transparent",
				border: "none",
			},
			"& svg": {
				marginTop: "3px",
				height: "17px",
			},
		},
		contentContainer: {
			"&.MuiTabPanel-root": {
				padding: "0px !important",
			},
		},
		rulesContainer: {
			display: "flex",
			alignItems: "end",
			position: "relative",

			"& span": {
				fontSize: 12,
				color: "#415A6C",
				fontWeight: 600,
				cursor: "pointer",
			},
		},
	}));

	return useStyles2;
};

const ViewSchemaRows = ({
	rows,
	setPage,
	page,
	isViewOnly = true,
	setOpen,
	setFilteredData,
	setShowSchemaState,
	updateDataFn,
	validationStatus,
	isEditable,
	datasetId,
	onViewSchemaClose,
	setDatasetConfigClone,
	clickedItem = {},
	datasetConfig = {},
	setTargetColumn = "",
	filteredData,
	datasetConfigClone,
	// addValidationStatus,
	setColumnNames,
	changeNameStatus = {},
}) => {
	const classes = useStyles();
	const pageSizes = [...config.hardCoded.pageSizes];
	const dispatch = useDispatch();
	const [pageSize, setPageSize] = useState(15);
	const [dataType, setDataType] = useState("");

	// const match = useRouteMatch();
	const match = useRouteMatch();

	const handleChangePage = (ev, newPage) => {
		setPage(newPage);
	};

	const handlePageSizeChange = (event) => {
		setPageSize(event.target.value);
		setPage(1);
	};

	const onUpdateSchema = () => {
		console.log(clickedItem);
		if (isEditable && isViewOnly) {
			const payload = {
				data: {
					datasetDetails: {
						...datasetConfig.data.datasetDetails,
					},
					datasetId,
					datasetSchema: rows,
				},
			};
			updateSchema(datasetId, payload).then((response) => {
				response
					.json()
					.then((result) => {
						if (result.status?.statusCode === "ratify-200") {
							dispatch(getDatasetSchema({ datasetId }));
							onViewSchemaClose();
						}
					})
					.catch((error) => {
						console.log("error", error);
					});
			});
		} else {
			if (match.url.endsWith("register") || match.url.endsWith("register/")) {
				updateDataFn("validationStatus", {
					status: "success",
					message: "",
					data: {
						datasetId: validationStatus.data.datasetId,
						datasetSchema: rows,
					},
				});
				const newColumnName = rows.filter(item => item.dataType === "string");
				setColumnNames(newColumnName);
			} else if (
				match.url.endsWith("addDataset")
				|| match.url.endsWith("addDataset/")
			) {
				updateDataFn("addValidationStatus", {
					status: "success",
					message: "",
					data: {
						datasetId,
						datasetSchema: rows,
					},
				});
				// const newColumnName = rows.filter((item) => item.dataType === "string");
				// setColumnNames(newColumnName);
			}

			setOpen(false);

			if (!isViewOnly) {
				setShowSchemaState(false);
			}
			// this is not returning the updated validationStatus
		}
		if (changeNameStatus.status === "success") {
			dispatch(getStandardDataset({}));
		}
	};

	// const handleChangeDataType = (e, obj, ind) => {
	//  handleSchemaUpdate("dataType", e.target.value, obj, ind);
	//  changeFormatList(e.target.value);
	// };

	const handleTypeUpdate = (e, obj) => {
		const { value } = e.target;
		let format = "";
		let { targetColumn } = obj;
		if (targetColumn === true && value === "date") {
			setTargetColumn(null);
		}
		if (value === "string") {
			format = "text";
		} else if (value === "decimal") {
			format = "float";
		} else if (value === "boolean") {
			format = "binary";
		} else if (value === "date") {
			format = "YYYY-MM-DD";
			targetColumn = false;
		}
		const index = filteredData.findIndex(
			el => el.columnName === obj.columnName,
		);
		const notUpdatedRecordsLeft = rows.slice(0, index);
		const notUpdatedRecordsRight = rows.slice(index + 1);
		setDataType(!dataType);
		const updatedState = [
			...notUpdatedRecordsLeft,
			{
				...obj,
				dataType: value,
				dataFormat: format,
				targetColumn,
			},
			...notUpdatedRecordsRight,
		];
		setFilteredData(updatedState);
		const mergedArray = [];
		updatedState.forEach((arr1) => {
			datasetConfigClone.forEach((arr2) => {
				if (arr1.columnName === arr2.columnName) {
					mergedArray.push({ ...arr2, ...arr1 });
				}
			});
		});
		setDatasetConfigClone([...mergedArray]);
	};
	const handleFormatUpdate = (e, obj) => {
		const { value } = e.target;
		const index = filteredData.findIndex(
			el => el.columnName === obj.columnName,
		);
		const notUpdatedRecordsLeft = rows.slice(0, index);
		const notUpdatedRecordsRight = rows.slice(index + 1);
		const updatedState = [
			...notUpdatedRecordsLeft,
			{ ...obj, dataFormat: value },
			...notUpdatedRecordsRight,
		];
		setFilteredData(updatedState);
		const mergedArray = [];
		updatedState.forEach((arr1) => {
			datasetConfigClone.forEach((arr2) => {
				if (arr1.columnName === arr2.columnName) {
					mergedArray.push({ ...arr2, ...arr1 });
				}
			});
		});
		setDatasetConfigClone([...mergedArray]);
	};

	const displayRecords = datasetConfigClone.length > pageSize
		? [...datasetConfigClone].splice((page - 1) * pageSize, pageSize)
		: datasetConfigClone;
	// data.length > page ? [...data].splice((page - 1) * pageSize, pageSize) : data;
	const noOfPages = Math.ceil(datasetConfigClone.length / pageSize);

	const useSchemaStyles = makeDatasetSchemaStyles();
	const datasetSchemaClasses = useSchemaStyles();
	const { ViewSchemaDropDown } = datasetSchemaClasses;

	return (
		<Box
			display="flex"
			justifyContent="space-between"
			flexDirection="column"
			height="calc(100vh - 260px)"
		>
			<div className="schema-table-container">
				<Table stickyHeader className={classes.table}>
					<TableHead>
						<TableRow>
							<TableCell>Column Name</TableCell>
							<TableCell>Data Type</TableCell>
							<TableCell>Expected format</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{displayRecords.map((obj, ind) => (
							<RowComponent
								key={ind}
								ind={ind}
								obj={obj}
								rows={rows}
								isViewOnly={isViewOnly}
								dataTypeOptions={dataTypeOptions}
								// dataFormatList={dataFormatList}
								ViewSchemaDropDown={ViewSchemaDropDown}
								handleFormatUpdate={handleFormatUpdate}
								handleTypeUpdate={handleTypeUpdate}
								dataType={dataType}
								isEditable={isEditable}
							/>
						))}
					</TableBody>
				</Table>
			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "flex-end",
					alignItems: "center",
				}}
			>
				{rows.length > 10 && (
					<Grid container spacing={2}>
						<Grid item>
							<Pagination
								count={noOfPages}
								page={page}
								shape="rounded"
								onChange={handleChangePage}
								siblingCount={0}
								boundaryCount={2}
								className={classes.pagination}
							/>
						</Grid>
						<Grid item>
							<Select
								onChange={handlePageSizeChange}
								value={pageSize}
								disableUnderline
								className={classes.paginationSelect}
								IconComponent={ExpandMoreIcon}
							>
								{pageSizes.map(size => (
									<CustomMenuItem
										key={size}
										value={size}
										style={customMenuItemStyle}
									>
										View
										{' '}
										{size}
									</CustomMenuItem>
								))}
							</Select>
						</Grid>
					</Grid>
				)}

				{(!isViewOnly || isEditable) && (
					<button
						type="button"
						onClick={onUpdateSchema}
						style={{ height: "35px" }}
					>
						Update
					</button>
				)}
			</div>
		</Box>
	);
};

ViewSchemaRows.propTypes = {
	rows: PropTypes.any.isRequired,
	page: PropTypes.any.isRequired,
	setPage: PropTypes.any.isRequired,
	isViewOnly: PropTypes.any.isRequired,
	setFilteredData: PropTypes.any.isRequired,
	setOpen: PropTypes.any.isRequired,
	setShowSchemaState: PropTypes.any.isRequired,
	updateDataFn: PropTypes.any.isRequired,
	validationStatus: PropTypes.any.isRequired,
	isEditable: PropTypes.any.isRequired,
	datasetId: PropTypes.any.isRequired,
	onViewSchemaClose: PropTypes.any.isRequired,
	setDatasetConfigClone: PropTypes.any.isRequired,
	clickedItem: PropTypes.any.isRequired,
	datasetConfig: PropTypes.any.isRequired,
	setTargetColumn: PropTypes.any.isRequired,
	filteredData: PropTypes.any.isRequired,
	datasetConfigClone: PropTypes.any.isRequired,
	// addValidationStatus: PropTypes.any.isRequired,
	setColumnNames: PropTypes.any.isRequired,
	changeNameStatus: PropTypes.any.isRequired,
};

const mapDispatchToProps = { updateData };

const RowComponent = ({
	obj,
	ind,
	isViewOnly,
	ViewSchemaDropDown,
	handleTypeUpdate,
	handleFormatUpdate,
	dataType,
	isEditable,
}) => {
	const classes = useStyles();
	const [dataFormat, setDataFormat] = useState([
		"text",
		"float",
		"YYYY-MM-DD",
		"string",
	]);

	const changeFormatList = (dataTypeValue) => {
		dataTypeValue = dataTypeValue.toLowerCase();
		if (dataTypeValue === "string") {
			setDataFormat(["text", "file path", "URL", "string"]);
		} else if (dataTypeValue === "decimal") {
			setDataFormat(["float", "integer", "bigInt", "number", "decimal"]);
		} else if (dataTypeValue === "boolean") {
			setDataFormat(["binary"]);
		} else if (dataTypeValue === "date") {
			setDataFormat(["YYYY-MM-DD", "DD-MM-YYYY", "MM-DD-YYYY"]);
		} else {
			setDataFormat(["No Options"]);
		}
	};

	useEffect(() => {
		changeFormatList(obj.dataType);
	}, [dataType, obj.dataType]);

	return (
		<TableRow key={ind} className={classes.tableRow} hover>
			<StyledTableCell style={{ width: "35%" }}>
				<div className="config-name">
					<span
						title={obj.columnName}
						style={{
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
							overflow: "hidden",
							cursor: "default",
						}}
					>
						{obj.columnName}
					</span>
				</div>
			</StyledTableCell>
			<StyledTableCell style={{ width: "32.5%" }}>
				{isViewOnly && !isEditable && (
					<div
						className="config-name"
						style={{
							textTransform: "capitalize",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
							overflow: "hidden",
							cursor: "default",
						}}
					>
						<span title={obj.dataType}>{obj.dataType}</span>
					</div>
				)}
				{(!isViewOnly || isEditable) && (
					<div className={ViewSchemaDropDown}>
						<Select
							labelId="demo-simple-select-standard-label"
							id="demo-simple-select-standard"
							value={obj.dataType}
							onChange={e => handleTypeUpdate(e, obj)}
							IconComponent={ExpandMoreIcon}
							className={classes.selectDatatype}
						>
							{dataTypeOptions.map(item => (
								<MenuItem
									value={item}
									key={item}
									style={{
										textTransform: "capitalize",
										fontFamily: "Hind Siliguri",
										fontSize: "14px",
										color: "#46596A",
									}}
								>
									{item}
								</MenuItem>
							))}
						</Select>
					</div>
				)}
			</StyledTableCell>
			<StyledTableCell style={{ width: "32.5%" }}>
				{isViewOnly && !isEditable && (
					<div
						className="config-name"
						style={{
							textTransform: "capitalize",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
							overflow: "hidden",
							cursor: "default",
						}}
					>
						<span title={obj.dataFormat}>{obj.dataFormat}</span>
					</div>
				)}
				{(!isViewOnly || isEditable) && (
					<div className={ViewSchemaDropDown}>
						<Select
							labelId="demo-simple-select-standard-label"
							value={obj.dataFormat !== null ? obj.dataFormat : obj.dataType}
							onChange={e => handleFormatUpdate(e, obj)}
							className={classes.selectDatatype}
							IconComponent={ExpandMoreIcon}
						>
							{dataFormat.map(item => (
								<CustomMenuItem
									value={item}
									key={item}
									style={{
										textTransform: "capitalize",
										fontFamily: "Hind Siliguri",
										fontSize: "14px",
										color: "#46596A",
									}}
								>
									{item}
								</CustomMenuItem>
							))}
						</Select>
					</div>
				)}
			</StyledTableCell>
		</TableRow>
	);
};
RowComponent.propTypes = {
	obj: PropTypes.any.isRequired,
	ind: PropTypes.any.isRequired,
	isViewOnly: PropTypes.any.isRequired,
	ViewSchemaDropDown: PropTypes.any.isRequired,
	handleTypeUpdate: PropTypes.any.isRequired,
	handleFormatUpdate: PropTypes.any.isRequired,
	dataType: PropTypes.any.isRequired,
	isEditable: PropTypes.any.isRequired,
};

const ViewSchema = ({
	datasetId = "",
	datasetConfig = {},
	datasetName = "",
	onViewSchemaClose,
	validationStatus = {},
	showSchemaState = true,
	setShowSchemaState,
	isViewOnly = true,
	updateData: updateDataFn,
	isEditable,
	clickedItem = {},
	type = "",
	detailsData,
	envType = "",
	// addValidationStatus = {},
	filterColumnSelected = [],
	setColumnNames,
	userRole,
	isUpdating,
	fromReport,
}) => {
	const initialSchemaData = isViewOnly
		? datasetConfig
		: {
			data: getKeyValueData(
				[validationStatus.data.datasetId],
				validationStatus.data.datasetSchema,
			),
			status: "success",
			message: "",
		};
	const dispatch = useDispatch();
	const classes02 = useStyles02();

	const [open, setOpen] = useState(false);
	const [tabValue, setTabValue] = useState("Schema");
	const [criteria, setCriteria] = useState({ searchText: "" });
	const [filteredData, setFilteredData] = useState([]);
	// const [searchedData, setSearchedData] = useState([filteredData]);
	const [targetColumn, setTargetColumn] = useState("");
	const [page, setPage] = useState(1);
	const [datasetConfigState, setDatasetConfigState] = useState(initialSchemaData);
	const [datasetConfigClone, setDatasetConfigClone] = useState([]);
	const [openInput, setOpenInput] = useState(false);
	const [inputValue, setInputValue] = useState(datasetName);

	const [changeNameStatus, setChangeNameStatus] = useState({
		status: "",
		data: null,
		message: "",
	});

	// const match = useRouteMatch();
	const { subtab } = useParams();

	const preState = useRef(datasetName);

	const handleInputValue = (event) => {
		const { value } = event.target;
		setInputValue(value);
	};

	// datasetConfig={...validationStatus}

	useEffect(() => {
		if (criteria.searchText) {
			const result = datasetConfigClone.filter(elem => (elem.columnName || "")
				.toLowerCase()
				.includes(criteria.searchText.toLowerCase()));
			setDatasetConfigClone(result);
		}
		//  else {
		// 	setDatasetConfigClone(filteredData);
		// }
	}, [criteria.searchText]);

	useEffect(() => {
		if (datasetId && isViewOnly && !fromReport && !isUpdating) {
			dispatch(getDatasetSchema({ datasetId }));
		} else if (datasetId && isViewOnly && fromReport && !isUpdating) {
			dispatch(getRunhistorySchema({ requestId: datasetId }));
		}
		setOpen(true);
	}, [datasetId]);

	useEffect(() => {
		setOpen(true);
	}, [showSchemaState]);

	useNonInitialEffect(() => {
		if (datasetConfig.status === "success") {
			setDatasetConfigState({ ...datasetConfig });
		}
	}, [datasetConfig]);

	useEffect(() => {
		if (
			datasetConfigState
			&& ((datasetConfigState.data && datasetConfigState.data[datasetId])
				|| datasetConfigState.status === "loading")
		) {
			if (datasetConfigState.status === "success") {
				setFilteredData([...datasetConfigState.data[datasetId]]);
				setDatasetConfigClone([...datasetConfigState.data[datasetId]]);
				const tempTargets = datasetConfigState.data[datasetId].filter(
					obj => obj.targetColumn,
				);

				if (tempTargets.length) {
					setTargetColumn(tempTargets[0].columnName);
				} else {
					setTargetColumn(null);
				}
			}
		}
	}, [datasetConfigState]);

	const onClose = () => {
		if (isViewOnly && !isUpdating) {
			onViewSchemaClose();
		} else {
			setShowSchemaState(false);
		}
		if (changeNameStatus.status === "success") {
			dispatch(getStandardDataset({}));
		}

		setOpen(false);
	};

	const handleInputOpen = () => {
		setOpenInput(!openInput);
	};

	const changeCriteria = (param, value) => {
		criteria[param] = value;
		setCriteria({ ...criteria });
		if (param === "searchText") {
			let modifiedData = null;
			const tempVal = filteredData || [];
			if (value && value.trim()) {
				modifiedData = tempVal.filter(obj => (obj.columnName || "").toLowerCase().includes(value.toLowerCase()));
			} else {
				modifiedData = tempVal.slice();
			}
			setDatasetConfigClone([...modifiedData]);
		}
		setPage(1);
	};

	const handleSchemaUpdate = (key, value, name) => {
		if (!name) {
			const allFalse = filteredData.map(el => ({
				...el,
				targetColumn: false,
			}));
			setFilteredData(allFalse);
			setDatasetConfigClone(allFalse);
		} else {
			const newObj = filteredData.find(el => el.columnName === name);
			const indObj = filteredData.findIndex(el => el.columnName === name);
			const notUpdatedRecordsLeft = filteredData.slice(0, indObj);
			const notUpdatedRecordsRight = filteredData.slice(indObj + 1);
			const updatedLeft = notUpdatedRecordsLeft.map(el => ({
				...el,
				targetColumn: false,
			}));
			const updatedRight = notUpdatedRecordsRight.map(el => ({
				...el,
				targetColumn: false,
			}));
			setFilteredData([
				...updatedLeft,
				{ ...newObj, [key]: value },
				...updatedRight,
			]);
			setDatasetConfigClone([
				...updatedLeft,
				{ ...newObj, [key]: value },
				...updatedRight,
			]);
		}
	};

	const handleChangeTab = (event, newValue) => {
		setTabValue(newValue);
	};

	const targetColumnOptions = () => {
		const arr = filteredData.filter(el => el.dataType !== "date");
		const arr2 = arr.map(el => el.columnName);
		return arr2;
	};

	const isLoading = datasetConfigState && datasetConfigState.status === "loading";

	const useSchemaStyles = makeDatasetSchemaStyles();
	const datasetSchemaClasses = useSchemaStyles();
	const { contentContainer, rulesContainer } = datasetSchemaClasses;

	const handleUpdateName = () => {
		const url = `${config.api.updateName}?datasetId=${datasetId}`;
		setChangeNameStatus({ ...changeNameStatus, status: "loading" });
		if (inputValue && inputValue !== preState) {
			fetch(url, {
				method: "PUT",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					datasetId,
					name: inputValue,
				}),
			}).then((response) => {
				response
					.json()
					.then((result) => {
						if (result.status.statusCode === "ratify-200") {
							preState.current = inputValue;
							setChangeNameStatus({
								...changeNameStatus,
								status: "success",
								message: result.status.statusMessage,
							});
							setOpenInput(false);
							// dispatch(getStandardDataset({}));
						} else {
							setChangeNameStatus({
								...changeNameStatus,
								status: "error",
								message: result.status.statusMessage,
							});
						}
					})
					.catch(() => {
						setInputValue(preState.current);
						setChangeNameStatus({
							...changeNameStatus,
							status: "error",
							message: "Something went wrong",
						});
					});
			});
		}
	};

	return (
		<Drawer anchor="right" open={open} onClose={onClose}>
			<div style={{ width: "50vw", padding: "10px" }}>
				<div className="dataset-schema-container">
					<div style={titleStyle}>
						{!openInput && (
							<>
								{changeNameStatus.status === "success" && (
									<span>{inputValue}</span>
								)}
								{changeNameStatus.status !== "success" && datasetName}
								{(userRole === "admin" && subtab === "standard" && !fromReport) && (
									<span
										className="material-icons-outlined"
										style={{ marginLeft: "5px", fontSize: "15px" }}
										onClick={handleInputOpen}
									>
										mode_edit
									</span>
								)}
							</>
						)}
						{openInput && changeNameStatus.status !== "loading" && (
							<>
								<div>
									<div
										style={{
											display: "flex",
											alignItems: "stretch",
											position: "relative",
										}}
									>
										<StyledInputBase
											style={{ width: "200px" }}
											value={inputValue}
											onChange={handleInputValue}
										/>
										<div
											style={{
												display: "flex",
												flexDirection: "column",
												justifyContent: "space-between",
												alignItems: "stretch",
												// height: "35px",
											}}
										>
											<span
												className="material-icons-outlined"
												style={{
													marginLeft: "5px",
													fontSize: "15px",
												}}
												onClick={() => {
													setOpenInput(false);
													setInputValue(preState.current);
												}}
											>
												close
											</span>

											{preState.current !== inputValue && (
												<span
													className="material-icons-outlined"
													style={{
														marginLeft: "5px",
														fontSize: "15px",
													}}
													onClick={handleUpdateName}
												>
													done
												</span>
											)}
										</div>
									</div>
									{changeNameStatus.status === "error" && (
										<span
											style={{
												position: "absolute",
												left: "12px",
												bottom: "-28%",
												fontSize: "10px",
												fontWeight: "500",
												color: "red",
											}}
										>
											{changeNameStatus.message}
										</span>
									)}
								</div>
							</>
						)}
						{changeNameStatus.status === "loading" && <Loader />}
					</div>

					<i
						className="material-icons-outlined drawer-cancel-icon"
						onClick={onClose}
					>
						cancel
					</i>
				</div>
				<TabContext value={tabValue}>
					<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
						<TabList
							onChange={handleChangeTab}
							aria-label="lab API tabs example"
							TabIndicatorProps={{
								children: <span className="MuiTabs-indicatorSpan" />,
							}}
							sx={tabListStyle}
						>
							<Tab sx={tabStyle} label="Schema" value="Schema" />
							<Tab sx={tabStyle} label="Details" value="Details" />
						</TabList>
					</Box>
					<TabPanel className={contentContainer} value="Schema">
						<div className="content-container">
							<div style={{ marginTop: "10px" }}>
								<div>
									<Filter
										criteria={criteria}
										changeCriteria={changeCriteria}
										width="100%"
									/>
								</div>
								<div
									style={{
										width: "100%",
										fontFamily: "Hind Siliguri",
										marginBottom: "25px",
										fontSize: "12px",
									}}
								>
									<div
										style={{
											display: "flex",
											alignItems: "center",
										}}
									>
										<SubHeading
											style={(titleStyle, { marginTop: "0px !important" })}
										>
											Target Column
										</SubHeading>
										<div className={`${rulesContainer} rulesSection`}>
											<span
												className="material-icons-outlined"
												style={{
													fontSize: "16px",
													margin: "5px 0px 0px 5px",
												}}
											>
												info
											</span>
											<div className="rules">
												<p
													style={{
														fontSize: "11px",
														margin: "0px !important",
													}}
												>
													Choose the column around which the dataset score will
													be calculated
												</p>
											</div>
										</div>
									</div>

									{(isViewOnly && !isEditable) ? (
										<input
											value={targetColumn}
											disabled
											style={{
												height: "32px",
												border: "0px",
												width: "50%",
												padding: "5px",
												cursor: "default",
											}}
											title="Target Column"
										/>
									) : (
										<Autocomplete
											disablePortal
											id="combo-box-demo"
											value={targetColumn}
											className={classes02.typographyStyle}
											options={targetColumnOptions()}
											style={{
												width: "50%",
												height: "28px",
												padding: "5px",
												fontFamily: "Hind Siliguri !important",
												fontSize: "12px !important",
											}}
											// className={classes.autocompleteStyle}
											onChange={(e, newValue) => {
												handleSchemaUpdate(
													"targetColumn",
													true,
													e.target.innerText,
												);
												setTargetColumn(newValue);
											}}
											getOptionLabel={option => option || ""}
											getOptionSelected={(option, value) => option === value}
											renderInput={param => (
												<StyledTextField
													{...param}
													className={classes02.typographyStyle}
													placeholder="Select Target Column"
													variant="standard"
												/>
											)}
										/>
									)}
								</div>
							</div>

							{isLoading && <Loader />}
							{datasetConfig.status === "loading" && <Loader />}
							{!isLoading
								&& !filteredData.length
								&& !datasetConfig.status === "loading" && (
								<div className="no-configs">
									<img src={noDataImage} alt="noDataImage" />
									<p className="no-data">
										<b>{messages.noColumnsFound}</b>
									</p>
								</div>
							)}
							{((!isLoading && !(datasetConfig.status === "loading")) && !!filteredData.length) && (
								<ViewSchemaRows
									rows={filteredData}
									setFilteredData={setFilteredData}
									setDatasetConfigClone={setDatasetConfigClone}
									datasetConfigClone={datasetConfigClone}
									setPage={setPage}
									page={page}
									isViewOnly={isViewOnly}
									setOpen={setOpen}
									setShowSchemaState={setShowSchemaState}
									updateDataFn={updateDataFn}
									validationStatus={validationStatus}
									isEditable={isEditable}
									datasetId={datasetId}
									clickedItem={clickedItem}
									onViewSchemaClose={onViewSchemaClose}
									datasetConfig={datasetConfig}
									setTargetColumn={setTargetColumn}
									filteredData={filteredData}
									// addValidationStatus={addValidationStatus}
									setColumnNames={setColumnNames}
									changeNameStatus={changeNameStatus}
								/>
							)}
						</div>
					</TabPanel>
					<TabPanel className={contentContainer} value="Details">
						<DetailsTab
							type={type}
							detailsData={detailsData}
							envType={envType}
							filterColumnSelected={filterColumnSelected}
						/>
					</TabPanel>
				</TabContext>
			</div>
		</Drawer>
	);
};

ViewSchema.propTypes = {
	datasetId: PropTypes.any.isRequired,
	datasetConfig: PropTypes.any.isRequired,
	datasetName: PropTypes.any.isRequired,
	onViewSchemaClose: PropTypes.any.isRequired,
	validationStatus: PropTypes.any.isRequired,
	isViewOnly: PropTypes.any.isRequired,
	showSchemaState: PropTypes.any.isRequired,
	setShowSchemaState: PropTypes.any.isRequired,
	updateData: PropTypes.any.isRequired,
	isEditable: PropTypes.any.isRequired,
	clickedItem: PropTypes.any.isRequired,
	type: PropTypes.any.isRequired,
	detailsData: PropTypes.any.isRequired,
	envType: PropTypes.any.isRequired,
	// addValidationStatus: PropTypes.any.isRequired,
	filterColumnSelected: PropTypes.any.isRequired,
	setColumnNames: PropTypes.any.isRequired,
	userRole: PropTypes.any.isRequired,
	isUpdating: PropTypes.any.isRequired,
	fromReport: PropTypes.any.isRequired,
};

const mapStateToProps = state => ({
	datasetConfig: state.dataset.datasetConfig || {},
	userRole: state.user.role || "",
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewSchema);
