/** @format */

import { Grid } from "@material-ui/core";
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import moment from "moment";
import SelectedFilterChip from "./SelectedFilterChip";

import "./index.scss";

const CssTextField = withStyles({
	root: {
		width: "100%",
		backgroundColor: "#f3f3f3",
		padding: "6px 10px",
		height: "24px",
		borderRadius: "4px",
		"& label.Mui-focused": {
			color: "green",
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "#dedede",
		},
		"& .MuiOutlinedInput-root": {
			backgroundColor: "white",
			"& fieldset": {
				borderColor: "#dedede",
			},
			"&:hover fieldset": {
				borderColor: "#dedede",
			},
			"&.Mui-focused fieldset": {
				border: "0.5px solid #dedede",
			},
		},
	},
})(TextField);
const StyledAutoComplete = withStyles(() => ({
	tag: {
		background: "#465a69",
		color: "#46596a !important",
		fontFamily: "Hind Siliguri !important",
	},
	paper: {
		fontFamily: "Hind Siliguri",
		fontSize: 13,
		color: "#46596a",
	},
}))(Autocomplete);

const useStyles = makeStyles(() => ({
	typographyStyle: {
		"& .MuiChip-root": {
			fontFamily: "Hind Siliguri",
			fontSize: 12,
		},
		"& .MuiInput-underline:after": {
			borderBottom: 0,
		},
		"& .MuiInput-underline:before": {
			borderBottom: 0,
			transition: "none",
		},
		"& .MuiInput-underline:hover:not(.Mui-disabled):before": {
			borderBottom: "0px solid #dedede !important",
		},
		"& .Mui-focused .MuiInput-underline": {
			borderBottom: 0,
		},
		"& .MuiAutocomplete-option": {
			fontFamily: "Hind Siliguri",
			fontSize: 12,
		},
		"& .MuiAutocomplete-paper": {
			fontFamily: "Hind Siliguri",
			fontSize: 6,
		},
		"& .MuiInputBase-input": {
			fontFamily: "Hind Siliguri",
			fontSize: 12,
			color: "#46596a",
		},
		"& .MuiOutlinedInput-root": {
			paddingTop: "0px !important",
			paddingBottom: "0px !important",
		},
		"& .MuiAutocomplete-popupIndicator": {
			"& span": {
				"& svg": {
					"& path": {
						d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')", // your svg icon path here
					},
				},
			},
		},
	},
	root: {
		"& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
			transform: "translate(34px, 20px) scale(1);",
			fontFamily: "Hind Siliguri",
			fontStyle: "normal",
			fontWeight: "400",
			fontSize: "5px",
			color: "#465a69",
			opacity: 0.5,
		},
	},
	select: {
		fontFamily: "Hind Siliguri !important",
		fontSize: "16px!important",
		fontStyle: "normal!important",
		fontWeight: "400!important",
	},
	comboOptions: {
		fontFamily: "Hind Siliguri",
		fontStyle: "normal",
		fontWeight: "400",
		fontSize: "16px",
		color: '#465A69',
	},
	popupIndicator: {
		"& span": {
			"& svg": {
				"& path": {
					d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')", // your svg icon path here
				},
			},
		},
	},
	inputRoot: {
		color: "#465a69",
		background: "#ededed",
		'&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
			paddingLeft: 26,
			fontFamily: 'Hind Siliguri',
			fontSize: '14px',
			fontStyle: 'normal',
			fontWeight: '500',
			lineHeight: '23px',
			letterSpacing: '0em',
			textAlign: 'left',
			color: '#465A69',
		},
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#fafafa",
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "#fafafa",
		},
		"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
			borderColor: "#fafafa",
		},
	},
}));

function getDatesForDateRange(range) {
	let dateRange = "";
	const epoch = {};
	switch (range) {
	case "Last 1 Month":
		dateRange = `${moment().subtract(1, 'months').format('D MMMM, YYYY')} - ${moment().format('D MMMM, YYYY')}`;
		epoch.startDate = moment().subtract(1, 'months').valueOf();
		break;
	case "Last 6 Months":
		dateRange = `${moment().subtract(6, 'months').format('D MMMM, YYYY')} - ${moment().format('D MMMM, YYYY')}`;
		epoch.startDate = moment().subtract(6, 'months').valueOf();
		break;
	case "Last 1 Year":
		dateRange = `${moment().subtract(12, 'months').format('D MMMM, YYYY')} - ${moment().format('D MMMM, YYYY')}`;
		epoch.startDate = moment().subtract(12, 'months').valueOf();
		break;
	case "Last 2 Years":
		dateRange = `${moment().subtract(24, 'months').format('D MMMM, YYYY')} - ${moment().format('D MMMM, YYYY')}`;
		epoch.startDate = moment().subtract(24, 'months').valueOf();
		break;
	default:
		break;
	}
	epoch.endDate = moment().valueOf();
	return { dateRange, epoch };
}


function FilterSelection({
	filterType,
	selectionFilterList,
	saveFilters,
	removeFilters,
	defaultSelection,
	isEditable,
}) {
	const classes = useStyles();
	const [selectionDisplayArray, setSelectionDisplayArray] = useState(defaultSelection || []);
	const [currentTimeRangeSelection, setCurrentTimeRangeSelection] = useState([]);
	const autoC = useRef(null);
	const filter = createFilterOptions();

	const removeFromSelectionArray = (deselction) => {
		if (deselction) {
			if (filterType === 'datasetTimeRange') {
				setSelectionDisplayArray([]);
				setCurrentTimeRangeSelection([]);
				removeFilters({ name: filterType, value: currentTimeRangeSelection[0] });
			} else {
				setSelectionDisplayArray(selectionDisplayArray.filter(f => f !== deselction));
				removeFilters({ name: filterType, value: deselction });
			}
		}
	};

	const selectChange = (selection) => {
		if (selection && selection !== "" && selection !== null) {
			if (filterType === "datasetTimeRange") {
				const dateRange = getDatesForDateRange(selection);
				saveFilters({
					name: filterType,
					value: selection,
					dateRange: dateRange.dateRange,
					epoch: dateRange.epoch,
				});
				setSelectionDisplayArray([dateRange.dateRange]);
				// remove from filter
				// get Currrent display value add it to
				if (currentTimeRangeSelection.length > 0) {
					setCurrentTimeRangeSelection([selection]);
				} else {
					setCurrentTimeRangeSelection([selection]);
				}
				saveFilters({
					name: filterType,
					value: selection,
					dateRange: dateRange.dateRange,
					epoch: dateRange.epoch,
				});
			} else {
				setSelectionDisplayArray([...selectionDisplayArray, selection]);
				saveFilters({ name: filterType, value: selection });
			}
		}
	};

	const getChips = () => selectionDisplayArray;

	useEffect(() => {
		if (defaultSelection) setSelectionDisplayArray(defaultSelection);
	}, []);

	return (
		<Grid className="container">
			<Grid style={
				{
					display: "flex",
					justifyContent: "space-between",
					paddingBottom: "5pt",
					alignItems: "center",
					marginTop: "5px",
				}
			}
			>
				<div className="selected-term">
					{`${filterType}:`}
					{' '}
				</div>


			</Grid>
			<div style={{ display: "flex", alignItems: "center" }}>
				{filterType === "datasetTimeRange" ? (
					<StyledAutoComplete
						id="share-select-user"
						size="small"
						style={{
							fontFamily: "Hind Siliguri !important",
							width: "200px",
							margin: "0px 20px",
						}}
						className={classes.typographyStyle}
						selectOnFocus
						clearOnBlur
						handleHomeEndKeys
						renderTags={() => null}
						options={selectionFilterList}
						ref={autoC}
						disabled={isEditable}
						getOptionLabel={option => option}
						value={null}
						onChange={(event, newValue) => {
							selectChange(newValue);
							const ele = autoC.current.getElementsByClassName(
								"MuiAutocomplete-clearIndicator",
							)[0];
							if (ele) ele.click();
						}}
						noOptionsText="No option"
						renderInput={params => (
							<CssTextField
								{...params}
								variant="standard"
								size="small"
								placeholder="Please select Filter"
								className={classes.typographyStyle}
							/>
						)}
					/>
				) : (
					<StyledAutoComplete
						id="share-select-user"
						size="small"
						style={{
							fontFamily: "Hind Siliguri !important",
							width: "200px",
							margin: "0px 20px",
						}}
						className={classes.typographyStyle}
						selectOnFocus
						clearOnBlur
						freeSolo
						handleHomeEndKeys
						renderTags={() => null}
						disabled={isEditable}
						options={selectionFilterList}
						ref={autoC}
						getOptionLabel={(option) => {
							if (option.title) {
								return option.title;
							}
							if (option.inputValue) {
								return option.inputValue;
							}
							return option || "";
						}}
						value={null}
						filterOptions={(options, params) => {
							const filtered = filter(options, params);
							// Suggest the creation of a new value
							if (params.inputValue !== "") {
								filtered.push({
									inputValue: params.inputValue,
									title: `Add "${params.inputValue}"`,
								});
							}
							return filtered;
						}}
						onChange={(event, newValue) => {
							if (newValue && newValue.inputValue) {
								// Create a new value from the user input
								selectChange(newValue.inputValue);
							} else {
								selectChange(newValue);
							}
							const ele = autoC.current.getElementsByClassName(
								"MuiAutocomplete-clearIndicator",
							)[0];
							if (ele) ele.click();
						}}
						noOptionsText="No option"
						renderInput={params => (
							<CssTextField
								{...params}
								variant="standard"
								size="small"
								placeholder="Please select Filter"
								className={classes.typographyStyle}
							/>
						)}
					/>
				)}
				<SelectedFilterChip chips={getChips()} handleDelete={removeFromSelectionArray} isEditable={isEditable} />
			</div>
		</Grid>
	);
}
FilterSelection.propTypes = {
	filterType: PropTypes.any.isRequired,
	selectionFilterList: PropTypes.any.isRequired,
	saveFilters: PropTypes.func.isRequired,
	removeFilters: PropTypes.func.isRequired,
	defaultSelection: PropTypes.array.isRequired,
	isEditable: PropTypes.array.isRequired,
};
export default FilterSelection;
