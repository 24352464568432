// Import Required Libraries
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// Import Pages
import Login from "../js/screens/Login/Login";
import Error from "../js/screens/Error/Error";
import PageNotFound from "../js/screens/PageNotFound/PageNotFound";
import AIDEVertical from "../js/screens/AIDEVeritcal/AIDEVertical";
import DatasetRegisterContainer from "../js/screens/RegisterDataset/RegisterDatasetContainer";
import AddDatasetContainer from "../js/screens/MyDataset/AddDatasetContainer";
import DummyComponent from "../js/screens/RegisterDataset/DummyComponent";

// Import Custom Components
import ProtectedRoute from "./ProtectedRoute";
import Dataset from "../components/Dataset";
import RatifyForm from "../components/RatifyForm";

function Routes() {
	return (
		<Switch>
			<Redirect from="/" to="/home" exact />
			<ProtectedRoute path="/error" component={Error} exact />
			<ProtectedRoute path="/home/:subtab" component={Dataset} exact />
			<ProtectedRoute path="/home/ratify/:datasetId/:runConfigId" component={RatifyForm} exact />
			<ProtectedRoute path="/home/ratify/" component={RatifyForm} />
			<ProtectedRoute path="/home" component={AIDEVertical} exact />
			<ProtectedRoute path="/home/datasets/edap_stg.igk_ekko" component={DummyComponent} exact />
			<ProtectedRoute path="/home/datasets/addnew" component={DatasetRegisterContainer} exact />
			<ProtectedRoute path="/home/datasets/addnew/:datasetId" component={DatasetRegisterContainer} exact />
			<ProtectedRoute path="/home/mydatasets/addnew" component={AddDatasetContainer} exact />
			<Route path="/login" component={Login} exact />
			<Route component={PageNotFound} />
		</Switch>
	);
}

export default Routes;
