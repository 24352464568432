import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { v4 } from "uuid";
import PropTypes from "prop-types";

function CustomFileInput(props) {
	const {
		accept = "",
		style = {},
		// label = "",
		onChange = () => {},
		disabled,
		children = <p>Default File Upload</p>,
		uploadState,
	} = props;
	const fileId = useRef(v4());

	const useStyles = makeStyles(() => ({
		root: {
			marginRight: style?.margin?.right || "0px",
			marginLeft: style?.margin?.left || "0px",
			position: "relative",
		},
		inputFile: {
			opacity: "0",
			width: "0.1px",
			height: "0.1px",
			position: "absolute",
			cursor: "pointer",
			"&:disabled": {
				cursor: "default",
			},
		},
		replace: {
			backgroundColor: "#465A69",
			borderRadius: "5px",
			padding: "7px 15px",
			color: "white",
			fontWeight: "500",
			fontSize: "12px",
			position: "absolute",
			right: 13,
			bottom: 11,
			margin: 0,
		},
		label: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			height: "max-content",
			width: "100%",
			boxSizing: "border-box",
			border: "1px dashed rgba(0, 0, 0, 0.3)",
			borderRadius: "3px",
			padding: "20px 20px",
			backgroundColor: "transparent",
			cursor: "pointer",
			"&:disabled": {
				cursor: "default",
			},
		},
	}));

	const classes = useStyles();

	const handleChange = (e) => {
		onChange(e, "select");
	};

	return (
		<div className={classes.root}>
			{uploadState !== "success" && uploadState !== "error" && (
				<label
					disabled={disabled}
					htmlFor={fileId.current}
					className={classes.label}
					onDragOver={(e) => {
						e.preventDefault();
					}}
					onDrop={(e) => {
						e.preventDefault();
						onChange(e, "drop");
					}}
				>
					<input
						type="file"
						id={fileId.current}
						className={classes.inputFile}
						accept={accept}
						onChange={e => handleChange(e)}
						disabled={disabled}
					/>
					{children}
				</label>
			)}
			{(uploadState === "success" || uploadState === "error") && (
				<>
					<div
						className={classes.label}
						onDragOver={(e) => {
							e.preventDefault();
						}}
						onDrop={(e) => {
							e.preventDefault();
							onChange(e, "drop");
						}}
						disabled={disabled}
					>
						{children}
						<div>
							<label htmlFor={fileId.current} disabled={disabled}>
								<input
									type="file"
									id={fileId.current}
									className={classes.inputFile}
									accept={accept}
									onChange={e => handleChange(e)}
									disabled={disabled}
								/>
								<p className={classes.replace}>Replace and Upload</p>
							</label>
						</div>
					</div>
				</>
			)}
		</div>
	);
}

CustomFileInput.propTypes = {
	accept: PropTypes.any.isRequired,
	// label: PropTypes.any.isRequired,
	onChange: PropTypes.any.isRequired,
	children: PropTypes.any.isRequired,
	style: PropTypes.any.isRequired,
	disabled: PropTypes.any.isRequired,
	uploadState: PropTypes.any.isRequired,
};

export default CustomFileInput;
