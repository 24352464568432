import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import RbLogo from "../RBLogo/RbLogo";
import Notifications from "../Notifications/Notifications";
import AllApps from "../AllApps/AllApps";
import UserInfo from "../UserInfo/UserInfo";

import { updateUserInfo } from "../../../actions";

import "./Header.scss";

function Header(props) {
	const { user, match } = props;
	return (
		<header className={user.theme}>
			<RbLogo match={match} />
			<section className="header-menu">
				{match?.params?.app && <Notifications match={match} />}
				<AllApps match={match} />
				<UserInfo {...props} />
			</section>
		</header>
	);
}

Header.propTypes = {
	user: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	user: state.user,
});

const mapDispatchToProps = {
	updateUserInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
