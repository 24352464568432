// Import required libraries
import React, { useState } from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";

// Import Libraries
import {
	withStyles,
	Select,
	Input,
	MenuItem,
	ListItemText,
	makeStyles,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { SubHeading, useStyles02 } from "../../../styles/js/Styles";
import TableColumnMapping from "../../../assets/data/TableColumnMapping.json";
import CustomFileInput from "../MyDataset/CustomFileInput";
import uploadFile from "../../../assets/images/ratify/upload-file.svg";
import addUploadFile from "../../../assets/images/ratify/addUpload-file.svg";

const StyledCheckbox = withStyles(() => ({
	root: {
		color: "#4eafb3 !important",
		"& .MuiSvgIcon-root": {
			width: 18,
			height: 18,
		},
		"&:hover": {
			backgroundColor: "transparent",
		},
	},
}))(Checkbox);

const makeRegisterDatasetFooterStyles = () => {
	const useStyles1 = makeStyles(() => ({
		mainContainer: {
			display: "flex",
			height: "calc(100% - 28px)",
			width: "100%",
			flexDirection: "column",
			justifyContent: "space-between",
			overflow: "auto",
			"& .commonMargin": {
				marginBottom: 20,
			},
		},
		registerDatasetContainer: {
			display: "flex",
			height: "100%",
			width: "100%",
			flexDirection: "column",
			overflow: "auto",
		},
		inputContainerClass: {
			display: "flex",
			"&:disabled": {
				backgroundColor: "#A4D4D7",
				cursor: "default",
			},
		},
		inputDatasetClass: {
			display: "flex",
			flexDirection: "column",
		},
		validateButtonClass: {
			"&:disabled": {
				backgroundColor: "#9F9F9F",
				cursor: "default",
			},
			backgroundColor: "#465A69",
			fontWeight: "700",
			fontFamily: "Hind Siliguri !important",
		},
		validateButtonContainer: {
			display: "flex",
			alignItems: "center",
		},
		footerButtonClass: {
			"&:disabled": {
				backgroundColor: "#A4D4D7",
				cursor: "default",
			},
			backgroundColor: "#4EAFB3",
			fontWeight: "700",
			fontFamily: "Hind Siliguri !important",
		},
		cancelBtn: {
			color: "#4eafb3",
			border: "1px solid #4eafb3",
			fontSize: "13px",
			padding: "0px 10px",
			fontFamily: "Hind Siliguri",
			textTransform: "none",
			marginRight: "10px",
			fontWeight: "700",
		},
		registerDatasetFooterContainer: {
			padding: "22px 0px",
		},
		leftFooterButtons: {
			marginRight: "20px",
		},
		footerButtonsContainer: {
			display: "flex",
			justifyContent: "flex-end",
		},
		leftButtonsContainer: {
			display: "flex",
			justifyContent: "end",
			width: "250px !important",
			alignItems: "flex-end",
			flexDirection: "column",
		},
		validatedText: {
			fontFamily: "Hind Siliguri !important",
			fontSize: "14px !important",
			fontWeight: "700",
		},
		uploadFileClass: {
			flexDirection: "column",
			width: "100%",
			alignItems: "center",
			minHeight: 150,
			justifyContent: "center",
			display: "flex",
			"& P": {
				margin: 0,
				padding: 0,
				color: "#4EAFB3",
				fontSize: 13,
			},
			"& .uploadImageContainer": {
				display: "flex",
				alignItems: "center",
				textAlign: "center",
				"& P": {
					margin: 0,
					padding: 0,
					color: "#4EAFB3",
					fontSize: 13,
				},
				"& .orContainer": {
					margin: "0  40px",
				},
				"& .imageContainer": {
					textAlign: "center",
				},
			},
			"& .loaderImageContainer": {
				textAlign: "center",
			},
			"& .loaderBox": {
				marginTop: 10,
				textAlign: "center",
				"& p": {
					marginTop: 10,
				},
			},
			"& .uploadedContainer": {
				display: "flex",
				marginTop: "30px",
				alignItems: "center",
			},
		},
		validateContainer: {
			"& .validateContentContainer": {
				border: "1px solid rgba(0, 0, 0, 0.3);",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				borderRadius: 5,
				padding: "10px 0",
				textAlign: "center",
				"& p": {
					margin: 0,
					padding: 0,
					color: "#4EAFB3",
					fontSize: 13,
				},
				"& span": {
					color: "#415A6C",
					fontWeight: 600,
					fontSize: 15,
				},
			},
		},
		schemaValidationContainer: {
			"& .schemaVerifyContainer": {
				"& p": {
					color: "#415A6C",
				},
				"& span": {
					fontWeight: 500,
					fontSize: 14,
					color: "#4EAFB3",
					textDecoration: "underline",
					cursor: "pointer",
				},
			},
		},
		validationSuccess: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			"& p": {
				color: "#4EAFB3 !important",
			},
		},
		rulesContainer: {
			display: "flex",
			alignItems: "end",
			position: "relative",

			"& span": {
				fontSize: 12,
				color: "#415A6C",
				fontWeight: 600,
				// textDecoration: "underline",
				cursor: "default",
			},
		},
	}));
	return useStyles1;
};

const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: 150,
			width: "auto",
		},
	},
	anchorOrigin: {
		vertical: "bottom",
		horizontal: "left",
	},
	transformOrigin: {
		vertical: "top",
		horizontal: "left",
	},
	variant: "menu",
	getContentAnchorEl: null,
};

function DummyDatasetRules(props) {
	const { tableName = "", disable = false, newMockyData = {} } = props;
	const classes = useStyles02();
	const columnOptionsObj = TableColumnMapping.find(
		elem => elem.tableName === tableName,
	);
	const columnOptions = columnOptionsObj?.columns || [];
	const [ruleData, setRuleData] = useState(newMockyData);
	const useRegisterFooterStyles = makeRegisterDatasetFooterStyles();
	const registerFooterClasses = useRegisterFooterStyles();
	const { uploadFileClass, rulesContainer } = registerFooterClasses;

	// Add empty test row
	const addTestLocalRow = (key) => {
		const newData = [
			...ruleData[key].column_details,
			{ column_name: [], reg_Ex: "", id: v4() },
		].flat();
		setRuleData({
			...ruleData,
			[key]: { ...ruleData[key], column_details: [...newData] },
		});
	};

	console.log(`ruleData`, ruleData);
	return (
		<div>
			<div style={{ display: "flex" }}>
				<SubHeading className="sub-heading">Select Rules</SubHeading>
				<p
					style={{
						fontSize: "12px",
						padding: "0px 20px ",
						color: "#4eafb3",
						textDecoration: "underline",
					}}
				>
					See Added Rule Summary
				</p>
			</div>
			<div
				className={`${!disable ? "disable-class" : ""}`}
				style={{ display: "flex", alignItems: "center" }}
			>
				<StyledCheckbox
					checked={ruleData.required_field.checked}
					onChange={(e, value) => {
						setRuleData({
							...ruleData,
							required_field: { ...ruleData.required_field, checked: value },
						});
					}}
				/>
				<p
					style={{
						fontSize: "14px",
						padding: "0px 20px 0px 0px",
						color: "#46596a",
						width: "320px",
					}}
				>
					Define columns that are required fields
				</p>
				<Select
					displayEmpty
					labelId="demo-mutiple-checkbox-label"
					id="demo-mutiple-checkbox"
					multiple
					value={ruleData.required_field.column_name}
					onChange={(e) => {
						setRuleData({
							...ruleData,
							required_field: {
								...ruleData.required_field,
								column_name: e.target.value,
							},
						});
					}}
					className={classes.multiSelect}
					renderValue={(selected) => {
						if (selected.length === 0) {
						  return <p>Choose columns</p>;
						}
						return selected.join(', ');
					  }}
					input={<Input />}
					MenuProps={MenuProps}
				>
					{columnOptions?.map(item => (
						<MenuItem key={item} value={item} className={classes.menuItem}>
							<StyledCheckbox
								checked={ruleData.required_field.column_name.indexOf(item) > -1}
							/>
							<ListItemText primary={item} className={classes.listItem} />
						</MenuItem>
					))}
				</Select>
			</div>
			<div
				className={`${!disable ? "disable-class" : ""}`}
				style={{ display: "flex", alignItems: "center" }}
			>
				<StyledCheckbox
					checked={ruleData.regular_expression.checked}
					onChange={(e, value) => {
						setRuleData({
							...ruleData,
							regular_expression: {
								...ruleData.regular_expression,
								checked: value,
							},
						});
					}}
				/>
				<p
					style={{
						fontSize: "14px",
						padding: "0px 20px 0px 0px",
						color: "#46596a",
						width: "320px",
					}}
				>
					Specify columns that should not have null values
				</p>
				<Select
					displayEmpty
					labelId="demo-mutiple-checkbox-label"
					id="demo-mutiple-checkbox"
					multiple
					value={ruleData.regular_expression.column_name}
					onChange={(e) => {
						setRuleData({
							...ruleData,
							regular_expression: {
								...ruleData.regular_expression,
								column_name: e.target.value,
							},
						});
					}}
					className={classes.multiSelect}
					renderValue={(selected) => {
						if (selected.length === 0) {
						  return <p>Choose columns</p>;
						}
						return selected.join(', ');
					  }}
					input={<Input />}
					MenuProps={MenuProps}
				>
					{columnOptions?.map(item => (
						<MenuItem key={item} value={item} className={classes.menuItem}>
							<StyledCheckbox
								checked={
									ruleData.regular_expression.column_name.indexOf(item) > -1
								}
							/>
							<ListItemText primary={item} className={classes.listItem} />
						</MenuItem>
					))}
				</Select>
			</div>
			<div
				className={`${!disable ? "disable-class" : ""}`}
				style={{ display: "flex", flexDirection: "column" }}
			>
				<div style={{ display: "flex", alignItems: "center" }}>
					<StyledCheckbox
						checked={ruleData.table_columns_to_match_ordered_list.checked}
						onChange={(e, value) => {
							setRuleData({
								...ruleData,
								table_columns_to_match_ordered_list: {
									...ruleData.table_columns_to_match_ordered_list,
									checked: value,
								},
							});
						}}
					/>
					<p
						style={{
							fontSize: "14px",
							padding: "0px 20px 0px 0px",
							color: "#46596a",
							width: "320px",
						}}
					>
						Column values must match Regular Expression
					</p>
				</div>
				{ruleData.table_columns_to_match_ordered_list.checked && (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							marginLeft: "40px",
						}}
					>
						{ruleData.table_columns_to_match_ordered_list.column_details.map(
							(elem, index) => {
								const handleChange = (key, value) => {
									const newData =										ruleData.table_columns_to_match_ordered_list.column_details.map(
										item => (item.id !== elem.id
											? item
											: {
												...item,
												[key]: [...value].flat(),
													  }),
									);
									setRuleData({
										...ruleData,
										table_columns_to_match_ordered_list: {
											...ruleData.table_columns_to_match_ordered_list,
											column_details: [...newData],
										},
									});
								};
								return (
									<div style={{ display: "flex", alignItems: "center" }}>
										<Select
											displayEmpty
											labelId="demo-mutiple-checkbox-label"
											id="demo-mutiple-checkbox"
											multiple
											value={elem.column_name}
											onChange={(e) => {
												handleChange("column_name", e.target.value);
											}}
											className={classes.multiSelect}
											renderValue={(selected) => {
												if (selected.length === 0) {
												  return <p>Choose columns</p>;
												}
												return selected.join(', ');
											  }}
											input={<Input />}
											MenuProps={MenuProps}
										>
											{columnOptions?.map(item => (
												<MenuItem
													key={item}
													value={item}
													className={classes.menuItem}
												>
													<StyledCheckbox
														checked={elem.column_name.indexOf(item) > -1}
													/>
													<ListItemText
														primary={item}
														className={classes.listItem}
													/>
												</MenuItem>
											))}
										</Select>
										<p
											style={{
												fontSize: "14px",
												padding: "0px 20px 0px 0px",
												color: "#46596a",
												marginLeft: "32px",
											}}
										>
											Should match
										</p>
										<div style={{ width: "28%" }}>
											<input
												type="text"
												value={elem.reg_Ex}
												className="input-text"
												onChange={(e) => {
													const newData =	ruleData.table_columns_to_match_ordered_list.column_details.map(
														item => (item.id !== elem.id
															? item
															: {
																...item,
																reg_Ex: e.target.value,
																	  }),
													);
													setRuleData({
														...ruleData,
														table_columns_to_match_ordered_list: {
															...ruleData.table_columns_to_match_ordered_list,
															column_details: [...newData],
														},
													});
												}}
												placeholder="Enter value"
											/>
										</div>
										{index
											=== ruleData.table_columns_to_match_ordered_list
												.column_details.length
												- 1 && (
											<p
												style={{
													fontSize: "14px",
													padding: "0px 20px 0px 0px",
													color: "#4eafb3",
													cursor: "pointer",
												}}
												onClick={() => {
													addTestLocalRow(
														"table_columns_to_match_ordered_list",
													);
												}}
											>
												Add New
											</p>
										)}
									</div>
								);
							},
						)}
					</div>
				)}
			</div>
			<div
				className={`${!disable ? "disable-class" : ""}`}
				style={{ display: "flex", flexDirection: "column" }}
			>
				<div style={{ display: "flex" }}>
					<StyledCheckbox
						checked={ruleData.column_values_to_not_be_null.checked}
						onChange={(e, value) => {
							setRuleData({
								...ruleData,
								column_values_to_not_be_null: {
									...ruleData.column_values_to_not_be_null,
									checked: value,
								},
							});
						}}
					/>
					<p
						style={{
							fontSize: "14px",
							padding: "0px 20px 0px 0px",
							color: "#46596a",
							width: "320px",
						}}
					>
						Define minimum value for specific column
					</p>
				</div>
				{ruleData.column_values_to_not_be_null.checked && (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							marginLeft: "40px",
						}}
					>
						{ruleData.column_values_to_not_be_null.column_details.map(
							(elem, index) => {
								const handleChange = (key, value) => {
									const newData =										ruleData.column_values_to_not_be_null.column_details.map(
										item => (item.id !== elem.id
											? item
											: {
												...item,
												[key]: [...value].flat(),
													  }),
									);
									setRuleData({
										...ruleData,
										column_values_to_not_be_null: {
											...ruleData.column_values_to_not_be_null,
											column_details: [...newData],
										},
									});
								};
								return (
									<div style={{ display: "flex", alignItems: "center", width: "100%" }}>
										<p
											style={{
												fontSize: "14px",
												padding: "0px 20px 0px 0px",
												color: "#46596a",
											}}
										>
											Minimum value for
										</p>
										<Select
											displayEmpty
											labelId="demo-mutiple-checkbox-label"
											id="demo-mutiple-checkbox"
											multiple
											value={elem.column_name}
											onChange={(e) => {
												handleChange("column_name", e.target.value);
											}}
											className={classes.multiSelect}
											renderValue={(selected) => {
												if (selected.length === 0) {
												  return <p>Choose columns</p>;
												}
												return selected.join(', ');
											  }}
											input={<Input />}
											MenuProps={MenuProps}
										>
											{columnOptions?.map(item => (
												<MenuItem
													key={item}
													value={item}
													className={classes.menuItem}
												>
													<StyledCheckbox
														checked={elem.column_name.indexOf(item) > -1}
													/>
													<ListItemText
														primary={item}
														className={classes.listItem}
													/>
												</MenuItem>
											))}
										</Select>
										<p
											style={{
												fontSize: "14px",
												padding: "0px 20px 0px 0px",
												color: "#46596a",
												marginLeft: "32px",
											}}
										>
											Should be
										</p>
										<div style={{ width: "28%" }}>
											<input
												type="text"
												value={elem.reg_Ex}
												className="input-text"
												onChange={(e) => {
													const newData =	ruleData.column_values_to_not_be_null.column_details.map(
														item => (item.id !== elem.id
															? item
															: {
																...item,
																reg_Ex: e.target.value,
																	  }),
													);
													setRuleData({
														...ruleData,
														column_values_to_not_be_null: {
															...ruleData.column_values_to_not_be_null,
															column_details: [...newData],
														},
													});
												}}
												placeholder="Enter value"
											/>
										</div>
										{index
											=== ruleData.column_values_to_not_be_null.column_details
												.length
												- 1 && (
											<p
												style={{
													fontSize: "14px",
													padding: "0px 20px 0px 0px",
													color: "#4eafb3",
													marginLeft: "32px",
													cursor: "pointer",
												}}
												onClick={() => {
													addTestLocalRow("column_values_to_not_be_null");
												}}
											>
												Add New
											</p>
										)}
									</div>
								);
							},
						)}
					</div>
				)}
			</div>
			<div
				className={`${!disable ? "disable-class" : ""}`}
				style={{ display: "flex", flexDirection: "column" }}
			>
				<div style={{ display: "flex" }}>
					<StyledCheckbox
						checked={ruleData.column_values_to_be_in_type_list.checked}
						onChange={(e, value) => {
							setRuleData({
								...ruleData,
								column_values_to_be_in_type_list: {
									...ruleData.column_values_to_be_in_type_list,
									checked: value,
								},
							});
						}}
					/>
					<p
						style={{
							fontSize: "14px",
							padding: "0px 20px 0px 0px",
							color: "#46596a",
							width: "320px",
						}}
					>
						Define datatype for specific column
					</p>
				</div>
				{ruleData.column_values_to_be_in_type_list.checked && (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							marginLeft: "40px",
						}}
					>
						{ruleData.column_values_to_be_in_type_list.column_details.map(
							(elem, index) => {
								const handleChange = (key, value) => {
									const newData =										ruleData.column_values_to_be_in_type_list.column_details.map(
										item => (item.id !== elem.id
											? item
											: {
												...item,
												[key]: [...value].flat(),
													  }),
									);
									setRuleData({
										...ruleData,
										column_values_to_be_in_type_list: {
											...ruleData.column_values_to_be_in_type_list,
											column_details: [...newData],
										},
									});
								};
								return (
									<div style={{ display: "flex", alignItems: "center" }}>
										<p
											style={{
												fontSize: "14px",
												padding: "0px 20px 0px 0px",
												color: "#46596a",
											}}
										>
											Datatype for
										</p>
										<Select
											displayEmpty
											labelId="demo-mutiple-checkbox-label"
											id="demo-mutiple-checkbox"
											multiple
											value={elem.column_name}
											onChange={(e) => {
												handleChange("column_name", e.target.value);
											}}
											className={classes.multiSelect}
											renderValue={(selected) => {
												if (selected.length === 0) {
												  return <p>Choose columns</p>;
												}
												return selected.join(', ');
											  }}
											input={<Input />}
											MenuProps={MenuProps}
										>
											{columnOptions?.map(item => (
												<MenuItem
													key={item}
													value={item}
													className={classes.menuItem}
												>
													<StyledCheckbox
														checked={elem.column_name.indexOf(item) > -1}
													/>
													<ListItemText
														primary={item}
														className={classes.listItem}
													/>
												</MenuItem>
											))}
										</Select>
										<p
											style={{
												fontSize: "14px",
												padding: "0px 20px 0px 0px",
												color: "#46596a",
												marginLeft: "32px",
											}}
										>
											Should be
										</p>
										<div style={{ width: "28%" }}>
											<Select
												displayEmpty
												value={elem.reg_Ex}
												onChange={(e) => {
													const newData =	ruleData.column_values_to_be_in_type_list.column_details.map(
														item => (item.id !== elem.id
															? item
															: {
																...item,
																reg_Ex: e.target.value,
																	  }),
													);
													setRuleData({
														...ruleData,
														column_values_to_be_in_type_list: {
															...ruleData.column_values_to_be_in_type_list,
															column_details: [...newData],
														},
													});
												}}
												className={classes.multiSelect}
												MenuProps={MenuProps}
											>
												<MenuItem disabled value="" className={classes.singleMenuItem}>
													Select option
												</MenuItem>
												{["Float", "Integer", "BigInt", "Double"]?.map(item => (
													<MenuItem
														key={item}
														value={item}
														className={classes.menuItem}
													>
														<ListItemText
															primary={item}
															className={classes.listItem}
														/>
													</MenuItem>
												))}
											</Select>
										</div>
										{index
											=== ruleData.column_values_to_be_in_type_list.column_details
												.length
												- 1 && (
											<p
												style={{
													fontSize: "14px",
													padding: "0px 20px 0px 0px",
													color: "#4eafb3",
													marginLeft: "32px",
													cursor: "pointer",
												}}
												onClick={() => {
													addTestLocalRow("column_values_to_be_in_type_list");
												}}
											>
												Add New
											</p>
										)}
									</div>
								);
							},
						)}
					</div>
				)}
			</div>
			<div
				className={`${!disable ? "disable-class" : ""}`}
				style={{ display: "flex", flexDirection: "column" }}
			>
				<div style={{ display: "flex" }}>
					<StyledCheckbox
						checked={ruleData.order_of_column.checked}
						onChange={(e, value) => {
							setRuleData({
								...ruleData,
								order_of_column: {
									...ruleData.order_of_column,
									checked: value,
								},
							});
						}}
					/>
					<p
						style={{
							fontSize: "14px",
							padding: "0px 20px 0px 0px",
							color: "#46596a",
							width: "320px",
						}}
					>
						Define order of columns for the table
					</p>
				</div>
				{ruleData.order_of_column.checked && (
					<div className="commonMargin" style={{ margin: "0px 35px" }}>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<SubHeading className="sub-heading">
								Upload your file here
							</SubHeading>
							<div className={`${rulesContainer} rulesSection`}>
								<span style={{ marginRight: "5px" }}>
									Rules for File Upload
								</span>
								<div className="rules">
									<p style={{ fontSize: "11px", margin: "0px !important" }}>
										To be discussed
									</p>
								</div>
							</div>
						</div>
						<CustomFileInput accept=".csv">
							<div className={`${uploadFileClass}`}>
								<div className="uploadImageContainer">
									<div className="imageContainer">
										<img src={uploadFile} alt="" />
										<p>Drag and Drop</p>
									</div>
									<div className="orContainer">or</div>
									<div className="imageContainer">
										<img src={addUploadFile} alt="" />
										<p>Upload from local</p>
									</div>
								</div>
							</div>
						</CustomFileInput>
					</div>
				)}
			</div>
		</div>
	);
}

DummyDatasetRules.propTypes = {
	tableName: PropTypes.any.isRequired,
	disable: PropTypes.bool.isRequired,
	newMockyData: PropTypes.any.isRequired,
};

export default DummyDatasetRules;
