import { createStore, applyMiddleware } from "redux";
import logger from "redux-logger";
import createSagaMiddleware from 'redux-saga';
import rootReducer from "../reducers";
import state from "./state";
import rootSaga from '../sagas';

const sagaMiddleware = createSagaMiddleware();

const storeFactory = applyMiddleware(sagaMiddleware, logger)(createStore)(rootReducer, state, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

sagaMiddleware.run(rootSaga);

export default storeFactory;
