// Import required libraries
import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

function ProtectedRoute(props) {
	const {
		path, component: Component, user, location,
	} = props;
	return user.isAuth ? (
		<Route
			path={path}
			render={ownProps => (
				<Component
					user={user}
					location={location}
					match={ownProps.match}
				/>
			)}
		/>
	) : (
		<Redirect to="/login" />
	);
}

ProtectedRoute.propTypes = {
	path: PropTypes.string.isRequired,
	user: PropTypes.object.isRequired,
	component: PropTypes.any.isRequired,
	location: PropTypes.any.isRequired,
};

const mapStateToProps = state => ({
	user: state.user,
});

export default connect(mapStateToProps)(ProtectedRoute);
