/** @format */

import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Modal from "@mui/material/Modal";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import Scheduler from './Scheduler';
import ErrorAlert from './ErrorAlert';
import Loader from "../../js/components/Loader/Loader";

// Import utils and data
import { getOrdinal } from "../../js/utils/utils";

import "./index.scss";

const Heading = withStyles({
	root: {
		fontFamily: "Hind Siliguri",
		fontStyle: "normal",
		fontWeight: "600",
		fontSize: "16px",
		lineHeight: "26px",
		color: "465A69",
	},
})(Typography);

const SubHeading = withStyles({
	root: {
		fontFamily: "Hind Siliguri !important",
		fontStyle: "normal !important",
		fontWeight: "600 !important",
		fontSize: "13px !important",
		lineHeight: "19px !important",
		color: "#465A69 !important",
		marginBottom: "7px !important",
		marginTop: "13px !important",
		paddingLeft: "2px !important",
	},
})(Typography);

const Linker = withStyles({
	root: {
		fontFamily: "Hind Siliguri",
		fontStyle: "normal",
		fontWeight: "400",
		fontSize: "12px",
		lineHeight: "26px",
		color: "#46596a !important",
		textDecorationColor: "#6646596a !important",
	},
})(Link);

const useStyles = makeStyles({
	root: {
		width: "35px",
		height: "35px",
		background: "#ECECEC",
		borderRadius: "3px",
		margin: "1.5px",
		textTransform: "capitalize",
		fontFamily: "Hind Siliguri",
	},
	noBorder: {
		border: "none",
	},
	inputTextval: {
		fontFamily: "Hind Siliguri!important",
		fontSize: "12px!important",
		fontStyle: "normal!important",
		fontWeight: "500!important",
		lineHeight: "19px!important",
		letterSpacing: "0em!important",
		textAlign: "left!important",
		background: "#EDEDED!important",
		paddinLeft: "10px!important",
		color: "#465A69!important",
		borderRadius: "5px!important",
		padding: "5px 12px 5px 12px!important",
	},
});

const popUpWindowsStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "50%",
	background: "#FFFFFF",
	borderRadius: "10px",
	marginBottom: "20px",
};

export const scheduleRatifyRequest = (payload) => {
	const url = "/api/v1/schedule";
	return fetch(url, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(payload),
	});
};

function SchedulerModel({
	dataSetDetails, role, item, showDatasetDetails, width, value,
}) {
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const [frequency, setFrequency] = useState("Weekly");
	const [frequencyValue, setFrequencyValue] = useState("Mon");
	const [triggerTime, setTriggerTime] = useState("09:00");
	const [triggerTimeZone, setTriggerTimeZone] = useState("UTC");
	const [alert, setAlert] = useState(false);
	const [messageAlert, setAlertMessage] = useState(false);
	const [waiting, setWaiting] = useState(false);

	const handleAlertClose = () => {
		setAlert(false);
	};
	const classes = useStyles();

	const updateDataSetDetails = () => {
		showDatasetDetails(item);
	};
	const triggerOrScheduleRatify = () => {
		const data = {};
		Object.defineProperties(data, {
			datasetId: {
				value: dataSetDetails?.datasetId,
				enumerable: true,
			},
			runConfigName: {
				value: dataSetDetails?.runConfigName,
				enumerable: true,
			},
			datasetFilters: {
				value: dataSetDetails.datasetFilters,
				enumerable: true,
			},
			datasetTimeRange: {
				value: dataSetDetails?.datasetTimeRange,
				enumerable: true,
			},
			timeRange: {
				value: dataSetDetails?.timeRange,
				enumerable: true,
			},
			frequency: {
				value: frequency,
				enumerable: true,
			},
			triggerDay: {
				value: (frequency === "Weekly" || frequency === "Monthly") ? frequencyValue : "",
				enumerable: true,
			},
			triggerDate: {
				value: (frequency === "Weekly" || frequency === "Monthly") ? frequencyValue : "",
				enumerable: true,
			},
			triggerTime: {
				value: triggerTime,
				enumerable: true,
			},
			triggerTimezone: {
				value: triggerTimeZone,
				enumerable: true,
			},
			isScheduled: {
				value:
					role === "user" ? "pending" : role === "admin" ? "scheduled" : "pending",
				enumerable: true,
			},
		});
		setWaiting(true);
		scheduleRatifyRequest(data).then((response) => {
			response.json().then((result) => {
				setWaiting(false);
				if (result.status.statusCode === "ratify-200") {
					setAlertMessage(result.status.statusMessage);
					setAlert(true);
					setOpen(false);
					updateDataSetDetails();
				} else {
					setAlertMessage(result.status.statusMessage);
					setAlert(true);
					setOpen(false);
					updateDataSetDetails();
				}
			}).catch(() => {
				setWaiting(false);
				setAlertMessage("Server error occured!");
				setAlert(true);
				setOpen(false);
			});
		});
	};

	const getDisplayFilter = () => {
		const filterValue = dataSetDetails.datasetFilters.map(f => f.value);
		// filterValue.push(dataSetDetails?.timeRange);
		const filters = filterValue.join(" ");
		return filters;
	};

	const displpaySchedule = (datasetDetails) => {
		if (datasetDetails.isScheduled === "pending") return <Linker onClick={handleOpen} style={{ cursor: "pointer" }}> View Request </Linker>;
		if (datasetDetails.isScheduled === "ondemand") return <Linker onClick={handleOpen} style={{ cursor: "pointer" }}> Schedule Now </Linker>;
		if (datasetDetails?.schedulingDetails?.frequency === "Monthly"
			&& datasetDetails?.schedulingDetails?.triggerDate === "EOM") {
			return "EOM";
		}
		return (
			<p className="info-text">
				{
					getOrdinal(datasetDetails?.schedulingDetails?.frequency,
						datasetDetails?.schedulingDetails?.triggerDate,
						datasetDetails?.schedulingDetails?.triggerDay)
				}
			</p>
		);
	};


	return (
		<div>
			{displpaySchedule(dataSetDetails)}
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={popUpWindowsStyle}>
					<Box
						display="flex"
						justifyContent="space-between"
						flexDirection="column"
						height="100%"
					>
						<Grid className="main-container">
							<ErrorAlert
								message={messageAlert}
								open={alert}
								handleClose={handleAlertClose}
							/>
							<Heading className="main-heading">Schedule</Heading>
							<div className="scheduler-detils-first-row">
								<div className="scheduler-sub-detil-row">
									<SubHeading className="sub-heading">Subset Name</SubHeading>
									<Grid xs={8} className="input-small-width">
										<InputBase
											value={dataSetDetails.runConfigName}
											fullWidth
											size="small"
											disabled
											className={classes.inputTextval}
										/>
									</Grid>
								</div>
								<div className="scheduler-sub-detil-row pl">
									<SubHeading className="sub-heading">Dataset Name</SubHeading>
									<Grid xs={8} className="input-small-width">
										<InputBase
											value={dataSetDetails?.datasetName}
											fullWidth
											size="small"
											disabled
											className={classes.inputTextval}
										/>
									</Grid>
								</div>
							</div>
							<SubHeading className="sub-heading">Filter</SubHeading>
							<Grid xs={8} className="input-full-width">
								<InputBase
									value={getDisplayFilter()}
									fullWidth
									size="small"
									disabled
									className={classes.inputTextval}
								/>
							</Grid>
							<Grid
								container
								direction="row"
								justifyContent="center"
								alignItems="flex-start"
							>
								<Grid item xs={12}>
									<SubHeading className="sub-heading">
										Scoring Frequency
									</SubHeading>
								</Grid>
								<Grid item xs={12}>
									<Scheduler
										setFrequency={setFrequency}
										setFrequencyValue={setFrequencyValue}
										setTriggerTime={setTriggerTime}
										setTriggerTimeZone={setTriggerTimeZone}
										width={width}
										defaultValues={dataSetDetails?.schedulingDetails}
										value={value}
										isModle
										size={4}
										triggerTime={triggerTime}
									/>
								</Grid>
							</Grid>
						</Grid>

						<Box
							m="28px"
							display="flex"
							alignItems="center"
							justifyContent="flex-end"
						>
							<Button className="cancle-button" onClick={handleClose}>
								Cancel
							</Button>
							<>
								{(waiting) && <Loader />}
								<Button
									onClick={triggerOrScheduleRatify}
									className="scheduler-button"
								>
									{role === "user"
										? "Raise Request"
										: role === "admin"
											? "Schedule Dataset"
											: ""}
								</Button>
							</>
						</Box>
					</Box>
				</Box>
			</Modal>
		</div>
	);
}
SchedulerModel.propTypes = {
	role: PropTypes.any.isRequired,
	dataSetDetails: PropTypes.any.isRequired,
	showDatasetDetails: PropTypes.any.isRequired,
	item: PropTypes.any.isRequired,
	width: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

const mapStateToProps = state => ({
	role: state.user.role,
});
export default connect(mapStateToProps)(SchedulerModel);
