import axios from "./axios";
import {
	DATASET_DETAILS_URL_MOCKY,
	DATASET_SCHEMA_URL,
	DATASET_SCHEMA_URL_MOCKY,
	DATASET_URL,
	DATASET_URL_MOCKY,
	RUN_HISTORY_URL,
	useMocky,
	DATASET_REGISTER_URL,
	VALIDATION_CONFIG_URL,
	REGISTER_CONFIG_URL,
	MY_DATASET_URL,
	UPLOAD_FILE_URL,
	DOWNLOAD_URL,
	RUNHISTORY_SCHEMA_URL,
} from "./constants";

/* Users APIs */
export const getStandardDataset = () => axios(useMocky ? `${DATASET_URL_MOCKY}` : `${DATASET_URL}`, "GET");

export const getDataSetDetails = params => axios(
	useMocky ? `${DATASET_DETAILS_URL_MOCKY}` : `${DATASET_URL}`,
	"GET",
	params,
);

export const getRunHistoryDetails = params => axios(
	useMocky ? `${DATASET_DETAILS_URL_MOCKY}` : `${RUN_HISTORY_URL}`,
	"GET",
	params,
);

export const getRunHistoryList = params => axios(
	useMocky ? `${DATASET_DETAILS_URL_MOCKY}` : `${RUN_HISTORY_URL}`,
	"GET",
	params,
);

export const fetchConfView = params => axios(
	useMocky ? `${DATASET_SCHEMA_URL_MOCKY}` : `${DATASET_SCHEMA_URL}`,
	"GET",
	params,
);

export const getRunHistoryConfView = params => axios(
	`${RUNHISTORY_SCHEMA_URL}`,
	"GET",
	params,
);

export const getMyDataset = () => axios(`${MY_DATASET_URL}`, "GET");
// export const getMyDataset = () => axios(`${MOCKY_MYDATASET_URL}`, "GET");

export const getRegisterDatasetData = () => axios(`${DATASET_REGISTER_URL}`, "GET");

export const getValidationStatus = data => axios(`${VALIDATION_CONFIG_URL}`, "POST", null, data);

export const getAddValidationStatus = data => axios(`${VALIDATION_CONFIG_URL}`, "POST", null, data);

export const getRegisterStatus = data => axios(`${REGISTER_CONFIG_URL}`, "POST", null, data);

export const getAddDatasetStatus = data => axios(`${REGISTER_CONFIG_URL}`, "POST", null, data);

export const getUploadStatus = data => axios(`${UPLOAD_FILE_URL}`, "POST", null, data);

export const getDownloadUrlStatus = data => axios(`${DOWNLOAD_URL}`, "POST", null, data);
