import React, { useState } from "react";
import PropTypes from "prop-types";

import Popover from "@material-ui/core/Popover";

import AppRow from "../AppRow/AppRow";

import AllAppsData from "../../../assets/data/AllApps.json";

function AllApps({ match }) {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	return (
		<div className="all-apps-container">
			<i
				className="material-icons-outlined header-menu-icon"
				onClick={handleClick}
			>
        apps
			</i>
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
			>
				<AppRow
					match={match}
					data={AllAppsData}
					popOverFlag={false}
					allAppsFlag
					customClassName="all-apps"
				/>
			</Popover>
		</div>
	);
}

AllApps.propTypes = {
	match: PropTypes.object.isRequired,
};

export default AllApps;
