/** @format */

import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Box } from "@mui/system";
import moment from "moment";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { isArray } from "lodash";
import FilterSelection from "./FilterSelection";
import DatasetName from "./DatasetName";
import LayoutTopSideBottom from "../../js/layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import Breadcrumbs from "../../js/components/Breadcrumbs/Breadcrumbs";
import Scheduler from './Scheduler';
import ErrorAlert from './ErrorAlert';
import RatificationType from './RatificationType';
import { formatDate } from "../../utils";

import SubsectionName from "./SubsectionName";
import Loader from "../../js/components/Loader/Loader";
import TriggerModel from './TriggerModel';
import SubHeading from './SubHeading';
import "./index.scss";
import config from '../../js/config/config';


function fetchFilter(datasetId) {
	return fetch(`/api/v1/filters?datasetId=${datasetId}`);
}

export const scheduleRatifyRequest = (payload, isEditable) => {
	const url = config.api.scheduleUrl;
	return fetch(url, {
		method: isEditable ? 'PUT' : 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(payload),
	});
};

export const ratifyNowRequest = (payload) => {
	const url = config.api.triggerRatifyUrl;
	return fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(payload),
	});
};

function getGetDateRangeFilter(filters) {
	const dateRange = filters.filter(f => f.name === "datasetTimeRange");
	return dateRange.length > 0 ? dateRange[0] : [];
}

function getFilterForAPI(filters, isEditable) {
	const names = filters.map(f => f.name).filter(f => f !== "datasetTimeRange").filter((item, i, ar) => ar.indexOf(item) === i);
	const formatedFilter = names.map((name) => {
		const filterObj = Object.create(null);
		filterObj.name = name;
		if (isEditable) {
			const data = filters.filter(f => f.name === name);
			filterObj.value = data[0]?.value;
		} else {
			filterObj.value = filters.filter(f => f.name === name).map(f => f.value);
		}
		return filterObj;
	});
	return formatedFilter;
}

function RatifyForm({ role, datasetDetails, match }) {
	const initialSchedule = {
		frequency: "Weekly",
		triggerDay: "Mon",
		triggerTime: "09:00",
	};

	const initialState = {
		selectionFilterList: {},
		filterFormList: [],
		filterValues: [],
		filtersDetails: {},
		subSectionName: undefined,
		ratifyType: "ondemand",
		schedule: {},
		// schedule: initialSchedule,
		dataset: {},
		baseDependentFilter: undefined,
	};

	const [isSubsectionUnique, setSubsectionUnique] = useState(false);
	// filter handling states
	// List of Filter form
	const [runDetails, setRunDetails] = useState({
		datasetId: "",
		runConfigId: "",
		data: {},
	});
	const [ratifyState, setRatifyState] = useState(initialState);
	// Filter Loading flag
	const [filterLoading, setFilterLoading] = useState(false);
	const [frequency, setFrequency] = React.useState();
	const [frequencyValue, setFrequencyValue] = React.useState();
	const [triggerTime, setTriggerTime] = React.useState();
	const [triggerTimeZone, setTriggerTimeZone] = React.useState("");
	const [defaultName, setDefaultName] = useState("");
	const [userDefaultName, setUserDefaultName] = useState("");


	// Messinging, redirect and alerts
	const [messageAlert, setAlertMessage] = React.useState(false);
	const [alert, setAlert] = React.useState(false);
	const [openTrigger, setOpenTrigger] = React.useState(false);
	const [waiting, setWaiting] = React.useState(false);
	const [redirect, setRedirect] = React.useState(false);

	const { datasetId, runConfigId } = useParams();
	const history = useHistory();
	const allowedDendency = ["country_name", "brand_name"];
	const isEditable = Boolean(datasetId);
	const formatedFilter = getFilterForAPI(ratifyState.filterValues, isEditable);
	const dateRangeObj = getGetDateRangeFilter(ratifyState.filterValues);
	const timeRangeText = dateRangeObj?.value;
	const timeRangeEpoch = dateRangeObj?.epoch;
	const getIsScheduled = () => {
		let isScheduled = "";
		if (ratifyState.ratifyType === "scheduled" && role === "user") {
			isScheduled = "pending";
		} else if (((ratifyState.ratifyType === "scheduled" || isEditable) && role === "admin")) {
			isScheduled = "scheduled";
		} else if (ratifyState.ratifyType === "ondemand") {
			isScheduled = "ondemand";
		}
		return isScheduled;
	};

	const getButtonName = () => {
		let name = "";
		if (isEditable && role === "admin" && ratifyState.ratifyType === "scheduled") {
			name = "Update Subset";
		} else if (ratifyState.ratifyType === "scheduled" && role === "user") {
			name = "Raise Request";
		} else if (ratifyState.ratifyType === "scheduled" && role === "admin") {
			name = "Schedule Subset";
		} else if (ratifyState.ratifyType === "ondemand") {
			name = "Ratify Now";
		}
		return name;
	};


	const triggerOrScheduleRatify = () => {
		const isOKToSchedule = ratifyState.dataset // dataset validation
			&& (ratifyState.subSectionName?.length
				&& (isSubsectionUnique || defaultName === `${ratifyState?.dataset?.datasetName}-${ratifyState.subSectionName}`));// name validation
		if (!isOKToSchedule) {
			setAlert(true);
			setAlertMessage("Please provide all the details.");
			return;
		}
		if (!(formatedFilter && formatedFilter.length > 0)) { // filter validation
			setAlert(true);
			setAlertMessage("Please provide atleast one column filter to proceed.");
			return;
		}
		const data = {};
		Object.defineProperties(data, {
			datasetId: {
				value: ratifyState.dataset.datasetId,
				enumerable: true,
			},
			runConfigName: {
				value: `${ratifyState?.dataset?.datasetName}-${ratifyState.subSectionName}`,
				enumerable: true,
			},
			datasetFilters: {
				value: formatedFilter,
				enumerable: true,
			},
			datasetTimeRange: {
				value: timeRangeEpoch || { startDate: 0, endDate: moment().valueOf() },
				enumerable: true,
			},
			timeRange: {
				value: timeRangeText || "fullRange",
				enumerable: true,
			},
			frequency: {
				value: ratifyState.ratifyType === "scheduled" ? frequency : undefined,
				enumerable: true,
			},
			triggerDay: {
				value: ratifyState.ratifyType === "scheduled" ? frequencyValue : undefined,
				enumerable: true,
			},
			triggerDate: {
				value: ratifyState.ratifyType === "scheduled" ? frequencyValue : undefined,
				enumerable: true,
			},
			triggerTime: {
				value: ratifyState.ratifyType === "scheduled" ? triggerTime : undefined,
				enumerable: true,
			},
			triggerTimezone: {
				value: ratifyState.ratifyType === "scheduled" ? triggerTimeZone : undefined,
				enumerable: true,
			},
			isScheduled: {
				value: getIsScheduled(),
				enumerable: true,
			},
			forceRunRequired: {
				value: ratifyState.ratifyType === "ondemand" ? false : undefined,
				enumerable: true,
			},
		});
		setWaiting(true);
		if (ratifyState.ratifyType === "ondemand") {
			ratifyNowRequest(data).then((response) => {
				response.json().then((result) => {
					setWaiting(false);
					if (result.status.statusCode === "ratify-200") {
						setAlertMessage(result.status.statusMessage);
						setAlert(true);
						setRedirect(true);
					} if (result.status.statusCode === "ratify-5002") {
						setUserDefaultName(result?.runConfigName);
						setRunDetails({
							datasetId: ratifyState.dataset.datasetId,
							runConfigId: result.runConfigId,
							data,
						});
						setOpenTrigger(true);
					} else {
						setAlertMessage(result.status.statusMessage);
						setAlert(true);
					}
				}).catch(() => {
					setWaiting(false);
					setAlertMessage("Server error occured!");
					setAlert(true);
				});
			});
		} else {
			scheduleRatifyRequest(data, isEditable).then((response) => {
				response.json().then((result) => {
					setWaiting(false);
					if (result.status.statusCode === "ratify-200") {
						setAlert(true);
						setAlertMessage(result.status.statusMessage);
						setRedirect(true);
					} else {
						setAlert(true);
						setAlertMessage(result.status.statusMessage);
					}
				}).catch(() => {
					setWaiting(false);
					setAlertMessage("Server error occured!");
					setAlert(true);
				});
			});
		}
	};
	const getFilter = (filterType, filters) => {
		let selectedFilter = [];
		const availableFiltersCombinations = filters?.datasetFilterCombinations;
		if (filterType) {
			const filterCombination = availableFiltersCombinations?.filter(
				availableFilters => availableFilters.id === filterType,
			);

			if (filterCombination?.length > 0) {
				selectedFilter = filterCombination[filterCombination.length - 1];
			}
		}
		return selectedFilter;
	};

	const getFilterListBasedOnFilterType = (filterType, filters) => {
		let filter = getFilter(filterType, filters);
		if (filter.id === "datasetTimeRange") {
			filter = filter.values;
		} else {
			filter = Object.keys(filter.values);
		}
		return filter;
	};

	const getDependentFilter = (filterName, filterValuesSelected) => {
		const selectedObject = getFilter(filterName, ratifyState.filtersDetails);
		let dependentFilters = [];
		filterValuesSelected.forEach((element) => {
			const filterArray = selectedObject.values[element.value];
			dependentFilters = isArray(filterArray) ? [...dependentFilters, ...filterArray] : [...dependentFilters];
		});
		return [...new Set(dependentFilters)].sort();
	};

	const updateSelectionAndDependentFilter = (filterTypes, selectionFilter, filterValues, setBase) => {
		const deepClone = JSON.parse(JSON.stringify(ratifyState));
		filterTypes.forEach((filterType) => {
			deepClone.selectionFilterList[filterType] = selectionFilter[filterType].sort();
		});
		deepClone.filterValues = filterValues;
		if (setBase.set) {
			deepClone.baseDependentFilter = setBase.base;
		}
		setRatifyState(deepClone);
	};

	const saveSelectedFilters = (filter) => {
		let updatedFilterValues = [];
		const filterTypes = [];
		const valuesToSet = {};
		const setBase = { base: filter.name, set: false };
		filterTypes.push(filter.name);
		if (filter.name === "datasetTimeRange") {
			const newFilterValues = ratifyState.filterValues.filter(f => f.name !== "datasetTimeRange");
			updatedFilterValues = [...newFilterValues, filter];
			const valuesToRemove = updatedFilterValues.map(f => f.value);
			const baseFilters = getFilterListBasedOnFilterType(filter.name, ratifyState.filtersDetails);
			valuesToSet[filter.name] = baseFilters.filter(el => !valuesToRemove.includes(el));
		} else if (allowedDendency.filter(f => f === filter.name).length === 0) {
			updatedFilterValues = [...ratifyState.filterValues, filter];
			const currentValues = ratifyState.selectionFilterList[filter.name];
			const valuesToRemove = updatedFilterValues.map(f => f.value);
			valuesToSet[filter.name] = currentValues.filter(el => !valuesToRemove.includes(el));
		} else {
			const formList = ratifyState.filterFormList.filter(f => f !== "datasetTimeRange");
			let dependentForms = [];
			if (!ratifyState.baseDependentFilter) {
				dependentForms = formList.filter(f => f !== filter.name && allowedDendency.findIndex(df => df === f) !== -1);
				setBase.set = true;
			} else {
				dependentForms = formList.filter(f => f !== ratifyState.baseDependentFilter && allowedDendency.findIndex(df => df === f) !== -1);
			}
			updatedFilterValues = [...ratifyState.filterValues, filter];
			const valuesToRemove = updatedFilterValues.map(f => f.value);
			const currentValues = ratifyState.selectionFilterList[filter.name];
			valuesToSet[filter.name] = currentValues.filter(el => !valuesToRemove.includes(el));
			// On chnage of baseDependentFilter filter values
			// we shall update dependent filter vlaue

			dependentForms.forEach((dependentForm) => {
				if (!ratifyState.baseDependentFilter || ratifyState.baseDependentFilter === filter.name) {
					const filterBaseArray = ratifyState.baseDependentFilter ? updatedFilterValues.filter(f => f.name === ratifyState.baseDependentFilter) : updatedFilterValues.filter(f => f.name === filter.name);
					const existingDepenedentFilters = updatedFilterValues.filter(f => f.name === dependentForm).map(f => f.value);
					const dependentFormFilters = getDependentFilter(filter.name, filterBaseArray);
					valuesToSet[dependentForm] = dependentFormFilters.filter(f => !existingDepenedentFilters.includes(f));
					filterTypes.push(dependentForm);
				}
			});
		}
		updateSelectionAndDependentFilter(filterTypes, valuesToSet, updatedFilterValues, setBase);
	};

	const removeFilters = (filter) => {
		const filterTypes = [];
		const valuesToSet = {};
		filterTypes.push(filter.name);
		const setBase = { base: undefined, set: false };

		const newFilterValues = ratifyState.filterValues.filter(f => f.value !== filter.value);
		if (filter.name === "datasetTimeRange" && allowedDendency.filter(f => f === filter.name).length === 0) {
			valuesToSet[filter.name] = getFilterListBasedOnFilterType(filter.name, ratifyState.filtersDetails);
		} else if (allowedDendency.filter(f => f === filter.name).length === 0) {
			valuesToSet[filter.name] = [filter.value];
		} else {
			const formList = ratifyState.filterFormList.filter(f => f !== "datasetTimeRange");
			let dependentForms = [];
			if (!ratifyState.baseDependentFilter) {
				dependentForms = formList.filter(f => f !== filter.name && allowedDendency.findIndex(df => df === f) !== -1);
			} else {
				dependentForms = formList.filter(f => f !== ratifyState.baseDependentFilter && allowedDendency.findIndex(df => df === f) !== -1);
			}
			const filterWithoutDateRange = newFilterValues.filter(f => f.name !== "datasetTimeRange");
			if (filterWithoutDateRange.length === 0) setBase.set = true;
			const filterValue = filter.value;
			const currentValues = ratifyState.selectionFilterList[filter.name];
			currentValues.push(filterValue);
			valuesToSet[filter.name] = currentValues;

			// On chnage of baseDependentFilter filter values
			// we shall update dependent filter vlaue
			dependentForms.forEach((dependentForm) => {
				if (!ratifyState.baseDependentFilter || ratifyState.baseDependentFilter === filter.name) {
					const filterBaseArray = newFilterValues.filter(f => f.name === ratifyState.baseDependentFilter);
					const existingDepenedentFilters = newFilterValues.filter(f => f.name === dependentForm).map(f => f.value);
					let dependentFormFilters = {};
					if (setBase.set || filterBaseArray.length < 1) {
						dependentFormFilters = getFilterListBasedOnFilterType(dependentForm, ratifyState.filtersDetails);
					} else {
						dependentFormFilters = getDependentFilter(filter.name, filterBaseArray);
					}
					valuesToSet[dependentForm] = dependentFormFilters.filter(f => !existingDepenedentFilters.includes(f));
					filterTypes.push(dependentForm);
				}
			});

			// reset base to intitail value if there are no filters
		}
		updateSelectionAndDependentFilter(filterTypes, valuesToSet, newFilterValues, setBase);
	};

	const getCurrentRun = (dataSetDetails) => {
		const datasetRuns = dataSetDetails.data[datasetId];
		const currentRun = datasetRuns.filter(el => el.runConfigId === runConfigId);
		const currentRunObject = currentRun.length ? currentRun[0] : undefined;
		return currentRunObject;
	};
	const getSubsectionName = (currentRunObject) => {
		const lengthOfDataset = currentRunObject?.datasetName?.length;
		const dataset = currentRunObject?.runConfigName?.substr(0, lengthOfDataset);
		if (currentRunObject?.datasetName === dataset) {
			return currentRunObject?.runConfigName?.substr(lengthOfDataset + 1);
		}
		return currentRunObject?.runConfigName;
	};

	function getDatesForDateRange(range) {
		let dateRange = "";
		const epoch = {};
		switch (range) {
		case "Last 1 Month":
			dateRange = `${moment().subtract(1, 'months').format('D MMMM, YYYY')} - ${moment().format('D MMMM, YYYY')}`;
			epoch.startDate = moment().subtract(1, 'months').valueOf();
			break;
		case "Last 6 Months":
			dateRange = `${moment().subtract(6, 'months').format('D MMMM, YYYY')} - ${moment().format('D MMMM, YYYY')}`;
			epoch.startDate = moment().subtract(6, 'months').valueOf();
			break;
		case "Last 1 Year":
			dateRange = `${moment().subtract(12, 'months').format('D MMMM, YYYY')} - ${moment().format('D MMMM, YYYY')}`;
			epoch.startDate = moment().subtract(12, 'months').valueOf();
			break;
		case "Last 2 Years":
			dateRange = `${moment().subtract(24, 'months').format('D MMMM, YYYY')} - ${moment().format('D MMMM, YYYY')}`;
			epoch.startDate = moment().subtract(24, 'months').valueOf();
			break;
		default:
			epoch.startDate = 0;
			break;
		}
		epoch.endDate = moment().valueOf();
		return { dateRange, epoch };
	}

	const getCurrentDataFilters = (currentRunObject) => {
		const filterValue = currentRunObject.datasetFilters;
		const value = currentRunObject.timeRange;
		const rangeFilter = getDatesForDateRange(value);
		const { epoch, dateRange } = rangeFilter;
		filterValue.push({
			value, epoch, dateRange, name: "datasetTimeRange",
		});
		return filterValue;
	};

	// prefilling all fields for updating subset
	const updateFilters = (filterData, dataset, preload) => {
		// reset full filters as new data will be loaded from backend
		const filtersDetails = filterData;
		const filterFormList = filterData?.datasetFilterColumns;
		const selectionFilterList = {};
		const currentRunObject = preload ? getCurrentRun(datasetDetails) : undefined;
		// const filterValues = currentRunObject ? currentRunObject.datasetFilters : [];
		const filterValues = currentRunObject ? getCurrentDataFilters(currentRunObject) : [];
		filterFormList.forEach((filterType) => {
			const currentFilters = getFilterListBasedOnFilterType(filterType, filterData);
			// if we are editing existing remove existing filters from selection options
			if (filterValues) {
				const removeExistingFilters = filterValues.filter(f => f.name === filterType).map(m => m.value).flat();
				selectionFilterList[filterType] = currentFilters.filter(f => !removeExistingFilters.includes(f));
			} else {
				selectionFilterList[filterType] = currentFilters;
			}
		});

		let state = {
			...ratifyState,
			dataset,
			filterFormList,
			filtersDetails,
			selectionFilterList,
			filterValues,
		};


		if (currentRunObject) { // currentRunObject => this obj is used to initialize state to prefilled all fields
			// set dataset name and subsection name
			const datasetNew = {
				datasetName: currentRunObject?.datasetName,
				datasetId: currentRunObject?.datasetId,
			};
			setDefaultName(currentRunObject?.runConfigName);
			const subSectionName = getSubsectionName(currentRunObject);
			const ratifyType = "scheduled";
			const schedule = { ...initialSchedule, ...currentRunObject?.schedulingDetails };
			const timeRange = currentRunObject?.timeRange;
			const { epoch } = getDatesForDateRange(currentRunObject?.timeRange);

			state = {
				...state,
				filterValues,
				...{
					dataset: datasetNew,
				},
				subSectionName,
				ratifyType,
				schedule,
				timeRange,
				datasetTimeRange: epoch,
			};
		}

		// set filter values from the state
		setRatifyState(state);
		// set schedule
	};


	const handleRoute = () => {
		history.push("/home");
	};

	const handleAlertClose = () => {
		setAlert(false);
		if (redirect) {
			handleRoute();
		}
	};


	const handleDataSetChange = (dataset, preload) => {
		setRatifyState({ ...initialState, dataset });
		setFilterLoading(true);
		fetchFilter(dataset.datasetId).then((resp) => {
			resp.json().then((result) => {
				setFilterLoading(false);
				if (result.status.statusCode === "ratify-200") {
					updateFilters(result?.data, dataset, preload);
				} else {
					setAlert(true);
					setAlertMessage(result.status.statusMessage);
				}
			});
		});
	};

	const handleRatificationChange = (selectedRatifyType) => {
		const ratifyType = selectedRatifyType;
		setRatifyState({ ...ratifyState, ratifyType });
	};

	const setSubSectionName = (name) => {
		const subSectionName = name;
		setRatifyState({ ...ratifyState, subSectionName });
	};

	const getDefaultSelection = (filterType) => {
		if (filterType === 'datasetTimeRange') {
			if (ratifyState.timeRange === "Last 1 Month" || ratifyState.timeRange === "Last 6 Months"
				|| ratifyState.timeRange === "Last 1 Year" || ratifyState.timeRange === "Last 2 Years") {
				const endDate = formatDate(ratifyState?.datasetTimeRange?.endDate, 'MMMM DD, yyyy');
				const startDate = formatDate(ratifyState?.datasetTimeRange?.startDate, 'MMMM DD, yyyy');
				return [`${startDate}-${endDate}`];
			}
			return [];
		}
		return ratifyState.filterValues.filter(f => f.name === filterType).map(m => m.value)[0];
	};

	useEffect(() => {
		// checking if this page is used to update subset else push to main ratify page
		if (!Object.keys(ratifyState?.dataset).length && datasetId && datasetDetails.status !== "loading" && role === "admin") {
			const currentRunObject = getCurrentRun(datasetDetails);
			if (currentRunObject) {
				const dataset = {
					datasetName: currentRunObject?.datasetName,
					datasetId: currentRunObject?.datasetId,
				};
				handleDataSetChange(dataset, true);
			}
		} else if (history.location?.pathname !== '/home/ratify') {
			history.push("/home/ratify");
		}
	}, [history.location?.pathname]);

	useEffect(() => {
		// used to reinitialize the component into its default state
		if (history.location?.pathname === '/home/ratify') {
			setRatifyState(initialState);
			setDefaultName("");
		}
	}, [history.location?.pathname]);

	// use to remove the datasetId and run configid form breadcrumbs
	const getMatchValue = () => {
		if (isEditable) {
			let obj = {};
			const { url } = match;
			const newUrl = url?.split("/")?.slice(0, 3);
			if (ratifyState?.dataset?.datasetName) {
				newUrl.push(ratifyState?.dataset?.datasetName);
			}
			obj = { ...match, url: newUrl.join("/") };
			return obj;
		}
		return match;
	};

	return (
		<LayoutTopSideBottom match={match}>
			<Breadcrumbs match={getMatchValue(match)} />
			<ErrorAlert message={messageAlert} open={alert} redirect={redirect} handleClose={handleAlertClose} />
			<TriggerModel open={openTrigger} handleClose={setOpenTrigger} dataSetDetails={runDetails} userDefaultName={userDefaultName} role={role} datasetName={ratifyState?.dataset?.datasetName} />
			<Box display="flex" className="ratify-data-container" flexDirection="column" justifyContent="space-between" minHeight="calc(100vh - 167px)">
				<Grid container className="top-panel" direction="column" alignItems="baseline">
					<Grid item container style={{ marginBottom: '100px' }}>
						<Grid item xs={12}>
							<Box display="flex" justifyContent="start" alignItems={{ xs: "start", md: "start" }} flexDirection={{ xs: "column", md: 'row' }}>
								<DatasetName handleDataSetChange={handleDataSetChange} dataset={ratifyState.dataset} isEditable={isEditable} />
								<SubsectionName
									isSubsectionUnique={isSubsectionUnique}
									setSubsectionUnique={setSubsectionUnique}
									dataset={ratifyState?.dataset?.datasetName}
									setSubSectionName={setSubSectionName}
									name={ratifyState?.subSectionName}
									isEditable={isEditable}
									defaultName={defaultName}
								/>
							</Box>
						</Grid>
						<Grid item xs={12}>
							{filterLoading && <Loader message="Loading Filters..." />}
							{ratifyState.filterFormList.map(filterType => (
								<FilterSelection
									key={filterType}
									filterType={filterType}
									selectionFilterList={ratifyState.selectionFilterList[filterType]}
									saveFilters={saveSelectedFilters}
									removeFilters={removeFilters}
									defaultSelection={getDefaultSelection(filterType)}
									isEditable={isEditable}
								/>
							))}
						</Grid>
						{!filterLoading
							&& (
								<Grid item xs={12}>
									<Grid item xs={12}>
										<RatificationType handleChange={handleRatificationChange} currentRatifyType={ratifyState.ratifyType} isEditable={isEditable} />
									</Grid>

									{ratifyState.ratifyType === "scheduled"
										&& (
											<>
												<Grid item xs={12}>
													<SubHeading className="sub-heading-secondary">Scoring Frequency</SubHeading>
												</Grid>
												<Grid item xs={12}>
													<Scheduler
														setFrequency={setFrequency}
														setFrequencyValue={setFrequencyValue}
														setTriggerTime={setTriggerTime}
														setTriggerTimeZone={setTriggerTimeZone}
														width={5}
														defaultValues={ratifyState.schedule}
														isEditable={isEditable}
														triggerTime={triggerTime}
													/>
												</Grid>
											</>
										)
									}

								</Grid>
							)
						}

					</Grid>
				</Grid>
				<Box className="bottom-panel" display="flex" alignItems="center" justifyContent="flex-end">
					<Box mx={3} display="flex" alignItems="end">
						<Button className="cancle-button" onClick={handleRoute} style={{ marginRight: "20px" }}>Cancel</Button>
						<Box>
							{(waiting) && <Loader />}
							<Button onClick={triggerOrScheduleRatify} disabled={waiting} className="scheduler-button">
								{getButtonName()}
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>
		</LayoutTopSideBottom>
	);
}

RatifyForm.propTypes = {
	role: PropTypes.string,
	datasetDetails: PropTypes.object,
	match: PropTypes.object,
};

RatifyForm.defaultProps = {
	role: "",
	datasetDetails: {},
	match: {},
};

const mapStateToProps = state => ({
	datasetDetails: state.dataset.datasetDetails || {},
	role: state.user.role || "",
});

export default connect(mapStateToProps)(React.memo(RatifyForm));
