import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		'& h5': {
			fontWeight: '600',
			color: "#d11450",
		},
		'& .MuiTextField-root': {
			margin: theme.spacing(1),
			width: '150px',
		},
		'& .MuiInputBase-root': {
			fontSize: '0.7em',

		},
	},
	gridContainerClass: {
		// alignItems:'end'
	},
	gridItemClass: {
		textAlign: 'start',
		marginLeft: '15px',
	},
	gridItemRight: {
		textAlign: 'end',
	},
	selectiontypo: {
		fontSize: '18px',
		backgroundColor: 'gray',
		margin: '10px',
		padding: '12px',
		borderRadius: '6px',
		color: 'white',
	},
	subsection: {
		fontFamily: 'Hind Siliguri',
		fontStyle: 'normal',
		fontWeight: '600',
		fontSize: '14px',
		lineHeight: '23px',
		color: '#465A69',
		[theme.breakpoints.down("md")]: {
			fontSize: "12px",
		},
		[theme.breakpoints.down("sm")]: {
			fontSize: "10px",
		},
	},
	subsectionVal: {
		fontFamily: 'Hind Siliguri',
		fontStyle: 'normal',
		fontWeight: '600',
		fontSize: '12px',
		lineHeight: '19px',
		color: '#4EAFB3',
		marginLeft: '10px',
		[theme.breakpoints.down("md")]: {
			fontSize: "10px",
		},
		[theme.breakpoints.down("sm")]: {
			fontSize: "8px",
		},
	},
	cardDesign: {
		textAlign: 'center',
		background: '#edf7f7',
		width: '100%',
		margin: 'auto',
		borderRadius: '5px',
		height: '90px',
	},
	cardHeader: {
		fontFamily: 'Hind Siliguri',
		fontStyle: 'normal',
		fontWeight: 'bold',
		fontSize: '12px',
		lineHeight: '19px',
		color: '#465A69',
		textAlign: 'center',
		[theme.breakpoints.down("md")]: {
			fontSize: "10px",
		},
		[theme.breakpoints.down("sm")]: {
			fontSize: "9px",
		},

	},
	cardContent: {
		fontFamily: 'Hind Siliguri',
		fontStyle: 'normal',
		fontWeight: '500',
		fontSize: '11px',
		lineHeight: '18px',
		color: '#465A69',
		textAlign: 'center',
		[theme.breakpoints.down("md")]: {
			fontSize: "9px",
		},
		[theme.breakpoints.down("sm")]: {
			fontSize: "7px",
		},
	},
	AmazonHeader: {
		fontFamily: 'Hind Siliguri',
		fontSize: '20px',
		fontStyle: 'normal',
		fontWeight: '700',
		lineHeight: '32px',
		letterSpacing: '-0.03em',
		textAlign: 'left',
		marginLeft: '4px',
		paddingLeft: '4px',
		color: '#465A69',
	},
	button: {
		fontFamily: 'Hind Siliguri',
		fontStyle: 'normal',
		fontSize: '11px',
		fontWeight: 500,
		lineHeight: '19px',
		color: '#465A69',
		backgroundColor: '#f6f6f6',
		border: 'none',
		textTransform: 'none',
		height: '26px',
		boxShadow: 'none',
		borderRadius: 'none',
	},
	activeBtn: {
		fontFamily: 'Hind Siliguri',
		fontStyle: 'normal',
		fontSize: '11px',
		fontWeight: 500,
		lineHeight: '19px',
		color: '#FFFFFF',
		backgroundColor: '#4EAFB3',
		border: 'none',
		boxShadow: 'none',
		textTransform: 'none',
		height: '26px',
		borderRadius: '4px',
		"&:hover": {
			background: '#4EAFB3',
			color: '#ffffff',
		},
	},
	chartTitle: {
		transform: 'rotate(-90deg)',
		fontFamily: 'Hind Siliguri',
		fontStyle: 'normal',
		fontSize: '12px',
		fontWeight: 600,
		lineHeight: '19px',
		whiteSpace: 'nowrap',
		position: 'absolute',
		left: '-34px',
	},
	avgScore: {
		fontFamily: 'Hind Siliguri',
		fontStyle: 'normal',
		fontSize: '11px',
		fontWeight: 600,
		lineHeight: '19px',
		color: '#465A69',
	},
}));

// export const getSelectionDetails = ({ datasetFilters = [] }) => {
// 	if (!datasetFilters.length) {
// 		return { country: "", brand: "" };
// 	}
// 	const country = (datasetFilters.find(obj => (obj.name || "").toLowerCase() === "country_name") || { value: [] }).value.join(", ");
// 	const brand = (datasetFilters.find(obj => (obj.name || "").toLowerCase() === "brand_name") || { value: [] }).value.join(", ");
// 	return { country, brand };
// };

export const getSchedulingDetails = (data) => {
	const { schedulingDetails } = data;
	if (!schedulingDetails) {
		return "";
	}
	if (schedulingDetails.frequency === 'Daily') {
		return schedulingDetails.frequency;
	}
	if (schedulingDetails.frequency === 'Weekly') {
		return `${schedulingDetails.frequency}, ${schedulingDetails.triggerDay}`;
	}
	return `${schedulingDetails.frequency}, ${schedulingDetails.triggerDate}`;
};
