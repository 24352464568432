import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Sidenav from "../../components/Sidenav/Sidenav";

import "./LayoutTopSideBottom.scss";

function LayoutTopSideBottom(props) {
	const { user, children, match } = props;
	return (
		<div className={`${user.theme} page`}>
			<Header match={match} />
			<section className="body">
				<Sidenav match={match} />
				<main
					className={`content ${user.screen.isSidenavBarExpanded
						? "main-expanded"
						: "main-collapsed"
					}`}
				>
					{children}
				</main>
			</section>
			<Footer />
		</div>
	);
}

LayoutTopSideBottom.propTypes = {
	children: PropTypes.node.isRequired,
	user: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({ user: state.user });

export default connect(mapStateToProps)(LayoutTopSideBottom);
