// Import required libraries
import React from "react";

import comingSoon from "../../../assets/images/under_progress.svg";

// Import styles
import "./WorkInProgress.scss";

function WorkInProgress() {
	return (
		<div className="work-in-progress-container">
			<div className="wip-image">
				<img src={comingSoon} alt="Work in progress" />
			</div>
			<div>
				<p className="work-in-progress-text">
					This page is not yet available. Thank you for your patience.
				</p>
			</div>
		</div>
	);
}

export default WorkInProgress;
