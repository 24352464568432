import moment from "moment";


export const formatDate = (date, format = "MMM-DD-YYYY H:mm:ss") => moment(date).format(format);

export const test = "test";

export const getRoundOfScore = (score) => {
	const convertedValue = Number(score).toFixed(2).endsWith(".00") ? score : Number(score).toFixed(2);
	return convertedValue;
};
