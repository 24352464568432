import React, { useState } from 'react';
// import PropTypes from "prop-types";
import PropTypes from "prop-types";
import Plotly from "plotly.js-dist";
import createPlotlyComponent from "react-plotly.js/factory";
import moment from 'moment';

const Plot = createPlotlyComponent(Plotly);

const PlotlyChart = ({
	runHistoryObj, onDateChange, range, graphData,
}) => {
	const { labels, scores, colors } = graphData;
	const [hoverPoints, setHoverPoints] = useState({});
	const getRoundOfScore = (score) => {
		const convertedValue = Number(score).toFixed(2).endsWith(".00") ? score : Number(score).toFixed(2);
		return convertedValue;
	};
	const handleClick = (data) => {
		const { points } = data;
		if (!points[0]) {
			return;
		}
		// const coords = colors;
		// coords[points[0]?.pointIndex] = "#465A69";
		// setDotColor(coords);
		let index = null;
		runHistoryObj.data.some((item, i) => {
			const date = moment(item.lastRunOn).format('YYYY-MM-DD hh:mm:ss');
			if (points[0].x === date && parseFloat(points[0].y) === parseFloat(item.response?.aggregateScore)) {
				index = i;
				return true;
			}
			return false;
		});
		if (index >= 0) {
			onDateChange(index);
		}
	};

	const handleHover = (data) => {
		const { points } = data;
		if (!points[0]) {
			return;
		}
		const x = moment(points[0].x).format("MMMM DD, yyyy");
		const y = getRoundOfScore(points[0].y);
		setHoverPoints({ x, y });
	};

	const data = [{
		x: labels,
		y: scores,
		type: 'marker',
		line: { color: '#4EAFB3' },
		marker: {
			color: colors,
			line: {
				width: 2.5,
				color: colors,
			},
			size: 5,
		},
		hovertemplate:
			`Last run on ${hoverPoints.x}<br>`
			+ `Score: ${hoverPoints.y}<br>`
			+ "<extra></extra>",
	}];
	const layout = {
		width: 461,
		height: 222,
		hoverlabel: {
			bgcolor: "rgba(0,0,0,0.87)", color: "#fff", font: { size: 11 },
		},
		plot_bgcolor: '#f6f6f6',
		paper_bgcolor: '#f6f6f6',
		font: 11,
		color: '#465A69',
		family: 'Hind Siliguri',
		margin: {
			l: 38,
			b: 40,
			r: 30,
			t: 20,
			// pad: 10,
		},
		yaxis: {
			range: [0, 100],
			dtick: 25,
			fixedrange: true,
			linecolor: '#DDDDDD',
			linewidth: 2,
			showticksuffix: 'all',
			ticksuffix: '%',
			showline: true,
			showgrid: false,
			ticks: 'outside',
			tickcolor: '#DDDDDD',
			tickwidth: 2,
			ticklen: 8,
			tickfont: {
				family: 'Hind Siliguri',
				size: 11,
				color: '#465A69',
				fontWeight: 500,
			},
		},
		xaxis: {
			range,
			tickmode: 'auto',
			type: 'date',
			showline: true,
			linecolor: '#DDDDDD',
			linewidth: 2,
			fixedrange: true,
			showgrid: false,
			ticks: 'outside',
			tickcolor: '#DDDDDD',
			tickwidth: 2,
			ticklen: 8,
			tickfont: {
				family: 'Hind Siliguri',
				size: 11,
				color: '#465A69',
				fontWeight: 500,
			},
		},
	};
	return (
		<div>
			<Plot
				data={data}
				layout={layout}
				config={{ displayModeBar: false, responsive: true }}
				onClick={e => handleClick(e)}
				onHover={e => handleHover(e)}
				style={{
					width: '461px',
					height: '222px',
					fontFamily: 'Hind Siliguri',
					fontStyle: 'normal',
					fontWeight: 500,
					fontSize: '11px',
					lineHeight: '18px',
					cursor: 'default',
				}}
			/>
		</div>
	);
};

PlotlyChart.propTypes = {
	onDateChange: PropTypes.any.isRequired,
	runHistoryObj: PropTypes.any.isRequired,
	range: PropTypes.any.isRequired,
	graphData: PropTypes.any.isRequired,
};

export default PlotlyChart;
