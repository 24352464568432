import { React, useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// Import Libraries
import { makeStyles, Button } from "@material-ui/core";

// Import Styles
import { StyledInputBase, SubHeading } from "../../../styles/js/Styles";
import runSuccess from "../../../assets/images/ratify/run-success.svg";
import runError from "../../../assets/images/ratify/run-error.svg";
import uploadFile from "../../../assets/images/ratify/upload-file.svg";
import addUploadFile from "../../../assets/images/ratify/addUpload-file.svg";
import config from "../../config/config";
import "./addDataSet.scss";

// Import actions
import {
	updateData,
	resetValidationStatus,
} from "../../../actions";

// Import custom Components
import ViewSchema from "../../../components/ViewSchema";
import CustomFileInput from "./CustomFileInput";
import UploadLoader from "../../components/UploadLoader";
import DatasetRules from "../RegisterDataset/DatasetRules";

// make style function
const makeRegisterDatasetFooterStyles = () => {
	const useStyles1 = makeStyles(() => ({
		mainContainer: {
			display: "flex",
			height: "calc(100% - 28px)",
			width: "100%",
			flexDirection: "column",
			justifyContent: "space-between",
			overflow: "auto",
			"& .commonMargin": {
				marginBottom: 20,
			},
		},
		registerDatasetContainer: {
			display: "flex",
			height: "100%",
			width: "100%",
			flexDirection: "column",
			overflow: "auto",
		},
		inputContainerClass: {
			display: "flex",
			"&:disabled": {
				backgroundColor: "#A4D4D7",
				cursor: "default",
			},
		},
		inputDatasetClass: {
			display: "flex",
			flexDirection: "column",
		},
		validateButtonClass: {
			"&:disabled": {
				backgroundColor: "#9F9F9F",
				cursor: "default",
			},
			backgroundColor: "#465A69",
			fontWeight: "700",
			fontFamily: "Hind Siliguri !important",
		},
		validateButtonContainer: {
			display: "flex",
			alignItems: "center",
		},
		footerButtonClass: {
			"&:disabled": {
				backgroundColor: "#A4D4D7",
				cursor: "default",
			},
			backgroundColor: "#4EAFB3",
			fontWeight: "700",
			fontFamily: "Hind Siliguri !important",
		},
		cancelBtn: {
			color: "#4eafb3",
			border: "1px solid #4eafb3",
			fontSize: "13px",
			padding: "0px 10px",
			fontFamily: "Hind Siliguri",
			textTransform: "none",
			marginRight: "10px",
			fontWeight: "700",
		},
		registerDatasetFooterContainer: {
			padding: "22px 0px",
		},
		leftFooterButtons: {
			marginRight: "20px",
		},
		footerButtonsContainer: {
			display: "flex",
			justifyContent: "flex-end",
		},
		leftButtonsContainer: {
			display: "flex",
			justifyContent: "end",
			width: "250px !important",
			alignItems: "flex-end",
			flexDirection: "column",
		},
		validatedText: {
			fontFamily: "Hind Siliguri !important",
			fontSize: "14px !important",
			fontWeight: "700",
		},
		uploadFileClass: {
			flexDirection: "column",
			width: "100%",
			alignItems: "center",
			minHeight: 150,
			justifyContent: "center",
			display: "flex",
			"& P": {
				margin: 0,
				padding: 0,
				color: "#4EAFB3",
				fontSize: 13,
			},
			"& .uploadImageContainer": {
				display: "flex",
				alignItems: "center",
				textAlign: "center",
				"& P": {
					margin: 0,
					padding: 0,
					color: "#4EAFB3",
					fontSize: 13,
				},
				"& .orContainer": {
					margin: "0  40px",
				},
				"& .imageContainer": {
					textAlign: "center",
				},
			},
			"& .loaderImageContainer": {
				textAlign: "center",
			},
			"& .loaderBox": {
				marginTop: 10,
				textAlign: "center",
				"& p": {
					marginTop: 10,
				},
			},
			"& .uploadedContainer": {
				display: "flex",
				marginTop: "30px",
				alignItems: "center",
			},
		},
		validateContainer: {
			"& .validateContentContainer": {
				border: "1px solid rgba(0, 0, 0, 0.3);",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				borderRadius: 5,
				padding: "10px 0",
				textAlign: "center",
				"& p": {
					margin: 0,
					padding: 0,
					color: "#4EAFB3",
					fontSize: 13,
				},
				"& span": {
					color: "#415A6C",
					fontWeight: 600,
					fontSize: 15,
				},
			},
		},
		schemaValidationContainer: {
			"& .schemaVerifyContainer": {
				"& p": {
					color: "#415A6C",
				},
				"& span": {
					fontWeight: 500,
					fontSize: 14,
					color: "#4EAFB3",
					textDecoration: "underline",
					cursor: "pointer",
				},
			},
		},
		validationSuccess: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			"& p": {
				color: "#4EAFB3 !important",
			},
		},
		rulesContainer: {
			display: "flex",
			alignItems: "end",
			position: "relative",

			"& span": {
				fontSize: 12,
				color: "#415A6C",
				fontWeight: 600,
				// textDecoration: "underline",
				cursor: "default",
			},
		},
	}));
	return useStyles1;
};

const AddDataset = (props) => {
	// destructing props
	const {
		updateData: updateDataFn,
		addUploadStatus,
		addValidationStatus,
		addDatasetStatus,
	} = props;


	// define required states
	const [datasetName, setDatasetName] = useState("");
	const [fileName, setFileName] = useState("");
	const [uploadState, setUploadState] = useState("");
	const [showSchemaState, setShowSchemaState] = useState(false);
	const [schemaSeen, setSchemaSeen] = useState(false);
	const [changesValidated, setChangesValidated] = useState(false);

	// define despatch
	const dispatch = useDispatch();

	//
	useEffect(() => {
		if (fileName === "") {
			updateDataFn("addUploadStatus", {
				status: "pending",
				message: "",
				data: [],
			});
		}
	}, [fileName]);

	const handleFileChange = (e, option) => {
		dispatch(resetValidationStatus());
		// despatch the resetValidationStatus action for reset the status and data all
		setUploadState("loading");
		updateDataFn("addUploadStatus", {
			status: "loading",
			message: "",
			data: [],
		});

		setSchemaSeen(false);
		const formData = new FormData();

		if (option === "select") {
			setFileName(e.target?.files[0]?.name);
			formData.append("file", e.target.files[0]);
		}
		if (option === "drop") {
			setFileName(e.dataTransfer.files[0]?.name);
			formData.append("file", e.dataTransfer.files[0]);
		}

		// api for post the file selected file in data base
		const url = config.api.fetchDoc;

		fetch(url, {
			// Your POST endpoint
			method: "POST",
			headers: {},
			body: formData, // This is your file object
		})
			.then(
				response => response.json(), // if the response is a JSON object
			)
			.then(
				(success) => {
					if (success.status.statusCode === "ratify-200") {
						updateDataFn("addUploadStatus", {
							status: "success",
							message: "",
							data: success,
						});
						setUploadState("success");
					} else {
						updateDataFn("addUploadStatus", {
							status: "error",
							message: "",
							data: success,
						});
						setUploadState("error");
					}
				}, // Handle the success response object
			)
			.catch(
				(error) => {
					console.log(error);
					updateDataFn("addUploadStatus", {
						status: "error",
						message: "",
						data: error,
					});
					setUploadState("error");
					updateDataFn("addUploadStatus", {
						status: "error",
						message: "",
						data: [],
					});
				}, // Handle the error response object
			);
	};

	const handleChange = (value) => {
		setDatasetName(value);
	};

	const isFileUploadDisabled = () => {
		if (uploadState === "loading") {
			return true;
		}
		return false;
	};

	const useRegisterFooterStyles = makeRegisterDatasetFooterStyles();
	const registerFooterClasses = useRegisterFooterStyles();

	const {
		inputDatasetClass,
		inputContainerClass,
		registerDatasetContainer,
		mainContainer,
		registerDatasetFooterContainer,
		uploadFileClass,
		rulesContainer,
		footerButtonClass,
	} = registerFooterClasses;

	return (
		<>
			<div className={mainContainer}>
				<div className={registerDatasetContainer}>
					<div className={`${inputContainerClass} commonMargin`}>
						<div className={inputDatasetClass}>
							<SubHeading className="sub-heading">
								Enter Dataset Name
							</SubHeading>
							<StyledInputBase
								// className={classes.inputTextVal}
								inputProps={{
									maxLength: 50,
								}}
								style={{
									fontFamily: "Hind Siliguri !important",
									width: "320px",
								}}
								size="small"
								placeholder="Enter a dataset name"
								onChange={e => handleChange(e.target.value)}
							/>
						</div>
					</div>
					<div style={{ width: "100%" }} className={inputContainerClass}>
						<div style={{ width: "100%" }} className={inputDatasetClass}>
							<div className="commonMargin">
								<div
									style={{ display: "flex", justifyContent: "space-between" }}
								>
									<SubHeading className="sub-heading">
										Upload your file here
									</SubHeading>
									<div className={`${rulesContainer} rulesSection`}>
										<span style={{ marginRight: "5px" }}>Rules for File Upload</span>
										<div className="rules">
											<p style={{ fontSize: "11px", margin: "0px !important" }}>
												1. Only comma separated files are allowed.
											</p>
											<p style={{ fontSize: "11px", margin: "0px !important" }}>
												2. Maximum size allowed is 1GB.
											</p>
											<p style={{ fontSize: "11px", margin: "0px !important" }}>
												3. Blank file cannot be uploaded.
											</p>
										</div>
									</div>
								</div>
								<CustomFileInput
									accept=".csv"
									onChange={handleFileChange}
									uploadState={uploadState}
									fileName={fileName}
									disabled={isFileUploadDisabled()}
									// maxNumberOfFiles={maxNumberOfFiles}
								>
									<div className={`${uploadFileClass}`}>
										{uploadState !== "loading" && uploadState !== "success"
											&& !fileName
											&& uploadState !== "error" && (
											<div className="uploadImageContainer">
												<div className="imageContainer">
													<img src={uploadFile} alt="" />
													<p>Drag and Drop</p>
												</div>
												<div className="orContainer">or</div>
												<div className="imageContainer">
													<img src={addUploadFile} alt="" />
													<p>Upload from local</p>
												</div>
											</div>
										)}

										{uploadState === "loading" && (
											<>
												<div className="loaderImageContainer">
													<img src={addUploadFile} alt="" />
													<p style={{ textAlign: "center  " }}>{fileName}</p>
												</div>
												<div className="loaderBox">
													<UploadLoader />
													<p className="loadingText">Loading...</p>
												</div>
											</>
										)}
										{uploadState === "error" && (
											<>
												<div className="loaderImageContainer">
													<img src={addUploadFile} alt="" />
													<p style={{ textAlign: "center  " }}>{fileName}</p>
												</div>
												<div
													style={{
														fontSize: "10px",
														color: "red",
														display: "flex",
														alignItems: "center",
													}}
												>
													<img src={runError} alt="" />
													<p
														style={{
															fontSize: "10px",
															marginRight: "10px",
															color: "red",
														}}
													>
														{addUploadStatus?.data?.status?.statusMessage
															.charAt(0)
															.toUpperCase()
															+ addUploadStatus?.data?.status?.statusMessage.slice(
																1,
															) || "Error : File size limit exceeded."}
													</p>
												</div>
											</>
										)}
										{uploadState === "success" && (
											<>
												<div className="loaderImageContainer">
													<img src={addUploadFile} alt="" />
													<p style={{ textAlign: "center" }}>{fileName}</p>
												</div>
												<div className="uploadedContainer">
													<img
														src={runSuccess}
														title="Uploaded"
														alt="success"
													/>
													<p>Uploaded</p>
												</div>
											</>
										)}
									</div>
								</CustomFileInput>
							</div>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									marginTop: "20px",
								}}
							>
								<button
									type="button"
									className={footerButtonClass}
									disabled={uploadState !== "success"}
									onClick={() => setChangesValidated(!changesValidated)}
								>
								Validate
								</button>
							</div>
							<div>
								<DatasetRules
									tableName="edap_stg.igk_ekko"
									disable={
										uploadState === "success" && changesValidated
									}
								/>
							</div>
							{showSchemaState && (
								<ViewSchema
									datasetId={addValidationStatus.data.datasetId}
									datasetName={datasetName}
									showSchemaState={showSchemaState}
									setShowSchemaState={setShowSchemaState}
									datasetConfig={addValidationStatus}
									validationStatus={addValidationStatus}
									isViewOnly={false}
									type="local-add"
								/>
							)}
						</div>
					</div>
				</div>
				<div className={registerDatasetFooterContainer}>
					<RegisterDatasetFooter
						datasetName={datasetName}
						addUploadStatus={addUploadStatus}
						uploadState={uploadState}
						addDatasetStatus={addDatasetStatus}
						addValidationStatus={addValidationStatus}
						setShowSchemaState={setShowSchemaState}
						schemaSeen={schemaSeen}
						updateDataFn={updateDataFn}
						disableFlag={
							uploadState === "success" && changesValidated
						}
					/>
				</div>
			</div>
		</>
	);
};

const RegisterDatasetFooter = (props) => {
	const {
		disableFlag,
	} = props;

	const useRegisterFooterStyles = makeRegisterDatasetFooterStyles();
	const registerFooterClasses = useRegisterFooterStyles();
	const {
		footerButtonClass, footerButtonsContainer, leftButtonsContainer, cancelBtn,
	} =		registerFooterClasses;
	const [displayMessage, setDisplayMessage] = useState({
		message: "",
		open: false,
	});

	const addDatasetSend = () => {
		setDisplayMessage({ message: "Changes saved successfully.", open: true });
	};

	return (
		<div className={footerButtonsContainer}>
			<p
				style={{
					color: "#4eafb3",
					fontSize: "13px",
					fontFamily: "Hind Siliguri !important",
					paddingRight: "20px",
				}}
			>
				{displayMessage.message}
			</p>
			<div className={leftButtonsContainer}>
				<div>
					<Link
						to="/home/mydatasets"
						style={{ textDecoration: "none" }}
					>
						<Button
							className={cancelBtn}
						>
									Cancel
						</Button>
					</Link>
					<button
						type="button"
						className={footerButtonClass}
						disabled={!disableFlag}
						onClick={addDatasetSend}
					>
						Save Dataset
					</button>
				</div>
			</div>
		</div>
	);
};

AddDataset.propTypes = {
	updateData: PropTypes.any.isRequired,
	addUploadStatus: PropTypes.any.isRequired,
	addDatasetStatus: PropTypes.any.isRequired,
	addValidationStatus: PropTypes.any.isRequired,
};

RegisterDatasetFooter.propTypes = {
	disableFlag: PropTypes.any.isRequired,
};

const mapStateToProps = state => ({
	addUploadStatus: { ...state.dataset.addUploadStatus },
	addValidationStatus: { ...state.dataset.addValidationStatus },
	addDatasetStatus: { ...state.dataset.addDatasetStatus },
});

const mapDispatchToProps = { updateData };

export default connect(mapStateToProps, mapDispatchToProps)(AddDataset);
