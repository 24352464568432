import {
	useEffect, useRef,
} from "react";

/**
 * This hook gets called only when the dependencies change but not during initial render.
 *
//  *  The useEffect callback function.
// An array of dependencies.
 *
 * @example
 *
 *  useNonInitialEffect(()=>{
 *      alert("Dependency changed!");
 * },[dependency]);
 *
 */
export const useNonInitialEffect = (effect, deps) => {
	const initialRender = useRef(true);

	useEffect(() => {
		let effectReturns = () => {};

		if (initialRender.current) {
			initialRender.current = false;
		} else {
			effectReturns = effect();
		}

		if (effectReturns && typeof effectReturns === "function") {
			return effectReturns;
		}
		return effectReturns;
	}, deps);
};

export default useNonInitialEffect;
