
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const SubHeading = withStyles({
	root: {
		fontFamily: "Hind Siliguri",
		fontStyle: "normal",
		fontWeight: "600",
		fontSize: "16px",
		lineHeight: "26px",
		color: "#465A69",
	},
})(Typography);

export default SubHeading;
