import C from "../actions/constants";

export default function user(state = {}, payload) {
	const { type, key, data } = payload;
	switch (type) {
	case C.UPDATE_SELECTIONS:
		return {
			...state,
			selections: { ...state.selections, [key]: data },
		};
	case C.UPDATE_MULTI_SELECTIONS:
		return {
			...state,
			selections: { ...state.selections, ...data },
		};
	case C.UPDATE_ALERT_INFO:
		return {
			...state,
			alertInfo: { ...data },
		};
	case C.UPDATE_DIALOG_INFO:
		return {
			...state,
			dialogInfo: { ...data },
		};
	case C.UPDATE_USER_INFO:
		return {
			...state,
			...data,
		};
	case C.RESET_FILTERS:
		return {
			...state,
			selections: {
				...state.selections,
				country: "ALL",
				segment: "ALL",
				brand: "ALL",
				searchText: "",
			},
		};
	case C.TOGGLE_SIDENAV:
		return {
			...state,
			screen: {
				...state.screen,
				isSidenavBarExpanded: !state.screen.isSidenavBarExpanded,
			},
		};
	case C.REDIRECT_TO_ERROR:
		return {
			...state,
			showErrorPage: true,
		};

	case C.RESET_REDIRECT_TO_ERROR:
		return {
			...state,
			showErrorPage: false,
		};
	default:
		return state;
	}
}
