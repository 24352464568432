// Import required libraies
import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

// Import Custom Component
import LayoutTopSideBottom from "../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";

// Import Custom Hooks

// Import utils/data

function AIDEVeritcal(props) {
	const { match } = props;

	return (
		<>
			{match.params.function ? (
				<LayoutTopSideBottom match={match}>
					<Breadcrumbs match={match} />
				</LayoutTopSideBottom>
			) : match.url === "/home/" ? (
				<Redirect to={`${match.url}datasets`} />
			) : (
				<Redirect to={`${match.url}/datasets`} />
			)}
		</>
	);
}

AIDEVeritcal.propTypes = {
	match: PropTypes.object.isRequired,
};

export default AIDEVeritcal;
