import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

function LayoutNone({ user, children }) {
	return <div className={`${user.theme} page`}>{children}</div>;
}

LayoutNone.propTypes = {
	children: PropTypes.node.isRequired,
	user: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({ user: state.user });

export default connect(mapStateToProps)(LayoutNone);
