/* eslint-disable react/react-in-jsx-scope */
import { makeStyles, MenuItem, Select } from "@material-ui/core";
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles(() => ({
	selectRoot: {
		width: "100%",
		borderRadius: '5px',
		paddingLeft: '10px',
		backgroundColor: "#EDEDED",
		fontFamily: 'Hind Siliguri!important',
		fontStyle: 'normal!important',
		fontWeight: '500!important',
		fontSize: '12px!important',
		lineHeight: '19px!important',
		color: '#465A69!important',
		"&:focus": {
			backgroundColor: "#EDEDED",
			borderRadius: '5px',

		},
	},
	selected: {
		"&:hover": {
			backgroundColor: "#EDEDED",
		},
	},
	rootMenuItem: {
		fontFamily: 'Hind Siliguri!important',
		fontStyle: 'normal!important',
		fontWeight: '500!important',
		fontSize: '12px!important',
		lineHeight: '19px!important',
		"&:hover": {
			backgroundColor: "#465A69",
			color: "white",
		},
		"&$selected": {
			backgroundColor: "#EDEDED",
			color: "#465A69",
			"&:hover": {
				backgroundColor: "#465A69",
				color: "white",
			},
		},
	},
}));


export default function SelectCmp(props) {
	const classes = useStyles();
	const {
		data, change, defaultValue, isDisabled,
	} = props;
	return (
		<div>
			<Select
				IconComponent={KeyboardArrowDownIcon}
				classes={{ root: classes.selectRoot }}
				fullWidth
				disableUnderline="true"
				defaultValue={defaultValue}
				onChange={change}
				disabled={isDisabled}
			>
				{data.map((listItem, index) => (
					<MenuItem
						value={listItem}
						key={index}
						classes={{ selected: classes.selected, root: classes.rootMenuItem }}

					>
						{listItem}
					</MenuItem>
				))}
			</Select>
		</div>
	);
}
SelectCmp.propTypes = {
	data: PropTypes.array.isRequired,
	change: PropTypes.func.isRequired,
	defaultValue: PropTypes.isRequired,
	isDisabled: PropTypes.isRequired,
};
