
import React, { useState } from "react";
import { Typography, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import runSuccess from "../../assets/images/ratify/run-success.svg";
import runError from "../../assets/images/ratify/run-error.svg";
import SubHeading from './SubHeading';

import "./index.scss";

function fetchAPI(param) {
	return fetch(`/api/v1/uniquerun?runConfigName=${param}`);
}

const statusIconStyle = {
	width: '30px',
	height: '30px',
};

const SubsectionName = ({
	name = "",
	dataset,
	setSubsectionUnique,
	setSubSectionName,
	isSubsectionUnique,
	isEditable,
	defaultName = "",
}) => {
	const [validationMessage, setValidationMessage] = useState("");
	const validateSubSectionName = (input) => {
		const value = `${dataset}-${input.currentTarget.value}`;
		if (!input.currentTarget?.value) {
			setValidationMessage("Subset name is required.");
			setSubsectionUnique(false);
			return;
		}
		if (value === defaultName && isEditable) {
			return;
		}
		fetchAPI(value).then((resp) => {
			resp.json().then((result) => {
				if (result?.status?.statusMessage) {
					if (result?.status?.statusCode === "ratify-200") {
						setSubsectionUnique(true);
						setValidationMessage("");
					} else {
						setValidationMessage(result.status.statusMessage);
						setSubsectionUnique(false);
					}
				}
			});
		});
	};

	return (
		<Box>
			<SubHeading className="sub-heading-sub">Subset Name</SubHeading>
			<Box display="flex" alignItems="center">
				<Typography className="subsection-name-field-ds">
					{dataset ? `${dataset}-` : ""}
					<input
						type="text"
						value={name}
						onChange={(e) => {
							setSubsectionUnique(false);
							setSubSectionName(e.target.value);
							setValidationMessage("");
						}}
						className="subsection-name-field"
						placeholder="Enter Subset name"
						onBlur={validateSubSectionName}
					// onBlur={validateSubSectionName}
					/>
				</Typography>

				{isSubsectionUnique
					? (
						<img
							src={runSuccess}
							style={statusIconStyle}
							title="Unique"
							alt="Success"
						/>
					) : validationMessage !== ""
						? (
							<img
								src={runError}
								style={statusIconStyle}
								title="Already exists"
								alt="Failed"
							/>
						) : null}
			</Box>
			<SubHeading className="validation-message">{validationMessage}</SubHeading>
		</Box>
	);
};

SubsectionName.propTypes = {
	name: PropTypes.any.isRequired,
	dataset: PropTypes.any.isRequired,
	setSubsectionUnique: PropTypes.any.isRequired,
	setSubSectionName: PropTypes.any.isRequired,
	isSubsectionUnique: PropTypes.any.isRequired,
	isEditable: PropTypes.any.isRequired,
	defaultName: PropTypes.any.isRequired,
};

export default SubsectionName;
