// Import required libraries
import React, { useState } from "react";
import PropTypes from "prop-types";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";

// Import styles
import "./PopOver.scss";

// Import utils
import appConfig from "../../config/config";
import Modal from "../Modal/Modal";

// Import action creators
import {
	updateAlertInfo,
	updateUserInfo,
	updateSelections,
	updateDialogInfo,
	reloadRunData,
	refreshData,
	reloadConfigs,
} from "../../../actions";

const useStyles = makeStyles(theme => ({
	popover: {
		fontSize: "12px",
		color: "#46596a",
		fontFamily: "Hind Siliguri",
		fontWeight: 500,
		textTransform: "capitalize",
		"&:hover": {
			backgroundColor: "#4EAFB3",
			color: "white",
			"& .MuiListItemIcon-root, & .MuiListItemText-primary": {
				color: "white",
			},
		},
	},
	disable: {
		fontSize: "12px",
		color: "#46596a",
		// textTransform: "uppercase",
		fontFamily: "Hind Siliguri",
		pointerEvents: "none",
		"&:hover": { backgroundColor: "transparent" },
	},
	toastTheme: {
		// backgroundColor: "#f0f2f4",
		color: "#46596a",
	},
	contained: {
		color: "white",
		backgroundColor: "#46596a",
		marginRight: 4,
		fontSize: 11,
		padding: 10,
		height: 23,
		minWidth: 15,
		textTransform: "capitalize",
	},
	outlined: {
		borderColor: "#46596a",
		color: "#46596a",
	},
	paperWidthSm: {
		width: "200px",
	},
	root: {
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "center",
		"& > * + *": {
			marginLeft: theme.spacing(2),
		},
	},
}));

function PopOver({
	user,
	match,
	name,
	configName,
	updateAlertInfo: updateAlertInfoFn,
	updateSelections: updateSelectionsFn,
	updateDialogInfo: updateDialogInfoFn,
	config,
}) {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const history = useHistory();
	const [modalComponent, setModalComponent] = useState({
		component: null,
		openModal: false,
		handleCancel: () => {
			console.log("handleCancel");
		},
		handleDone: () => {
			console.log("handleDone");
		},
	});


	const handleDialogClose = () => {
		setModalComponent(prevState => ({ ...prevState, openModal: false }));
	};

	const handleClick = (e) => {
		setAnchorEl(e.currentTarget);
		e.stopPropagation();
	};

	const handleClose = () => {
		setAnchorEl(null);
	};


	const navigateToDetails = ({ openRecordError }) => {
		if (openRecordError && openRecordError.trim()) {
			updateAlertInfoFn({
				open: true,
				message: openRecordError,
				severity: "info",
			});
			return "failed";
		}
		return "";
	};

	const navigateToDetailsForLink = ({ openRecordError }) => {
		if (openRecordError && openRecordError.trim()) {
			return "failed";
		}
		return "";
	};

	return (
		<>
			<i
				className="material-icons more-vert-icon-style"
				aria-label="more"
				aria-controls="long-menu"
				aria-haspopup="true"
				onClick={handleClick}
			>
				more_vert
			</i>
			<Menu
				id="long-menu"
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={handleClose}
			>
				<Link
					className="disabled-style menu-item"
					to={
						navigateToDetailsForLink(config) === "failed"
							? "#"
							: user.selections.appView === "table"
								? `/home/${match.params.function}/${match.params.app}/${match.params.tab}/${configName}`
								: `/home/${match.params.function}/${match.params.app}/${match.params.tab}/${name}`
					}
				>
					<MenuItem
						className={classes.popover}
						onClick={() => {
							if (navigateToDetails(config) === "failed") {
								return;
							}
							handleClose();
						}}
					>
						Edit
					</MenuItem>
				</Link>
				<MenuItem
					className={classes.popover}
					onClick={() => {
						if (navigateToDetails(config) === "failed") {
							return;
						}
						handleClose();
					}}
				>
					Run
				</MenuItem>
				<MenuItem
					className={classes.popover}
					onClick={() => {
						if (navigateToDetails(config) === "failed") {
							return;
						}
						handleClose();
						updateDialogInfoFn({
							message: appConfig.messages.confirmDelete,
							open: true,
							handleYes: () => { },
							handleNo: () => { },
						});
					}}
				>
					Delete
				</MenuItem>
				<MenuItem
					className={classes.disable}
					onClick={() => {
						if (navigateToDetails(config) === "failed") {
							return;
						}
						handleClose();
					}}
				>
					Schedule
				</MenuItem>
				<MenuItem
					className={classes.popover}
					onClick={() => {
						if (navigateToDetails(config) === "failed") {
							return;
						}
						handleClose();
						updateSelectionsFn("runFilters", {
							...user.selections.runFilters,
							searchText: config.name,
							dayCat: "all",
						});
						setTimeout(() => history.push(
							`/home/${match.params.function}/${match.params.app}/viewruns`,
						), 0);
					}}
				>
					View Runs
				</MenuItem>
				<Modal
					modalComponent={modalComponent}
					setModalComponent={setModalComponent}
					handleDialogClose={handleDialogClose}
				/>
			</Menu>
		</>
	);
}

PopOver.propTypes = {
	user: PropTypes.any.isRequired,
	match: PropTypes.any.isRequired,
	name: PropTypes.any.isRequired,
	configName: PropTypes.any.isRequired,
	updateAlertInfo: PropTypes.any.isRequired,
	updateSelections: PropTypes.any.isRequired,
	updateDialogInfo: PropTypes.any.isRequired,
	config: PropTypes.any.isRequired,
};

const mapStateToProps = state => ({
	user: state.user,
	saved_configurations: state.data.saved_configurations,
});

const mapDispatchToProps = {
	updateAlertInfo,
	updateUserInfo,
	updateSelections,
	updateDialogInfo,
	reloadRunData,
	refreshData,
	reloadConfigs,
};

export default connect(mapStateToProps, mapDispatchToProps)(PopOver);
