import React from "react";
import PropTypes from "prop-types";
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';

import 'react-circular-progressbar/dist/styles.css';
import '../index.scss';

export default function CircleProgress({ percentage: circlePercent = 0, color, remark }) {
	return (
		<div style={{ width: 111, height: 111 }}>
			<CircularProgressbarWithChildren
				value={circlePercent}
				strokeWidth={12}
				styles={{
					path: { stroke: color },
				}}
			>
				<div style={{
					fontFamily: 'Hind Siliguri',
					fontStyle: 'normal',
					// fontSize: '16px',
					fontSize: '14px',
					fontWeight: '600',
					lineHeight: '16px',
					color: '#465A69',
					marginLeft: '5px',
					position: 'absolute',
					top: '39px',
					// top: '45px',
				}}
				>
					<div>Total Score</div>
				</div>
				<div style={{
					fontFamily: 'Hind Siliguri',
					fontStyle: 'normal',
					fontSize: '10px',
					fontWeight: '600',
					lineHeight: '16px',
					color: '#465A69',
					marginLeft: '5px',
					position: 'absolute',
					top: '56px',
				}}
				>
					{remark}
				</div>
				<div style={{
					fontFamily: 'Hind Siliguri',
					fontStyle: 'normal',
					fontSize: '10px',
					fontWeight: '600',
					// lineHeight: '16.17 px',
					lineHeight: '16px',
					color: '#465A69',
					marginLeft: '5px',
					position: 'absolute',
					top: '72px',
				}}
				>
					{`${circlePercent}%`}
				</div>
			</CircularProgressbarWithChildren>
		</div>
	);
}

CircleProgress.propTypes = {
	percentage: PropTypes.any.isRequired,
	color: PropTypes.any.isRequired,
	remark: PropTypes.any.isRequired,
};
