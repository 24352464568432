import { makeStyles, withStyles } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import InputBase from "@mui/material/InputBase";
import Typography from "@mui/material/Typography";

export const StyledAutoComplete = withStyles(() => ({
	endAdornment: {
		display: "none",
	},
	tag: {
		background: "#4eafb3",
		color: "white !important",
		fontFamily: "Hind Siliguri !important",
		height: "22px",
	},
	paper: {
		fontFamily: "Hind Siliguri",
		fontSize: 13,
		color: "#46596a",
	},
}))(Autocomplete);

export const SubHeading = withStyles({
	root: {
		fontFamily: "Hind Siliguri !important",
		fontStyle: "normal !important",
		fontWeight: "600 !important",
		fontSize: "13px !important",
		lineHeight: "19px !important",
		color: "#465A69 !important",
		marginBottom: "7px !important",
		marginTop: "13px !important",
		paddingLeft: "2px !important",
	},
})(Typography);

export const StyledInputBase = withStyles(() => ({
	root: {
		width: "50%",
		height: "35px",
		background: "#F3F3F3",
		borderRadius: "3px",
		margin: "1.5px",
		textTransform: "capitalize",
		fontFamily: "Hind Siliguri !important",
		fontSize: "12px !important",
		paddingLeft: "10px !important",
		paddingTop: "0px !important",
	},
	noBorder: {
		border: "none",
	},
	inputTextval: {
		fontFamily: "Hind Siliguri!important",
		fontSize: "8px!important",
		fontStyle: "normal!important",
		fontWeight: "400!important",
		lineHeight: "19px!important",
		letterSpacing: "0em!important",
		textAlign: "left!important",
		background: "#EDEDED!important",
		paddingLeft: "10px!important",
		color: "#465A69!important",
		borderRadius: "5px!important",
		padding: "5px 12px 5px 12px!important",
	},
	"&:disabled": {
		backgroundColor: "#A4D4D7",
		cursor: "default",
	},
}))(InputBase);

export const StyledTextField = withStyles({
	root: {
		width: "100%",
		backgroundColor: "#f3f3f3",
		padding: "5px 10px",
		height: "26px",
		fontFamily: "Hind Siliguri",
		fontSize: "12px",
		borderRadius: "4px",
		"& label.Mui-focused": {
			color: "green",
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "#dedede",
		},
		"& .MuiOutlinedInput-root": {
			backgroundColor: "white",
			"& fieldset": {
				borderColor: "#dedede",
			},
			"&:hover fieldset": {
				borderColor: "#dedede",
			},
			"&.Mui-focused fieldset": {
				border: "0.5px solid #dedede",
			},
		},
	},
})(TextField);

export const useStyles01 = makeStyles(() => ({
	typographyStyle: {
		"& .MuiChip-root": {
			fontFamily: "Hind Siliguri",
			fontSize: 12,
		},
		"& .MuiInput-underline:after": {
			borderBottom: 0,
		},
		"& .MuiInput-underline:before": {
			borderBottom: 0,
			transition: "none",
		},
		"& .MuiInput-underline:hover:not(.Mui-disabled):before": {
			borderBottom: "0px solid #dedede !important",
		},
		"& .Mui-focused .MuiInput-underline": {
			borderBottom: 0,
		},
		"& .MuiAutocomplete-option": {
			fontFamily: "Hind Siliguri",
			fontSize: 12,
		},
		"& .MuiAutocomplete-paper": {
			fontFamily: "Hind Siliguri",
			fontSize: 6,
		},
		"& .MuiInputBase-input": {
			fontFamily: "Hind Siliguri",
			fontSize: 12,
			color: "#46596a",
		},
		"& .MuiOutlinedInput-root": {
			paddingTop: "0px !important",
			paddingBottom: "0px !important",
		},
		"& .MuiAutocomplete-popupIndicator": {
			"& span": {
				"& svg": {
					"& path": {
						d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')", // your svg icon path here
					},
				},
			},
		},
	},
	root: {
		"& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
			transform: "translate(34px, 20px) scale(1);",
			fontFamily: "Hind Siliguri",
			fontStyle: "normal",
			fontWeight: "400",
			fontSize: "5px",
			color: "#465a69",
			opacity: 0.5,
		},
	},
	select: {
		fontFamily: "Hind Siliguri !important",
		fontSize: "16px!important",
		fontStyle: "normal!important",
		fontWeight: "400!important",
	},
	comboOptions: {
		fontFamily: "Hind Siliguri",
		fontStyle: "normal",
		fontWeight: "400",
		fontSize: "16px",
		color: "#465A69",
	},
	popupIndicator: {
		"& span": {
			"& svg": {
				"& path": {
					d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')", // your svg icon path here
				},
			},
		},
	},
	inputRoot: {
		color: "#465a69",
		background: "#ededed",
		'&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
			paddingLeft: 26,
			fontFamily: "Hind Siliguri",
			fontSize: "14px",
			fontStyle: "normal",
			fontWeight: "500",
			lineHeight: "23px",
			letterSpacing: "0em",
			textAlign: "left",
			color: "#465A69",
		},
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#fafafa",
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "#fafafa",
		},
		"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
			borderColor: "#fafafa",
		},
	},
}));

export const useStyles02 = makeStyles({
	singleMenuItem: {
		fontSize: "12px",
		fontFamily: "Hind Siliguri",
		padding: "8px 12px",
		color: "#46596a",
		width: "auto",
	},
	multiSelect: {
		fontSize: "12px",
		fontFamily: "Hind Siliguri",
		minWidth: "180px",
		maxWidth: "180px",
		height: "30px",
		borderRadius: "3px",
		color: "#46596a !important",
		backgroundColor: "white",
		alignItems: "center",
		display: "flex",
		border: "1px solid #dedede",
		"&:before": {
		  borderBottom: "0px",
		},
		"&:after": {
		  borderBottom: "0px",
		},
		"&:hover:not(.Mui-disabled):before": {
		  borderBottom: "0px",
		},
		"& .MuiOutlinedInput-input": {
		  padding: "6px 6px",
		},
		"& .MuiSelect-select:focus": {
		  backgroundColor: "transparent",
		},
		"& .MuiSelect-select.MuiSelect-select": {
		  paddingLeft: "8px",
		},
	  },
	  listItem: {
		"& span.MuiTypography-body1": {
		  fontSize: "12px",
		  color: "#46596a",
		  fontFamily: "Hind Siliguri",
		  paddingRight: "5px",
		},
	  },
	chipStyle: {
		// marginTop: "0px",
		fontFamily: "Hind Siliguri",
		fontSize: 14,
		border: "1px solid #dedede",
		color: "white !important",
		height: "30px",
		borderRadius: "3px",
		width: "fit-content",
		maxWidth: "90%",
		margin: "12px",
		background: "#4EAFB3",
		"&:focus": {
		  background: "#4EAFB3",
		},
		"& .MuiChip-deleteIcon": {
		  // width: "16px",
		  fontSize: 20,
		  border: "none",
		  color: "white",
		},
	  },
	autocompleteStyle: {
		"& .MuiChip-root": {
			fontFamily: "Hind Siliguri",
			fontSize: 12,
		},
		"& .MuiInput-underline:after": {
			borderBottom: 0,
		},
		"& .MuiInput-underline:before": {
			borderBottom: 0,
			transition: "none",
		},
		"& .MuiInput-underline:hover:not(.Mui-disabled):before": {
			borderBottom: "0px solid #dedede !important",
		},
		"& .Mui-focused .MuiInput-underline": {
			borderBottom: 0,
		},
		"& .MuiAutocomplete-option": {
			fontFamily: "Hind Siliguri",
			fontSize: 12,
		},
		"& .MuiAutocomplete-paper": {
			fontFamily: "Hind Siliguri",
			fontSize: 6,
		},
		"& .MuiInputBase-input": {
			fontFamily: "Hind Siliguri",
			fontSize: 12,
			color: "#46596a",
		},
		"& .MuiOutlinedInput-root": {
			paddingTop: "0px !important",
			paddingBottom: "0px !important",
		},
	},
	typographyStyle: {
		"& .MuiChip-root": {
			fontFamily: "Hind Siliguri",
			fontSize: 12,
		},
		"& .MuiInput-underline:after": {
			borderBottom: 0,
		},
		"& .MuiInput-underline:before": {
			borderBottom: 0,
			transition: "none",
		},
		"& .MuiInput-underline:hover:not(.Mui-disabled):before": {
			borderBottom: "0px solid #dedede !important",
		},
		"& .Mui-focused .MuiInput-underline": {
			borderBottom: 0,
		},
		"& .MuiAutocomplete-option": {
			fontFamily: "Hind Siliguri",
			fontSize: 12,
		},
		"& .MuiAutocomplete-paper": {
			fontFamily: "Hind Siliguri",
			fontSize: 6,
		},
		"& .MuiInputBase-input": {
			fontFamily: "Hind Siliguri",
			fontSize: 12,
			color: "#46596a",
			marginTop: "2px",
		},
		"& .MuiOutlinedInput-root": {
			paddingTop: "0px !important",
			paddingBottom: "0px !important",
		},
		"& .MuiAutocomplete-endAdornment": {
			display: "block !important",
		},
		"& .MuiAutocomplete-popupIndicator": {
			"& span": {
				"& svg": {
					"& path": {
						d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')", // your svg icon path here
					},
				},
			},
		},
		"& .WithStyles(ForwardRef(Autocomplete))-endAdornment-47": {
			display: "block",
		},
	},
	root: {
		"& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
			transform: "translate(34px, 20px) scale(1);",
			fontFamily: "Hind Siliguri",
			fontStyle: "normal",
			fontWeight: "400",
			fontSize: "5px",
			color: "#465a69",
			opacity: 0.5,
		},
	},
	select: {
		fontFamily: "Hind Siliguri !important",
		fontSize: "16px!important",
		fontStyle: "normal!important",
		fontWeight: "400!important",
	},
	comboOptions: {
		fontFamily: "Hind Siliguri",
		fontStyle: "normal",
		fontWeight: "400",
		fontSize: "16px",
		color: "#465A69",
	},
	popupIndicator: {
		"& span": {
			"& svg": {
				"& path": {
					d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')", // your svg icon path here
				},
			},
		},
	},
	inputRoot: {
		color: "#465a69",
		background: "#ededed",
		'&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
			paddingLeft: 26,
			fontFamily: "Hind Siliguri",
			fontSize: "14px",
			fontStyle: "normal",
			fontWeight: "500",
			lineHeight: "23px",
			letterSpacing: "0em",
			textAlign: "left",
			color: "#465A69",
		},
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#fafafa",
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "#fafafa",
		},
		"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
			borderColor: "#fafafa",
		},
	},
	normalText: {
		fontFamily: "Hind Siliguri",
		fontStyle: "normal",
		fontWeight: "normal",
		fontSize: "11px",
		lineHeight: "24px",
		marginLeft: "5px",
		marginTop: "5px",
	},
});

export const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: 150,
			width: "auto",
		},
	},
	anchorOrigin: {
		vertical: "bottom",
		horizontal: "left",
	},
	transformOrigin: {
		vertical: "top",
		horizontal: "left",
	},
	variant: "menu",
	getContentAnchorEl: null,
};
